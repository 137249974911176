import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { verificarUsuario } from "../../api/loginApi";
import { personaAnular, personasListar } from "../../api/personaApi";
import { personaBuscar } from "../../api/tramiteApi";
import {
  codigo_personas_agregar,
  ruta_personas_agregar,
  ruta_personas_editar,
  ruta_sin_acceso,
} from "../../constants/constants-rutas-codigo";
import { DataContext } from "../../context/DataContext";
import {
  /* guardarLocalStorage, */
  obtenerLabel,
  obtenerLocalStorage,
} from "../../resources/funciones";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ListaPersonas = () => {
  const {
    register,
    handleSubmit,
    watch,
    /* reset,
    control,
    setValue,
    formState: { errors }, */
  } = useForm();

  const navigate = useNavigate();
  const token = obtenerLocalStorage("usuario")?.token;
  const estilosCompania = obtenerLocalStorage("usuario")?.estilo;

  const [listaPersonas, setListaPersonas] = useState([]);

  const { listaTipoDocumentos, listaEstadosPersona, listaUbigeo } =
    useContext(DataContext);

  const onSubmitBuscar = async (data) => {
    ////console.log("data", data);
    const dataPOST = {
      TipDocPer: data?.TipoDoc || "",
      NumDocPer: data?.NumDoc || "",
      NomComPer: data?.Nombre || "",
      EstadoPer: data?.Estado || "T",
      tokenCompañia: token,
    };

    // console.log(dataPOST)

    const {/*  status, */ datos_personas } = await personasListar(dataPOST);
    // console.log(datos_personas);
    setListaPersonas(datos_personas);
    ////console.log("datos tramite", datos_tramite);
  };

  const irAgregarPersona = async () => {
    const dataPOST = {
      token: token,
      // url_modulo: url,
      codigo: codigo_personas_agregar,
    };
    const { autenticado } = await verificarUsuario(dataPOST);

    if (autenticado) {
      navigate(ruta_personas_agregar);
    } else {
      navigate(ruta_sin_acceso);
    }
  };

  const editarPersona = async (TipoDoc, NumDoc) => {
    const dataPOST2 = {
      TipDocPer: TipoDoc,
      NumDocPer: NumDoc,
      tokenCompañia: token,
      tipo: 2,
    };

    const { status, persona } = await personaBuscar(dataPOST2);

    // console.log(persona)

    const persona_formateado = {
      TipoPer: persona.PerRol,
      TipoDoc: persona.PerTipDoc,
      NumDoc: persona.PerNumDoc,
      ApellidoP: persona.PerApllPat,
      ApellidoM: persona.PerApllMat,
      Nombre: persona.PerNomb,
      RazonSocial: persona.PerRazSoc,
      Telefono: persona.PerTelefono,
      Email: persona.PerEmail,
      EstCivil: persona.PerEstCivil,
      UnionHecho: persona.PerUnionHech,
      SepPatr: persona.PerSepaPatri,
      SocCony: persona.PerSocConyug,
      PartReg: persona.PerPartReg,
      OficReg: persona.PerOficReg,
      Direcciones: persona.PersonaDireccion.map((dir) => {
        return {
          Domicilio: dir.PerDirDomi,
          Ubigeo: listaUbigeo.find((u) => u.TUbiCod === dir.PerDirUbig_R),
        };
      }),
      IdCony: persona.PerIdConyug,
    };

    if (status === 1) {
      //guardarLocalStorage({persona: persona_formateado}, "persona");
      navigate(ruta_personas_editar, {
        state: { persona: persona_formateado },
      });
    }
  };

  const anularPersona = async (TipoDoc, NumDoc) => {
    const dataPOST = {
      TipDocPer: TipoDoc,
      NumDocPer: NumDoc,
      tokenCompañia: token,
      EstadoPer: "I",
    };

    const { status } = await personaAnular(dataPOST);

    if (status === 1) {
      onSubmitBuscar(watch());
    }
  };

  useEffect(() => {
    onSubmitBuscar();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="p-3">
      <div className="container pt-2">
        <h4 className="text-center mt-3">Lista de Personas</h4>

        <div className="row mt-3">
          <div className="col-md-2">
            <label className="form-label ">Tipo</label>
            <select
              className="form-select"
              aria-label="Default select example"
              defaultValue="T"
              {...register("TipoDoc")}
            >
              <option value="">Todos</option>
              {listaTipoDocumentos.map((tipDoc,index) => {
                if (tipDoc.TipDocEst === "S") {
                  return (
                    <option
                      key={`per_tipoDoc_${index}`}
                      value={tipDoc.TipDocCod}
                      // selected={tipDoc.TipDocCod === "D" && true}
                    >
                      {tipDoc.TipDocDes}
                    </option>
                  )
                }else{
                  return null
                }
              })}
            </select>
          </div>

          <div className="col-md-2">
            <label className="form-label">Número</label>
            <input
              type="text"
              className="form-control"
              {...register("NumDoc")}
            />
          </div>

          <div className="col-md-4">
            <label className="form-label">Nombre</label>
            <input
              type="text"
              className="form-control"
              {...register("Nombre")}
            />
          </div>

          <div className="col-md-2">
            <label className="form-label ">Estado</label>
            <select
              className="form-select"
              defaultValue="T"
              {...register("Estado")}
            >
              <option value="T">Todos</option>
              <option value="A">Activo</option>
              <option value="I">Inactivo</option>
            </select>
          </div>

          <div className="col-md-2 d-flex justify-content-center align-items-end">
            <button
              className="btn btn-main"
              onClick={handleSubmit(onSubmitBuscar)}
            >
              Buscar
            </button>
          </div>
        </div>

        {/* <div className="mt-3 ps-1">
          <OverlayTrigger overlay={<Tooltip>Agregar Persona</Tooltip>}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-file-plus"
              viewBox="0 0 16 16"
              style={{
                color: estilosCompania?.boton_primario || "rgb(0, 0, 255)",
                cursor: "pointer",
              }}
              onClick={irAgregarPersona}
            >
              <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z" />
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
            </svg>
          </OverlayTrigger>
        </div> */}
        <div className="mt-3 ps-1">
          <div>
            <OverlayTrigger overlay={<Tooltip>Agregar Persona</Tooltip>}>
              <button className="btn btn-outline-main" onClick={irAgregarPersona}><strong>+</strong></button>
            </OverlayTrigger>
          </div>
        </div>

        <div className="container mt-3 border table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Tipo</th>
                <th scope="col">Número</th>
                <th scope="col">Nombre Completo</th>
                <th scope="col">Estado</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {listaPersonas?.length > 0 ? (
                listaPersonas?.map((p,index) => (
                  <tr key={`persona_${index}`}>
                    <td>
                      {obtenerLabel(listaTipoDocumentos, p?.PerTipDoc) || "RUC"}
                    </td>
                    <td>{p?.PerNumDoc}</td>
                    <td>{p?.PerNomCom}</td>
                    <td>{obtenerLabel(listaEstadosPersona, p?.PerEstado)}</td>
                    <td className="text-center">
                      <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-pencil"
                          viewBox="0 0 16 16"
                          style={{
                            color: estilosCompania?.boton_primario,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            editarPersona(p?.PerTipDoc, p?.PerNumDoc)
                          }
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                      </OverlayTrigger>
                    </td>
                    <td className="text-center">
                      <OverlayTrigger overlay={<Tooltip>Anular</Tooltip>}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x-circle"
                          viewBox="0 0 16 16"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            anularPersona(p?.PerTipDoc, p?.PerNumDoc)
                          }
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={6}>No hay Personas</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
