import { api, apiPV	 } from "./configApi"

export const tramiteIofe = async (dataPOST) => {
    try {
        const res = await api.post("api/iofe/inmatriculacion", dataPOST)
        
        if (res.status === 200) {
            return {
                status: 1,
                message: "Se envió a IOFE",
            }
        }

    } catch (error) {
        
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error al enviar a IOFE",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const tramitePruebaVida = async (dataPOST,headers) => {
    try {
        const res = await api.post("api/iofe/pruebaVidaLink", dataPOST,{ headers })        
        if (res.status === 200) {
            return {
                status: 1,
                message: "",
                data: res.data
            }
        }

    } catch (error) {        
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error al enviar a IOFE Prueba de Vida",
            };
        }
        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}


export const updatePruebaVida = async (dataPOST,headers) => {
    try {
        const res = await api.post("api/iofe/pruebavidaUpdate", dataPOST,{ headers })        
        if (res.status === 200) {
            return {
                status: 1,
                message: "",
                data: res.data
            }
        }

    } catch (error) {        
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error al enviar a IOFE Prueba de Vida",
                data: false
            };
        }
        return {
            status: -1,
            message: "Error en el servidor",
            data: false
        };
    }
}



