import { NavLink, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect, useRef } from "react";

//Iconos
import { FaBars, FaHome, FaUser } from "react-icons/fa";
import { BsShieldLockFill } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion";
import { RiLogoutBoxLine, RiTeamFill } from "react-icons/ri";
import { RxModulzLogo } from "react-icons/rx";
import { AiFillBank } from "react-icons/ai";
import { MdOutlineLocationCity } from "react-icons/md";

import Swal from "sweetalert2";
import { SidebarMenuRecursive } from "./SidebarMenuRecursive";
import {
  ruta_gestor_de_archivos,
  ruta_historial,
  ruta_login,
} from "../../constants/constants-rutas-codigo";
import {
  limpiarLocalStorage,
  obtenerLocalStorage,
} from "../../resources/funciones";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";

import { blue } from "@mui/material/colors";
import { navegarOtraApp } from "../../api/loginApi";
import { CambiarContrasenia } from "./CambiarContrasenia";
import Modal from "react-bootstrap/Modal";
import { IdleTimer } from "../idleTimer/IdleTimer";
import 'bootstrap-icons/font/bootstrap-icons.css';

// const routes = [
//   // Espacio para usuario
//   {
//     path: ruta_historial,
//     name: "Inmatriculacion",
//   },
//   {
//     path: ruta_historial,
//     name: "Reportes",
//   },
//   // {
//   //   path: ruta_gestor_de_archivos,
//   //   name: "Archivos"
//   // },
//   {
//     path: null,
//     name: "Salir",
//     icon: <RiLogoutBoxLine />,
//   },
// ];

export const SideBar = ({ children, ruta }) => {
  const usuarioLS = obtenerLocalStorage("usuario");

  const widthOpen = "11.5rem";
  const widthClose = "2.8rem";

  // Obtener los valores de las variables CSS cuando el componente se monta
  const computedStyles = getComputedStyle(document.documentElement);

  let routes = [];

  let route = usuarioLS?.data?.subRoutes || [];

  const [openModalCambioContrasenia, setOpenModalCambioContrasenia] =
    useState(false);
  const handleOpenCambioContrasenia = () => setOpenModalCambioContrasenia(true);
  const handleCloseCambioContrasenia = () =>
    setOpenModalCambioContrasenia(false);

  // console.log('route', route)

  routes = routes.concat(route, {
    path: null,
    name: "SALIR",
    tipo: "MWT",
    icon: (
      <RiLogoutBoxLine
        className="color-iconos-sidebar"
        style={{ fontSize: "24px" }}
      />
    ),
  });

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const logo_primario_guardado = usuarioLS?.estilo?.log_primario
    ? `data:image/png;base64,${usuarioLS?.estilo?.log_primario}`
    : null;

  const navigate = useNavigate();

  const salirSesion = () => {
    Swal.fire({
      title: "Salir sesión",
      text: `¿Estás seguro(a) de salir sesión?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Salir",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn-salir",
        cancelButton: "btn-cancelar",
      },
      confirmButtonColor: "#d33",
    }).then((result) => {
      if (result.value) {
        limpiarLocalStorage("usuario");
        limpiarLocalStorage("tramite");
        limpiarLocalStorage("pagos");
        limpiarLocalStorage("vehiculo");
        navigate(`/inmatriculacion/login`);
      }
    });
  };

  const [aplicaciones, setAplicaciones] = useState([]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = async () => {
    const dataPOST = {
      token: usuarioLS?.token,
    };

    const { apps } = await navegarOtraApp(dataPOST);
    setAplicaciones(apps);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="h-100">
        {ruta != "" && (
          <motion.div
            animate={{
              minWidth: isOpen ? widthOpen : widthClose,
              width: isOpen ? "auto" : widthClose,
              transition: {
                duration: 0,
              },
            }}
            className={`sidebar bg-gradient`}
            style={{ position: "fixed", height: "100%" }}
          >
            <div
              className={"top_section justify-content-center"}
              style={{ position: "relative", marginTop: !isOpen && "15px" }}
            >
              {isOpen &&
                (logo_primario_guardado ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "28px", width: "28px" }}
                  >
                    <img
                      src={logo_primario_guardado}
                      alt="Imagen logo 1"
                      className="img-thumbnail"
                    />
                  </div>
                ) : (
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-building"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                      <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                    </svg>
                  </div>
                ))}

              <div
                className="bars text-center d-flex align-items-end justify-content-center"
                onClick={toggle}
                style={{ position: "absolute", right: "8px" }}
              >   
                <FaBars
                  className="color-iconos-sidebar"
                  style={{ fontSize: "18px" }}
                />
              </div>
            </div>

            {/* TODAS LAS RUTAS DEL SISTEMA */}

            <section className="routes">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ flex: 1, margin: isOpen ? "2px" : "5px" }}
              >
                {/* <Avatar alt="Remy Sharp" src={logo_primario_guardado} sx={{ width: isOpen ? 30 : 24, height: isOpen ? 30 : 24 }} /> */}

                <AnimatePresence>
                  {isOpen && (
                    <div
                      className={
                        isOpen
                          ? "usuario text-center"
                          : "usuario d-flex justify-content-center"
                      }
                    >
                      {isOpen && (
                        <span
                          className="ms-1"
                          style={{
                            fontSize: "0.688rem",
                            textTransform: "capitalize",
                          }}
                        >
                          {usuarioLS.nombre}
                        </span>
                      )}
                    </div>
                  )}
                </AnimatePresence>
              </div>

              {routes?.map((route, index) => {
                if (
                  route?.subRoutes &&
                  route?.tipo == "MO" &&
                  route?.subRoutes.length != 0
                ) {
                  return (
                    <SidebarMenuRecursive
                      key={index}
                      setIsOpen={setIsOpen}
                      route={route}
                      isOpen={isOpen}
                    />
                  );
                } else {
                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      className={(navData) =>
                        navData.isActive && route.path != null && isOpen
                          ? "active link"
                          : "none link"
                      }
                      onClick={route.path === null ? salirSesion : undefined}
                      style={{
                        display: !isOpen && "flex",
                        justifyContent: !isOpen && "center",
                      }}
                    >
                      {route.icon && <div>{route.icon}</div>}
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route.name.toLowerCase()}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                }
              })}
            </section>

            {usuarioLS?.tipo != "M" && (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                  marginBottom: "30px",
                  fontSize: "0.75rem",
                }}
              >
                {isOpen && (
                  <NavLink
                    className="btn-cambiar-psw none link"
                    onClick={handleOpenCambioContrasenia}
                  >
                    <motion.div
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                      style={{ borderRadius: "5px" }}
                    >
                      ¿Cambiar contraseña?
                    </motion.div>
                  </NavLink>
                )}
              </div>
            )}

            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                marginBottom: "30px",
              }}
            >
              <div className="boton-apps" onClick={handleClickOpen}>
                <Box sx={{ "& > :not(style)": { m: 1 } }}>
                  <Fab aria-label="add">
                    <svg
                      height="30px"
                      width="30px"
                      fill={computedStyles?.getPropertyValue(
                        "--color-texto-nav-item"
                      )}
                      className="gb_i color-iconos-sidebar"
                      focusable="false"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
                    </svg>
                  </Fab>
                </Box>
                {/* <div style={{ width: "35px", height: "35px", borderRadius: "50%", border: `1px solid ${computedStyles?.getPropertyValue('--color-button-primario')}`, textAlign: "center", verticalAlign: "center" }}>
                    <svg height="30px" width="30px" fill={computedStyles?.getPropertyValue('--color-button-primario')} className="gb_i color-iconos" focusable="false" viewBox="0 0 24 24"><path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                  </div> */}
              </div>
            </div>

            <CambiarContrasenia
              open={openModalCambioContrasenia}
              handleClose={handleCloseCambioContrasenia}
            />

            <SimpleDialog
              open={open}
              onClose={handleClose}
              listaApps={aplicaciones}
            />
          </motion.div>
        )}
        <main
          className="p-0 h-100"
          style={{ marginLeft: isOpen ? widthOpen : widthClose/* , backgroundColor:"#f2f2f7"  */}}
        >
          {children}
        </main>
      </div>
    </>
  );
};

const SimpleDialog = (props) => {
  const { onClose, listaApps, open } = props;

  // Obtener los valores de las variables CSS cuando el componente se monta
  const computedStyles = getComputedStyle(document.documentElement);

  const handleListItemClick = (app) => {
    const usuarioLS = obtenerLocalStorage("usuario");
    // console.log('app', app)
    const token = usuarioLS?.token;
    limpiarLocalStorage("usuario");
    const urlApp = app?.url + "/conexionExterna/" + token;

    // Abre la nueva ruta en una nueva ventana o pestaña
    window.location.replace(urlApp);
  };

  const navigate = useNavigate();

  const [isIdle, setIsIdle] = useState(false);
  const idleTimerRef = useRef(null);

  const [show, setShow] = useState(false);

  const onIdle = () => {
    setIsIdle(true);
    setShow(true);
    // Puedes mostrar una alerta aquí u realizar cualquier acción que desees
    limpiarLocalStorage("usuario");
    limpiarLocalStorage("tramite");
    limpiarLocalStorage("pagos");
    limpiarLocalStorage("persona");
  };

  const onAction = () => {
    setIsIdle(false);
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1800000}
        onIdle={onIdle}
        onAction={onAction}
      />

      <Modal show={show}>
        <Modal.Header className="py-1">
          <Modal.Title className="h5">Sesion Terminada</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2">
          <div>
            Estimado usuario, su sesión ha terminado debido a la inactividad.
          </div>
          <div>Por favor, vuelva a iniciar sesión.</div>
        </Modal.Body>
        <Modal.Footer className="py-1">
          <button
            className="btn btn-main btn-sm"
            onClick={() => navigate(ruta_login)}
          >
            Aceptar
          </button>
        </Modal.Footer>
      </Modal>

      <Dialog
        onClose={onClose}
        open={open}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-start",
            alignItems: "flex-end",
            width: "350px",
          },
        }}
        PaperProps={{
          className: "custom-dialog-paper", // Agrega una clase personalizada.
        }}
      >
        <div className="popup-aplicaciones">
          <DialogTitle sx={{ textAlign: "center" }}>Aplicaciones</DialogTitle>
          <List sx={{ display: "flex", flexWrap: "wrap", padding: 0 }}>
            {listaApps.map((app) => (
              <ListItem
                disableGutters
                key={app?.ModCod}
                sx={{ padding: "0", margin: "0", width: "33.33%" }}
              >
                <ListItemButton onClick={() => handleListItemClick(app)}>
                  <ListItemAvatar>
                    <Avatar /* sx={{ bgcolor: blue[100], color: blue[600] }} */
                      sx={{
                        color: computedStyles?.getPropertyValue(
                          "--color-button-primario"
                        ),
                        border: `1px solid ${computedStyles?.getPropertyValue(
                          "--color-button-primario"
                        )}`,
                        bgcolor: "#ffffff",
                      }}
                    >
                      {/* <PersonIcon /> */}
                      {app?.icono == "" || app?.icono == null ? (
                        <i className="bi bi-person-workspace"></i>
                      ) : (
                        <i className={app?.icono}></i>
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={app?.name}
                    primaryTypographyProps={{
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    </>
  );
};
