import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { placasPendientes } from '../../api/tramiteApi';


export const PlacaEntrega = () => {

  const [listado, setListado] = useState([])




  useEffect(() => {


    const getData = async () => {
      try {
        console.log("Entro al get data ");
        const dataPost = { "sede": "1" }
        const respuesta = await placasPendientes(dataPost);
        setListado(respuesta.lista)
      } catch (error) {
        console.log("error ", error);

      }

    }
    getData()

  }, [])


  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    console.log("submit");
    console.log(data);
  };




  return (
    <div className="container-fluid p-0 m-0">

      <div className="container pt-2 ">

        <div className="container p-0">



          <div className="container col-12 col-xs-12 col-sm-12 col-md-10 col-lg-6 border border-1 pt-3">

            <p className='fs-4 text-center'>Entrega de placas</p>
            <hr />



            <form>
              <div className="mb-3">
                <label for="idrecepciono" className="form-label">Quien recepciono?</label>
                <input type="text" className="form-control" id="idrecepciono" aria-describedby="" placeholder='Ingrese Nombres Completos' />
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">Sede de entrega:</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...register(`sede`)}
                >
                  <option value="B">Volkswagen surquillo</option>
                  <option value="F">Audi surquillo</option>
                </select>
              </div>

              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">Seleccione las placas entregadas </label>
                <table className='table  border   table-striped table-responsive'>
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th scope="col">PLACA
                      </th>
                      <th scope="col"> SEDES
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {listado.map((item, index) => (
                      <tr>
                        <td>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          </div>
                        </td>
                        <td>{item.placa}</td>
                        <td> SEDE PLAZA NsaffffssTE</td>
                      </tr>

                    ))}
                     

                  </tbody>
                </table>

              </div>





              <button type="submit" className="btn btn-primary col-12">Registrar Entrega</button>
            </form>


          </div>
        </div>

      </div>





      <div className="container pt-5">
        <p className='fs-2'>Placas por recoger en la AAP</p>
      </div>

      <div className="container row">
        <div className="col">
          <p>Seleccione la sede  </p>
        </div>
        <div className="col">
          <select className="form-select" aria-label="Default select example">
            <option selected>Todos</option>
            <option value="2">Sede principal</option>
            <option value="3">Sede Norte</option>
          </select>

          {errors.sede && (
            <span className="error">El comprobante es requerido</span>
          )}
        </div>
      </div>

      <div className="container">

        <table className='table  border   table-striped table-responsive'>
          <thead>
            <tr>
              <th>
                #
              </th>
              <th scope="col">PLACA
              </th>
              <th scope="col"> SEDES
              </th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                </div>
              </td>
              <td>A5U570</td>
              <td> SEDE PLAZA NORTE</td>
            </tr>
            <tr>
              <td><input className="form-check-input" type="checkbox" value="" id="defaultCheck1" /></td>
              <td>A5U570</td>
              <td> SEDE PLAZA NORTE</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  )
}
