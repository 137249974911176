export const combustible = [
  {
    codigo: "ACE",
    descripcion: "ACEITE",
  },
  {
    codigo: "BIL",
    descripcion: "BI-COMBUSTIBLE GLP",
  },
  {
    codigo: "BIE",
    descripcion: "BI-COMBUSTIBLE GNL",
  },
  {
    codigo: "BIN",
    descripcion: "BI-COMBUSTIBLE GNV",
  },
  {
    codigo: "BDS",
    descripcion: "BIODIESEL",
  },
  {
    codigo: "CCO",
    descripcion: "CELDAS DE COMBUSTIBLE",
  },
  {
    codigo: "DSL",
    descripcion: "DIESEL",
  },
  {
    codigo: "DUL",
    descripcion: "DUAL GLP",
  },
  {
    codigo: "DUE",
    descripcion: "DUAL GNL",
  },
  {
    codigo: "DUN",
    descripcion: "DUAL GNV",
  },
  {
    codigo: "ELT",
    descripcion: "ELÉCTRICO",
  },
  {
    codigo: "ETA",
    descripcion: "ETANOL",
  },
  {
    codigo: "FLX",
    descripcion: "FLEXFUEL",
  },
  {
    codigo: "GSL",
    descripcion: "GASOLINA",
  },
  {
    codigo: "GLP",
    descripcion: "GLP",
  },
  {
    codigo: "GNL",
    descripcion: "GNL",
  },
  {
    codigo: "GNV",
    descripcion: "GNV",
  },
  {
    codigo: "HDB",
    descripcion: "HIBRIDO (DIESEL / BATERIAS)",
  },
  {
    codigo: "HID",
    descripcion: "HIDRÓGENO",
  },
  {
    codigo: "HGB",
    descripcion: "HÍBRIDO (GASOLINA / BATERIAS)",
  },
  {
    codigo: "SOL",
    descripcion: "SOLAR",
  },
];

// SELECTORES PARA DUA
export const puertos = [
  {
    codigo: "019",
    puerto: "019 Tumbes",
  },
  {
    codigo: "028",
    puerto: "028 Talara",
  },
  {
    codigo: "037",
    puerto: "037 Sullana",
  },
  {
    codigo: "046",
    puerto: "046 Paita",
  },
  {
    codigo: "055",
    puerto: "055 Chiclayo",
  },
  {
    codigo: "064",
    puerto: "064 Eten",
  },
  {
    codigo: "073",
    puerto: "073 Pacasmayo",
  },
  {
    codigo: "082",
    puerto: "082 Salaverry",
  },
  {
    codigo: "091",
    puerto: "091 Chimbote",
  },
  {
    codigo: "109",
    puerto: "109 Huacho",
  },
  {
    codigo: "118",
    puerto: "118 Maritima del Callao",
  },
  {
    codigo: "127",
    puerto: "127 Pisco",
  },
  {
    codigo: "136",
    puerto: "136 San Juan",
  },
  {
    codigo: "145",
    puerto: "145 Mollendo - Matarani",
  },
  {
    codigo: "154",
    puerto: "154 Arequipa",
  },
  {
    codigo: "163",
    puerto: "163 Ilo",
  },
  {
    codigo: "172",
    puerto: "172 Tacna",
  },
  {
    codigo: "181",
    puerto: "181 Puno",
  },
  {
    codigo: "190",
    puerto: "190 Cuzco",
  },
  {
    codigo: "280",
    puerto: "280 Puerto Maldonado",
  },
  {
    codigo: "217",
    puerto: "217 Pucallpa",
  },
  {
    codigo: "226",
    puerto: "226 Iquitos",
  },
  {
    codigo: "235",
    puerto: "235 Aerea del Callao",
  },
  {
    codigo: "244",
    puerto: "244 Postal de Lima",
  },
  {
    codigo: "271",
    puerto: "271 Tarapoto",
  },
  {
    codigo: "262",
    puerto: "262 Desaguadero",
  },
  {
    codigo: "299",
    puerto: "299 La Tina",
  },
];

export const regimenes = [
  {
    codigo: "10",
    regimen: "10-Importacion Definitiva",
  },
  {
    codigo: "20",
    regimen: "20-Importacion Temporal",
  },
  {
    codigo: "21",
    regimen: "21-Admision Temporal",
  },
];

// SELECTORES PARA CODIGO DE OPERACION - CANCELACION
export const codOperaciones = [
  {
    codigo: "001",
    operacion: "Depósito en cuenta"
  },
  {
    codigo: "002",
    operacion: "Giro"
  },
  {
    codigo: "003",
    operacion: "Transferencia de fondos"
  },
  {
    codigo: "004",
    operacion: "Orden de pago"
  },
  {
    codigo: "005",
    operacion: "Tarjeta de débito"
  },
  {
    codigo: "006",
    operacion: "Tarjeta de crédito"
  },
  {
    codigo: "007",
    operacion: "Cheques no negociables"
  },
  {
    codigo: "008",
    operacion: "Efectivo (sin Medios de Pago)"
  },
  {
    codigo: "009",
    operacion: "Efectivo (demás casos)"
  },
  {
    codigo: "010",
    operacion: "Medios de Pago (comercio exterior)"
  },
  {
    codigo: "011",
    operacion: "Documentos emitidos por EDPYMES"
  },
  {
    codigo: "012",
    operacion: "Tarjeta de credito emitida en el pais o en el exterior"
  },
  {
    codigo: "013",
    operacion: "Tarjeta de credito emitidas en el exterior"
  },

  {
    codigo: "099",
    operacion: "Otros Medios de Pago"
  },
]

export const carrocerias = [
  {
    codigo: "AMB",
    carroceria: "AMBULANCIA"
  },
  {
    codigo: "ARE",
    carroceria: "ARENERO"
  },
  {
    codigo: "ART",
    carroceria: "ARTICULADO"
  },
  {
    codigo: "AUX",
    carroceria: "AUXILIO MECÁNICO"
  },
  {
    codigo: "BAR",
    carroceria: "BARANDA"
  },
  {
    codigo: "BAD",
    carroceria: "BARREDERA"
  },
  {
    codigo: "BIA",
    carroceria: "BIARTICULADO"
  },
  {
    codigo: "BMT",
    carroceria: "BICIMOTO"
  },
  {
    codigo: "BHO",
    carroceria: "BOMBA HORMIGONERA"
  },
  {
    codigo: "BOM",
    carroceria: "BOMBERO"
  },
  {
    codigo: "BOB",
    carroceria: "BOMBONA"
  },
  {
    codigo: "CBA",
    carroceria: "CAMA BAJA"
  },
  {
    codigo: "CCG",
    carroceria: "CAMIÓN GRÚA"
  },
  {
    codigo: "CAB",
    carroceria: "CARGOBUS"
  },
  {
    codigo: "CRD",
    carroceria: "CASA RODANTE"
  },
  {
    codigo: "CAN",
    carroceria: "CAÑERO"
  },
  {
    codigo: "CEL",
    carroceria: "CELULAR"
  },
  {
    codigo: "CHC",
    carroceria: "CHASIS CABINADO"
  },
  {
    codigo: "CHM",
    carroceria: "CHASIS MOTORIZADO"
  },
  {
    codigo: "CIG",
    carroceria: "CIGÜEÑA"
  },
  {
    codigo: "CIS",
    carroceria: "CISTERNA"
  },
  {
    codigo: "COM",
    carroceria: "CISTERNA COMBUSTIBLES"
  },
  {
    codigo: "COB",
    carroceria: "COMPACTADOR"
  },
  {
    codigo: "CPT",
    carroceria: "COMPETENCIA"
  },
  {
    codigo: "CMP",
    carroceria: "COMPRESOR"
  },
  {
    codigo: "RTV",
    carroceria: "COMUNICACIONES"
  },
  {
    codigo: "CNV",
    carroceria: "CONVERTIBLE"
  },
  {
    codigo: "CPE",
    carroceria: "COUPÉ"
  },
  {
    codigo: "CMT",
    carroceria: "CUATRIMOTO"
  },
  {
    codigo: "DOL",
    carroceria: "DOLLY"
  },
  {
    codigo: "ELV",
    carroceria: "ELEVADOR"
  },
  {
    codigo: "ASF",
    carroceria: "ESPARCIDOR DE ASFLATO"
  },
  {
    codigo: "ORG",
    carroceria: "ESPARCIDOR DE MATERIA ORGÁNICA"
  },
  {
    codigo: "EXP",
    carroceria: "EXPLOSIVOS"
  },
  {
    codigo: "FAC",
    carroceria: "FACTORIA"
  },
  {
    codigo: "FUN",
    carroceria: "FUNERARIO"
  },
  {
    codigo: "FUR",
    carroceria: "FURGÓN"
  },
  {
    codigo: "FRG",
    carroceria: "FURGÓN FRIGORÍFICO"
  },
  {
    codigo: "TER",
    carroceria: "FURGÓN ISOTÉRMICO"
  },
  {
    codigo: "GRA",
    carroceria: "GRANELERO"
  },
  {
    codigo: "GEL",
    carroceria: "GRUPO ELECTRÓGENO"
  },
  {
    codigo: "GRU",
    carroceria: "GRÚA"
  },
  {
    codigo: "HBK",
    carroceria: "HATCHBACK"
  },
  {
    codigo: "HOR",
    carroceria: "HORMIGONERA"
  },
  {
    codigo: "HOS",
    carroceria: "HOSPITAL"
  },
  {
    codigo: "ILM",
    carroceria: "ILUMINADOR"
  },
  {
    codigo: "INS",
    carroceria: "INSTRUCCIÓN"
  },
  {
    codigo: "INT",
    carroceria: "INTERCAMBIADOR"
  },
  {
    codigo: "LIM",
    carroceria: "LIMOSINA"
  },
  {
    codigo: "LUB",
    carroceria: "LUBRICADOR"
  },
  {
    codigo: "MAD",
    carroceria: "MADRINA"
  },
  {
    codigo: "MEZ",
    carroceria: "MEZCLADOR"
  },
  {
    codigo: "MIC",
    carroceria: "MICROBUS"
  },
  {
    codigo: "MIN",
    carroceria: "MINIBUS"
  },
  {
    codigo: "MSD",
    carroceria: "MOTO SIDECAR"
  },
  {
    codigo: "MTT",
    carroceria: "MOTO TODO TERRENO"
  },
  {
    codigo: "MTO",
    carroceria: "MOTOCICLETA"
  },
  {
    codigo: "MUL",
    carroceria: "MULTIFUNCIÓN"
  },
  {
    codigo: "MPO",
    carroceria: "MULTIPROPÓSITO"
  },
  {
    codigo: "OMI",
    carroceria: "OMNIBUS INTERURBANO"
  },
  {
    codigo: "OMP",
    carroceria: "OMNIBUS PANORAMICO"
  },
  {
    codigo: "OMN",
    carroceria: "OMNIBUS URBANO"
  },
  {
    codigo: "OTR",
    carroceria: "OTROS USOS ESPECIALES"
  },
  {
    codigo: "PAN",
    carroceria: "PANEL"
  },
  {
    codigo: "PER",
    carroceria: "PERFORADOR"
  },
  {
    codigo: "PUP",
    carroceria: "PICK UP"
  },
  {
    codigo: "PLA",
    carroceria: "PLATAFORMA"
  },
  {
    codigo: "PCO",
    carroceria: "PORTA CONTENEDOR"
  },
  {
    codigo: "POR",
    carroceria: "PORTA TROPAS"
  },
  {
    codigo: "QUI",
    carroceria: "QUILLA"
  },
  {
    codigo: "RAD",
    carroceria: "RADIOLOGICO"
  },
  {
    codigo: "REM",
    carroceria: "REMOLCADOR"
  },
  {
    codigo: "REG",
    carroceria: "REMOLCADOR GRÚA"
  },
  {
    codigo: "RPV",
    carroceria: "REPARAVIAS"
  },
  {
    codigo: "ROM",
    carroceria: "ROMPE MANIFESTACIONES"
  },
  {
    codigo: "SAN",
    carroceria: "SANITARIO"
  },
  {
    codigo: "SED",
    carroceria: "SEDAN"
  },
  {
    codigo: "SWG",
    carroceria: "STATION WAGON"
  },
  {
    codigo: "SUV",
    carroceria: "SUV"
  },
  {
    codigo: "TCA",
    carroceria: "TANQUE CALORIFICO"
  },
  {
    codigo: "TCO",
    carroceria: "TANQUE CORROSIVO"
  },
  {
    codigo: "CRI",
    carroceria: "TANQUE CRIOGENICO"
  },
  {
    codigo: "TFR",
    carroceria: "TANQUE FRIGORIFICO"
  },
  {
    codigo: "GLP",
    carroceria: "TANQUE GLP"
  },
  {
    codigo: "GNC",
    carroceria: "TANQUE GNC"
  },
  {
    codigo: "TIS",
    carroceria: "TANQUE ISOTÉRMICO"
  },
  {
    codigo: "TRA",
    carroceria: "TRANSFORMADOR"
  },
  {
    codigo: "NAV",
    carroceria: "TRANSPORTE DE NAVES"
  },
  {
    codigo: "TRM",
    carroceria: "TRIMOTO CARGA"
  },
  {
    codigo: "TRI",
    carroceria: "TRIMOTO PASAJEROS"
  },
  {
    codigo: "TRO",
    carroceria: "TROLEBÚS"
  },
  {
    codigo: "TUB",
    carroceria: "TUBULAR"
  },
  {
    codigo: "VAL",
    carroceria: "VALORES"
  },
  {
    codigo: "FER",
    carroceria: "VEHÍCULO DE FERIAS"
  },
  {
    codigo: "VOL",
    carroceria: "VOLQUETE"
  },
  {
    codigo: "VFC",
    carroceria: "VOLQUETE FUERA DE CARRETERA"
  }
]