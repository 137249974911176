import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';


export const ChartsLine = ({ datosCharts }) => {
    const [idCharts, setidCharts] = useState(0)

    const generarNumeroAleatorio = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;

    }

    if (idCharts == 0) {
        const idAleatorio = generarNumeroAleatorio(10000, 99999);
        setidCharts(idAleatorio)
    }


    useEffect(() => {

        // Ejemplo de uso para obtener un número aleatorio de 5 dígitos



        Highcharts.chart(`${idCharts}-chart-container`, {
            chart: {
                type: 'line'
            },
            title: {
                text: null
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                    },
                }
            },
            yAxis: {
                title: {
                    text: 'Inmatriculaciones'
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'left',
                verticalAlign: 'bottom'
            },
            xAxis: {
                categories:datosCharts.dataMesesxSede.meses 
            },



            series: datosCharts.dataMesesxSede.series
        });
    }, []);

    return (
        <>

            {
                idCharts > 0 ?
                    <div id={`${idCharts}-chart-container`} style={{"height":"550px"}}>
                    </div>
                    :
                    <div>
                        cargando
                    </div>
            }

        </>


    );
};
