import React from 'react'
import Highcharts from 'highcharts';
import { useEffect, useState } from 'react';




export const ChartsPie = ({ datosCharts }) => {

    const [idCharts, setidCharts] = useState(0)

    const generarNumeroAleatorio = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    if (idCharts == 0) {
        const idAleatorio = generarNumeroAleatorio(10000, 99999);
        setidCharts(idAleatorio)
    }


    let anulados = 0;
    let pendientes = 0;
    let presentados = 0;
    let inscritos = 0;
    let placas = 0;
    let entregados = 0; 
    if (datosCharts) { 
        let ListaTramites = datosCharts.RptInmatriculacionesxEstado

        anulados = datosCharts.RptInmatriculacionesxEstado.ListaTramitesAnulados.length
        pendientes = datosCharts.RptInmatriculacionesxEstado.ListaTramitesPendientes.length
        presentados = datosCharts.RptInmatriculacionesxEstado.ListaTramitesPresentados.length
        //inscritos = datosCharts.RptInmatriculacionesxEstado.ListaTramitesInscritos.length
        placas = datosCharts.RptInmatriculacionesxEstado.ListaTramitesPlacas.length
        entregados = datosCharts.RptInmatriculacionesxEstado.ListaTramitesEntregados.length
 
    }



    useEffect(() => {
        // Ejemplo de uso para obtener un número aleatorio de 5 dígitos 

        Highcharts.chart(`${idCharts}-chart-container`, {
            chart: {
                type: 'pie'
            },
            title: {
                text: null
            },
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: [{
                        enabled: true,
                        distance: 20
                    }, {
                        enabled: true,
                        distance: -40,
                        format: '{point.percentage:.1f}%',
                        style: {
                            fontSize: '1rem',
                            textOutline: 'none',
                            opacity: 0.7
                        },
                        filter: {
                            operator: '>',
                            property: 'percentage',
                            value: 2
                        }
                    }]
                }
            },

            series: [{
                name: 'Total',
                data: [
                    {
                        name: 'Anulados',
                        y: anulados
                    },
                    {
                        name: 'Pendientes',
                        y: pendientes
                    },
                    {
                        name: 'Presentados',
                        y: presentados
                    },
                    {
                        name: 'Placas en tramite',
                        y: placas
                    },
                    {
                        name: 'Entregadas',
                        y: entregados
                    }
                ]
            }]
        });
    }, []);

    return (

        <>{
            idCharts > 0 ?
                <div id={`${idCharts}-chart-container`}  style={{ height: "350px" }}>
                </div>
                :
                <> cargando....</>
        }

        </>



    );
};


