import axios from 'axios'
import {server} from '../config/config'

export const api = axios.create({
    baseURL: server.serverUrl
})

export const apiSeg = axios.create({
    baseURL: server.serverSeguridad
})

export const apiPV = axios.create({
    baseURL: server.servertestPV
})