/* import { ErrorResponse } from "@remix-run/router"; */
import React, { useContext, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { DataContext } from "../../context/DataContext";
import { inputNumber } from '../../resources/funciones'
import { FileFormats, FileInput } from "../layout/FileInput";
import { obtenerLocalStorage } from "../../resources/funciones";
import { xml2js } from "xml-js";
import { XmlInput } from "../layout/XmlInput";

export const TramiteMedioPago = ({
  index,
  register,
  watch,
  errors,
  fecha,
  item,
  setValue,
  mediosPagosRemove,
}) => {
  const { listaTipoMoneda } = useContext(DataContext);
  //console.log("watch()", watch())
  //console.log("fecha", watch(`ListaMediosPagos.${index}.Fecha`))

  // useEffect(() => {
  //   console.log("Entro al useEffect del importe")
  //   console.log(parseFloat(watch(`ListaMediosPagos.${index}.Importe`)).toFixed(2))
  //   setValue(`ListaMediosPagos.${index}.Importe`, parseFloat(watch(`ListaMediosPagos.${index}.Importe`)).toFixed(2))
  // }, [watch(`ListaMediosPagos.${index}.Importe`)])

  const [xmlData, setXmlData] = useState(null)
  const [isArchiveSelected, setIsArchiveSelected] = useState(false)

  const datosTramiteLS = obtenerLocalStorage("tramite")

  useEffect(() => {
    const tipoMoneda = watch(`ListaMediosPagos.${index}.Moneda`)

    watch(`ListaMediosPagos`).forEach((medPag, indice) => {
      // console.log("Moneda ", medPag.Moneda)
      if (medPag.Moneda !== tipoMoneda) {
        setValue(`ListaMediosPagos.${indice}.Moneda`, tipoMoneda)
      }
    });
    // eslint-disable-next-line
  }, [watch(`ListaMediosPagos.${index}.Moneda`)])

  useEffect(() => {
    const xmlFileList = watch(`ListaMediosPagos.${index}.Xml`)
    
    if (xmlFileList) {
      const xmlFile = xmlFileList.item(0) 
      if (xmlFile) {
        const reader = new FileReader()
        reader.onload = () => {
          const xmlContent = reader.result;

          let jsonData;
          try {
            jsonData = xml2js(xmlContent, { compact: true });
          } catch (error) {
    }
          setXmlData(jsonData)
          setIsArchiveSelected(true)
        }
        if (xmlFile) {
          reader.readAsText(xmlFile);
        }else{
          setIsArchiveSelected(false);
        }
      }
    }
  }, [watch(`ListaMediosPagos.${index}.Xml`)])

  return (
    <>
      {watch("ListaMediosPagos").length > 1 ? (
        <h5 className="pt-4">Medio de Pago {index + 1}</h5>
      ) : (
        <h5 className="pt-4">Medio de Pago</h5>
      )}

      <div className="row form-section border pb-3">
        <div className="col-md-3">
          <label className="form-label">Boleta/Factura</label>
          <div className="row">
            <div className="col-3 m-0 p-0 pe-1 ps-2">
              <select
                className="form-select"
                aria-label="Default select example"
                {...register(`ListaMediosPagos.${index}.Comprobante`)}
              >
                <option value="B">B</option>
                <option value="F">F</option>
              </select>
              {errors.ListaMediosPagos?.[index]?.Comprobante && (
                <span className="error">El comprobante es requerido</span>
              )}
            </div>
            <div className="col-4 m-0 p-0 pe-1">
              <input
                type="text"
                className="form-control"
                {...register(`ListaMediosPagos.${index}.Serie`, {
                  required: { value: true, message: "La serie es requerido" },
                  //minLength: 6 || "El comprobante debe de ser de 6 caracteres",
                  maxLength: { value: 6, message: "La serie no debe pasar los 6 caracteres" }
                })}
              />
              {errors.ListaMediosPagos?.[index]?.Serie && (
                <span className="error">{errors.ListaMediosPagos?.[index]?.Serie?.message}</span>
              )}
            </div>

            <div className="col-5 m-0 p-0">
              <input
                type="text"
                className="form-control"
                {...register(`ListaMediosPagos.${index}.Numero`, {
                  required: { value: true, message: "El número es requerido" },
                  //minLength: 8 || "La serie debe de ser de 8 caracteres",
                  maxLength: { value: 8, message: "La serie no debe pasar los 8 caracteres" }
                })}
              />
              {errors.ListaMediosPagos?.[index]?.Numero && (
                <span className="error">{errors.ListaMediosPagos?.[index]?.Numero?.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <label className="form-label">Importe</label>
          <CurrencyInput
            {...register(`ListaMediosPagos.${index}.Importe`, {
              required: true,
            })}
            className="form-control"
            defaultValue={watch(`ListaMediosPagos.${index}.Importe`)}
            decimalSeparator="."
            groupSeparator=","
            decimalsLimit={2}
            decimalScale={2}
            onKeyDown={e => inputNumber(e, true)}
            onValueChange={(value) => setValue(`ListaMediosPagos.${index}.Importe`, value)}
          />
          {errors.ListaMediosPagos?.[index]?.Importe && (
            <span className="error">El importe es requerido</span>
          )}
          {/* <div>{typeof watch(`ListaMediosPagos.${index}.Importe`)}</div> */}
        </div>
        <div className="col-md-3">
          <label className="form-label">Moneda</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`ListaMediosPagos.${index}.Moneda`, {
              required: true,
            })}
            defaultValue={watch(`ListaMediosPagos[0].Moneda`)}
          >
            {listaTipoMoneda.map((tipMon) => {
              if (tipMon.TipMonEst === "S") {
                return (
                  <option
                    key={tipMon.TipMonCod}
                    value={tipMon.TipMonCod}
                  //selected={tipMon.TipMonCod === watch(`ListaMediosPagos[0].Moneda`) && true}
                  >
                    {tipMon.TipMonDes}
                  </option>
                )
              } else {
                return null
              }
            })}
          </select>
          {errors.ListaMediosPagos?.[index]?.Moneda && (
            <span className="error">Especificar el tipo de moneda</span>
          )}
        </div>

        <div className="col-md-3">
          <label className="form-label">Fecha</label>

          <input
            type="date"
            className="form-control"
            defaultValue={new Date(fecha).toISOString().substring(0, 10)}
            {...register(`ListaMediosPagos.${index}.Fecha`, { required: true })}
          />
          {errors.ListaMediosPagos?.[index]?.Fecha && (
            <span className="error">La fecha es requerida</span>
          )}
        </div>

        {/* <div className="col-md-6">
          <label className="form-label">
            Archivo de Medio de Pago (Archivo XML)
          </label>
          <input
            {...register(`ListaMediosPagos.${index}.Xml`, {
              validate: (value) => {
                // Verificar si se seleccionó un archivo
                if (!value[0]) return true;
                // Verificar si el archivo es un XML
                return value[0].type === "text/xml";
              },
              required: true,
            })}
            type="file"
            className="form-control"
            id="inputGroupFile01"
            accept="text/xml"
          />
          {errors.ListaMediosPagos?.[index]?.Xml && (
            <span className="error">El archivo es requerido</span>
          )}
        </div> */}

        <div className="col-md-6">
          <label className="form-label">
            Archivo XML de Boleta/Factura Max 2MB
          </label>
          <XmlInput
            id={`inputFileMedXml${index}`}
            register={register}
            refText={`ListaMediosPagos.${index}.Xml`}
            fileName={datosTramiteLS?.dataMedioPago?.[index] === undefined ? undefined : `Comprobante_Pago_${datosTramiteLS?.idTramite}_${index + 1}.Xml`}
            format={FileFormats.XML}
            formatError={"El archivo no es un XML"}
            min={0}
            minError={"El archivo no debe ser menor a 0 MB"}
            max={2 * 1024 * 1024}
            maxError={"El archivo no debe ser mayor a 2 MB"}
            xmlData={xmlData}
            isArchiveSelected={isArchiveSelected}

          />
          
     
           



          {/* <input
            {...register(`ListaMediosPagos.${index}.Xml`, {
              validate: (value) => {
                // Verificar si se seleccionó un archivo
                if (!value[0]) return true;
                // Verificar si el archivo es un XML
                return value[0].type === "text/xml";
              },
              required: true,//accion === "agregar" ? true : false,
            })}
            type="file"
            className="form-control"
            id="inputGroupFile01"
            accept="text/xml"
          /> */}
          {errors.ListaMediosPagos?.[index]?.Xml && (
            <span className="error">{/* El archivo es requerido */ errors.ListaMediosPagos?.[index]?.Xml.message}</span>
          )}
        </div>

        <div className="col-md-6">
          <label className="form-label">
            Archivo PDF de Boleta/Factura Max 2MB
          </label>
          <FileInput
             id={`inputFileMedPdf${index}`}
            register={register}
            refText={`ListaMediosPagos.${index}.Pdf`}
            fileName={datosTramiteLS?.dataMedioPago?.[index] === undefined ? undefined : `Comprobante_Pago_${datosTramiteLS.idTramite}_${index + 1}.Pdf`}
            format={FileFormats.PDF}
            formatError={"El archivo no es un PDF"}
            min={0}
            minError={"El archivo no debe ser menor a 0 MB"}
            max={2 * 1024 * 1024}
            maxError={"El archivo no debe ser mayor a 2 MB"}
          />
          {/* <input
            {...register(`ListaMediosPagos.${index}.Pdf`, {
              validate: (value) => {
                if (!value[0]) return true;
                return value[0].type === "application/pdf";
              },
              required: true,//accion === "agregar" ? true : false,
            })}
            type="file"
            className="form-control"
            id="inputGroupFile02"
            accept=".pdf"
          /> */}
          {errors.ListaMediosPagos?.[index]?.Pdf && (
            <span className="error">{/* El archivo es requerido */ errors.ListaMediosPagos?.[index]?.Pdf?.message}</span>
          )}
        </div>

        {watch("ListaMediosPagos").length > 1 && (
          <div className="col-12 mt-3">
            <button
              className="btn btn-outline-danger"
              onClick={(e) => {
                e.preventDefault();
                mediosPagosRemove(index);
              }}
            >
              Eliminar Medio de Pago
            </button>
          </div>
        )}
      </div>
    </>
  );
};
