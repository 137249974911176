import React from "react";
import { PlacaEntrega } from "./entrega/PlacaEntrega";

export const SinAcceso = () => {
  const imagen = "/images/sin-acceso.png";

  return (
    <div className="p-3 h-100">

      <PlacaEntrega/>
      <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img
            src={imagen}
            alt="imagen"
            className="img-thumbnail"
            style={{ height: "15rem", width: "15rem" }}
          />
          <h3 className="mt-3">
            No está autorizado para navegar en esta página
          </h3>
          <h6>Comuníquese con el área de Sistemas para solicitar acceso</h6>
        </div>
      </div>
    </div>
  );
};




