import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';


export const ChartsBar = ({ datosCharts }) => {
    const [idCharts, setidCharts] = useState(0)

    const generarNumeroAleatorio = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;

    }

    if (idCharts == 0) {
        const idAleatorio = generarNumeroAleatorio(10000, 99999);
        setidCharts(idAleatorio)
    }



    useEffect(() => {

        // Ejemplo de uso para obtener un número aleatorio de 5 dígitos
        let series = []
        if (datosCharts){
            for(const item of datosCharts.dataUltimosMeses){
                series.push(
                    {
                        name: item.name,
                        data: item.data,
                        dataLabels: {
                            enabled: true,
                            format: `${item.name} {point.y}`, // Formato del texto de los data labels                    
                        }
                    }

                )
                

            }

        }

        Highcharts.chart(`${idCharts}-chart-container`, {
            chart: {
                type: 'bar'
            },
            title: {
                text: null
            },
            tooltip: {
                valueSuffix: ' #'
            },
            plotOptions: {
                bar: {
                    borderRadius: '25%',
                    dataLabels: {
                        enabled: true,
                    },

                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                x: 0,
                y: 0,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },

            series:series,
            xAxis: {
                categories: ["Inmatriculaciones"], 
                lineWidth: 0
            }, 
            yAxis: {
                title: {
                    text: null
                }
            },




        });
    }, []);







    return (
        <>

            {
                idCharts > 0 ?
                    <div id={`${idCharts}-chart-container`} style={{ height: "350px" }}>
                    </div>
                    :
                    <div>
                        cargando
                    </div>
            }

        </>


    );
};
