import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { vehiculoActualizar } from "../../api/vehiculoApi";
import { ruta_historial } from "../../constants/constants-rutas-codigo";
import { DataContext } from "../../context/DataContext";
import Swal from "sweetalert2";
import {  limpiarLocalStorage,  obtenerLocalStorage} from "../../resources/funciones"; 

export const Vehiculo = () => {
  const navigate = useNavigate();

  // const location = useLocation();
  // const vehiculo = location?.state?.vehiculo;
  const vehiculo2 = obtenerLocalStorage("tramite")?.dataVehiculo;
  const idTramite = obtenerLocalStorage("tramite")?.idTramite
  //console.log(vehiculo)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const { listaCombustible } = useContext(DataContext);

  const reenviarSunarp = async () => {

    const dataPOST = {
      TramitId: idTramite,
      NVin: watch("VIN"),
      NSerie: watch("Serie"),
      Marca: watch("Marca"),
      Modelo: watch("Modelo"),
      anio_modelo: watch("Anio"),
      version: watch("Version"),
      categoria: watch("Categoria"),
      carroceria: watch("Carroceria"),
      color: watch("Color"),
      comb_especial: watch("CombEsp"),
      motorN: watch("Motor"),
      potencia_motor: watch("Potencia"),
      tipo_potencia: watch("TipoPotencia"),
      combustible: watch("Combustible"),
      cilindros: watch("Cilindros"),
      cilindrada: watch("Cilindrada"),
      longitud: watch("Longitud"),
      ancho: watch("Ancho"),
      alto: watch("Alto"),
      peso_neto: watch("PNeto"),
      carga_util: watch("CUtil"),
      peso_bruto: watch("PBruto"),
      eje: watch("Ejes"),
      ruedas: watch("Ruedas"),
      asientos: watch("Asientos"),
      pasajeros: watch("Pasajeros"),
      formula_rodante: watch("Formula"),
    };


    const { isConfirmed } = await Swal.fire({
      title: "¿Esta seguro de actualizar los datos?",      
      html:"<p> El bot volverá a generar todos los formatos necesarios en unos minutos</p> <h6 class='text-danger'>Recuerda que deberá validarlo nuevamente. </h6>",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      showLoaderOnConfirm: true,
      cancelButtonText: "Cancelar",
      icon: "info",
      allowOutsideClick: false, // Evitar que el modal se cierre haciendo clic fuera de él
      customClass: {
        confirmButton: "btn-lg",
        cancelButton: "btn-lg",
      },
    });


    if (isConfirmed) {
      const { status } = await vehiculoActualizar(dataPOST);
      if (status === 1) {
        irBandeja();
      } 
    } else {



    }


    
  };




  const irBandeja = () => {
    limpiarLocalStorage("tramite");
    limpiarLocalStorage("pagos");
    navigate(ruta_historial);
  };

  return (
    <>
      <div className="container form-section pt-3">
        <h4>Datos del vehiculo</h4>
        <div className="row mt-3 pb-3 border">
          <div className="col-md-2">
            <label className="form-label">N° VIN</label>
            <input
              {...register("VIN")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuId || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">N° Serie</label>
            <input
              {...register("Serie")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuSerie || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Marca</label>
            <input
              {...register("Marca")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuMarca || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Modelo</label>
            <input
              {...register("Modelo")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuModel || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Año de modelo</label>
            <input
              {...register("Anio")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuAnioModel || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Version</label>
            <input
              {...register("Version")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuVersion || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Longitud</label>
            <div className="row">
              <div className="col-10">
                <input
                  {...register("Longitud")}
                  type="text"
                  className="form-control"
                  // defaultValue={vehiculo2?.VehicuLongt + " metro(s)" || ""}
                  defaultValue={
                    vehiculo2?.VehicuLongt === undefined
                      ? ""
                      : vehiculo2?.VehicuLongt
                  }
                  readOnly
                />
              </div>
              <div className="col-2 ps-0">m.</div>
            </div>
            {/* <input
              type="text"
              className="form-control"
              // defaultValue={vehiculo2?.VehicuLongt + " metro(s)" || ""}
              defaultValue={
                vehiculo2?.VehicuLongt === undefined
                  ? ""
                  : vehiculo2?.VehicuLongt + " metro(s)"
              }
              
            /> */}
          </div>
          <div className="col-md-2">
            <label className="form-label">Ancho</label>
            <div className="row">
              <div className="col-10">
                <input
                  {...register("Ancho")}
                  type="text"
                  className="form-control"
                  // defaultValue={vehiculo2?.VehicuAncho + " metro(s)" || ""}
                  defaultValue={
                    vehiculo2?.VehicuAncho === undefined
                      ? ""
                      : vehiculo2?.VehicuAncho
                  }
                  readOnly
                />
              </div>
              <div className="col-2 ps-0">m.</div>
            </div>
          </div>
          <div className="col-md-2">
            <label className="form-label">Alto</label>

            <div className="row">
              <div className="col-10">
                <input
                  {...register("Alto")}
                  type="text"
                  className="form-control"
                  // defaultValue={vehiculo2?.VehicuAlto + " metro(s)" || ""}
                  defaultValue={
                    vehiculo2?.VehicuAlto === undefined
                      ? ""
                      : vehiculo2?.VehicuAlto
                  }
                  readOnly
                />
              </div>
              <div className="col-2 ps-0">m.</div>
            </div>
          </div>

          <div className="col-md-2">
            <label className="form-label">Ejes</label>
            <input
              {...register("Ejes")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuEje || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Ruedas</label>
            <input
              {...register("Ruedas")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuRuedas || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Asientos</label>
            <input
              {...register("Asientos")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuAsientos || ""}
              readOnly
            />
          </div>

          <div className="col-md-2">
            <label className="form-label">Pasajeros</label>
            <input
              {...register("Pasajeros")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuPasajero || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Formula rodante</label>
            <input
              {...register("Formula")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuFormuRod || ""}
              readOnly
            />
          </div>

          <div className="col-md-2">
            <label className="form-label">Categoria</label>
            <select
              {...register("Categoria")}
              /* type="text" */
              className="form-select"
              defaultValue={vehiculo2?.VehicuCatego || ""}
              readOnly
            >
              <option value="L1">L1</option>
              <option value="L2">L2</option>
              <option value="L3">L3</option>
              <option value="L4">L4</option>
              <option value="L5">L5</option>
              <option value="L6">L6</option>
              <option value="L7">L7</option>
              <option value="M1">M1</option>
              <option value="M2">M2</option>
              <option value="M3">M3</option>
              <option value="N1">N1</option>
              <option value="N2">N2</option>
              <option value="N3">N3</option>
              <option value="O1">O1</option>
              <option value="O2">O2</option>
              <option value="O3">O3</option>
              <option value="O4">O4</option>
            </select>
          </div>
          <div className="col-md-2">
            <label className="form-label">Carroceria</label>
            <select
              {...register("Carroceria")}
              /* type="text" */
              className="form-select"
              defaultValue={vehiculo2?.VehicuCarroc || ""}
              readOnly
            >
              <option value="AMB">AMBULANCIA</option>
              <option value="ARE">ARENERO</option>
              <option value="ART">ARTICULADO</option>
              <option value="AUX">AUXILIO MECÁNICO</option>
              <option value="BAR">BARANDA</option>
              <option value="BAD">BARREDERA</option>
              <option value="BIA">BIARTICULADO</option>
              <option value="BMT">BICIMOTO</option>
              <option value="BHO">BOMBA HORMIGONERA</option>
              <option value="BOM">BOMBERO</option>
              <option value="BOB">BOMBONA</option>
              <option value="CBA">CAMA BAJA</option>
              <option value="CCG">CAMIÓN GRÚA</option>
              <option value="CAB">CARGOBUS</option>
              <option value="CRD">CASA RODANTE</option>
              <option value="CAN">CAÑERO</option>
              <option value="CEL">CELULAR</option>
              <option value="CHC">CHASIS CABINADO</option>
              <option value="CHM">CHASIS MOTORIZADO</option>
              <option value="CIG">CIGÜEÑA</option>
              <option value="CIS">CISTERNA</option>
              <option value="COM">CISTERNA COMBUSTIBLES</option>
              <option value="COB">COMPACTADOR</option>
              <option value="CPT">COMPETENCIA</option>
              <option value="CMP">COMPRESOR</option>
              <option value="RTV">COMUNICACIONES</option>
              <option value="CNV">CONVERTIBLE</option>
              <option value="CPE">COUPÉ</option>
              <option value="CMT">CUATRIMOTO</option>
              <option value="DOL">DOLLY</option>
              <option value="ELV">ELEVADOR</option>
              <option value="ASF">ESPARCIDOR DE ASFALTO</option>
              <option value="ORG">ESPARCIDOR DE MATERIA ORGÁNICA</option>
              <option value="EXP">EXPLOSIVOS</option>
              <option value="FAC">FACTORIA</option>
              <option value="FUN">FUNERARIO</option>
              <option value="FUR">FURGÓN</option>
              <option value="FRG">FURGÓN FRIGORÍFICO</option>
              <option value="TER">FURGÓN ISOTÉRMICO</option>
              <option value="GRA">GRANELERO</option>
              <option value="GEL">GRUPO ELECTRÓGENO</option>
              <option value="GRU">GRÚA</option>
              <option value="HBK">HATCHBACK</option>
              <option value="HOR">HORMIGONERA</option>
              <option value="HOS">HOSPITAL</option>
              <option value="ILM">ILUMINADOR</option>
              <option value="INS">INSTRUCCIÓN</option>
              <option value="INT">INTERCAMBIADOR</option>
              <option value="LIM">LIMOSINA</option>
              <option value="LUB">LUBRICADOR</option>
              <option value="MAD">MADRINA</option>
              <option value="MEZ">MEZCLADOR</option>
              <option value="MIC">MICROBUS</option>
              <option value="MIN">MINIBUS</option>
              <option value="MSD">MOTO SIDECAR</option>
              <option value="MTT">MOTO TODO TERRENO</option>
              <option value="MTO">MOTOCICLETA</option>
              <option value="MUL">MULTIFUNCIÓN</option>
              <option value="MPO">MULTIPROPÓSITO</option>
              <option value="OMI">OMNIBUS INTERURBANO</option>
              <option value="OMP">OMNIBUS PANORAMICO</option>
              <option value="OMN">OMNIBUS URBANO</option>
              <option value="OTR">OTROS USOS ESPECIALES</option>
              <option value="PAN">PANEL</option>
              <option value="PER">PERFORADOR</option>
              <option value="PUP">PICK UP</option>
              <option value="PLA">PLATAFORMA</option>
              <option value="PCO">PORTA CONTENEDOR</option>
              <option value="POR">PORTA TROPAS</option>
              <option value="QUI">QUILLA</option>
              <option value="RAD">RADIOLOGICO</option>
              <option value="REM">REMOLCADOR</option>
              <option value="REG">REMOLCADOR GRUA</option>
              <option value="RPV">REPARAVIAS</option>
              <option value="ROM">ROMPE MANIFESTACIONES</option>
              <option value="SAN">SANITARIO</option>
              <option value="SED">SEDAN</option>
              <option value="SWG">STATION WAGON</option>
              <option value="SUV">SUV</option>
              <option value="TCA">TANQUE CALORIFICO</option>
              <option value="TCO">TANQUE CORROSIVO</option>
              <option value="CRI">TANQUE CRIOGENICO</option>
              <option value="TFR">TANQUE FRIGORIFICO</option>
              <option value="GLP">TANQUE GLP</option>
              <option value="GNC">TANQUE GNC</option>
              <option value="TIS">TANQUE ISOTÉRMICO</option>
              <option value="TRA">TRANSFORMADOR</option>
              <option value="NAV">TRANSPORTE DE NAVES</option>
              <option value="TRM">TRIMOTO CARGA</option>
              <option value="TRI">TRIMOTO PASAJEROS</option>
              <option value="TRO">TROLEBÚS</option>
              <option value="TUB">TUBULAR</option>
              <option value="VAL">VALORES</option>
              <option value="FER">VEHÍCULO DE FERIAS</option>
              <option value="VOL">VOLQUETE</option>
              <option value="VFC">VOLQUETE FUERA DE CARRETERA</option>
            </select>
          </div>
          <div className="col-md-2">
            <label className="form-label">Cilindros</label>
            <input
              {...register("Cilindros")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuCilind || ""}
              readOnly
            />
          </div>

          <div className="col-md-2">
            <label className="form-label">Comb. Especial</label>
            <input
              {...register("CombEsp")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuCombSpecial || ""}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Color</label>
            <input
              {...register("Color")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuColor || ""}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Combustible</label>
            <select
              {...register("Combustible")}
              className="form-select"
              aria-label="Default select example"
              defaultValue={vehiculo2?.VehicuCombusti}
            >
              {listaCombustible.map((c) => {
                return <option value={c.codigo}>{c.descripcion}</option>;
              })}
            </select>
          </div>
          <div className="col-md-2">
            <label className="form-label">Motor N°</label>
            <input
              {...register("Motor")}
              type="text"
              className="form-control"
              defaultValue={vehiculo2?.VehicuMotorN || ""}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Potencia del motor</label>
            <div className="row">
              <div className="col-6 pe-1">
                <input
                  {...register("Potencia")}
                  type="text"
                  className="form-control"
                  // defaultValue={vehiculo2?.VehicuPotenMotor + " KW/RPM" || ""}
                  defaultValue={
                    vehiculo2?.VehicuPotenMotor === undefined
                      ? ""
                      : vehiculo2?.VehicuPotenMotor
                  }
                  readOnly
                />
              </div>
              <div className="col-6 ps-1">
                <select {...register("TipoPotencia")} className="form-select">
                  <option value="001">Kw/RPM</option>
                  <option value="002">HP/RPM</option>
                </select>
              </div>
            </div>
          </div>


          <div className="col-md-2">
            <label className="form-label">Cilindrada</label>
            <div className="row">
              <div className="col-10">
                <input
                  {...register("Cilindrada")}
                  type="text"
                  className="form-control"
                  // defaultValue={vehiculo2?.VehicuCilindrad + " litro(s)" || ""}
                  defaultValue={
                    vehiculo2?.VehicuCilindrad === undefined
                      ? ""
                      : vehiculo2?.VehicuCilindrad
                  }
                  readOnly
                />
              </div>
              <div className="col-2 ps-0">L</div>
            </div>
          </div>

          <div className="col-md-2">
            <label className="form-label">Peso Neto</label>
            <div className="row">
              <div className="col-10">
                <input
                  {...register("PNeto")}
                  type="text"
                  className="form-control"
                  // defaultValue={vehiculo2?.VehicuPesoNet + " tonelada(s)" || ""}
                  defaultValue={
                    vehiculo2?.VehicuPesoNet === undefined
                      ? ""
                      : vehiculo2?.VehicuPesoNet
                  }

                />
              </div>
              <div className="col-2 ps-0">Tn</div>
            </div>
          </div>
          <div className="col-md-2">
            <label className="form-label">Carga Util</label>
            <div className="row">
              <div className="col-10">
                <input
                  {...register("CUtil")}
                  type="text"
                  className="form-control"
                  // defaultValue={vehiculo2?.VehicuCargaUtil + " tonelada(s)" || ""}
                  defaultValue={
                    vehiculo2?.VehicuCargaUtil === undefined
                      ? ""
                      : vehiculo2?.VehicuCargaUtil
                  }

                />
              </div>
              <div className="col-2 ps-0">Tn</div>
            </div>
          </div>
          <div className="col-md-2">
            <label className="form-label">Peso Bruto</label>
            <div className="row">
              <div className="col-10">
                <input
                  {...register("PBruto")}
                  type="text"
                  className="form-control"
                  // defaultValue={vehiculo2?.VehicuPesoBruto + " tonelada(s)" || ""}
                  defaultValue={
                    vehiculo2?.VehicuPesoBruto === undefined
                      ? ""
                      : vehiculo2?.VehicuPesoBruto
                  }
                />
              </div>
              <div className="col-2 ps-0">Tn</div>
            </div>
          </div>


        </div>

        <div className="col-12 mt-3 py-3 text-center">
          <button className="btn btn-secundario mx-3 ps-5 pe-5" onClick={() => navigate(-1)}>
            Volver
          </button>
          <button className="btn btn-main mx-3 ps-5 pe-5" onClick={reenviarSunarp}>Actualizar</button>
          <button className="btn btn-main mx-3 ps-3 pe-3" onClick={irBandeja}>
            Ir a Inmatriculaciones
          </button>

        </div>

        {/* <div>
          <table className="table">
            <tbody>
              <tr>
                <td>Numero del VIN</td>
                <td>9BWBH6BF2P4027361</td>
                <td>Numero de serie</td>
                <td>9BWBH6BF2P4027361</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};
