import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api, apiSeg } from "../../api/configApi";
import { ruta_historial } from "../../constants/constants-rutas-codigo";
import { cambiarColores, guardarLocalStorage, obtenerLocalStorage } from "../../resources/funciones";
import { CONST_CODIGO_PARAMETRO } from "../../constants/constants-codigo";
import { Loader } from "./Loader";

export const IniciandoSesion = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { token } = location.state;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onLogin = async () => {
      const dataPOST = {
        token: token,
        parametro: CONST_CODIGO_PARAMETRO,
      };
      const startTime = Date.now(); // Hora de inicio de la petición

      // Inicializa el temporizador para mostrar el Loader al menos durante 2 segundos
    
        try {
          const respuesta = await api.post("api/common/LoginExterno", dataPOST);
          
          if (respuesta.status === 201) {
            const status = respuesta.data.status;
            //const message = respuesta?.data?.message || "Tiene acceso al sistema";
            const data = respuesta.data;
            if (status === 1) {
              guardarLocalStorage(data, "usuario");

              const estilosCompania = obtenerLocalStorage("usuario")?.estilo
              if (estilosCompania) {
                const colores = [
                    { name: '--color-fondo-menu-primario', color: estilosCompania?.fondo_menu },
                    { name: '--color-texto-nav-item', color: estilosCompania?.text_opciones },
                    { name: '--color-fondo-nav-item-activo', color: estilosCompania?.fond_opc_activo },
                    { name: '--color-texto-nav-item-activo', color: estilosCompania?.text_opc_activo },
                    { name: '--color-button-primario', color: estilosCompania?.boton_primario },
                    { name: '--color-button-primario-hover', color: estilosCompania?.boton_primario_activo },
                    { name: '--color-button-primario-text', color: estilosCompania?.text_boton_primario },
                    { name: '--color-button-secundario', color: estilosCompania?.boton_secundario },
                    { name: '--color-button-secundario-hover', color: estilosCompania?.boton_secundario_activo },
                    { name: '--color-button-secundario-text', color: estilosCompania?.text_boton_secundario },
                ];
                cambiarColores(colores);
              } else {
                // const colores = [
                //     { name: '--color-fondo-menu-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_FONDO_MENU_PRIMARIO },
                //     { name: '--color-texto-nav-item', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_TEXTO_NAV_ITEM },
                //     { name: '--color-fondo-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_FONDO_NAV_ITEM_ACTIVO },
                //     { name: '--color-texto-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_TEXTO_NAV_ITEM_ACTIVO },
                //     { name: '--color-button-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO },
                //     { name: '--color-button-primario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_HOVER },
                //     { name: '--color-button-primario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_TEXT },
                //     { name: '--color-button-secundario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO },
                //     { name: '--color-button-secundario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_HOVER },
                //     { name: '--color-button-secundario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_TEXT},
                // ];
                // cambiarColores(colores);
              }
            }
          }
        } catch (error) {
          // Manejar errores de la petición aquí, si es necesario

        } finally {
          const endTime = Date.now(); // Hora de finalización de la petición
          const requestDuration = endTime - startTime; // Duración de la petición en milisegundos
  
          // Calculamos el tiempo que el Loader debe mostrar, al menos 0.8 segundos
          const minLoaderDuration = 1000; // Mínimo de 0.8 segundos
          const loaderDuration = Math.max(minLoaderDuration, requestDuration);
  
          // Esperar el tiempo calculado antes de ocultar el Loader
          setTimeout(() => {
            setIsLoading(false);
            navigate(ruta_historial);
          }, loaderDuration);
        }
    }
    onLogin();
  }, []);

  return (
    isLoading ? <Loader /> : null
  )
};
