/* import { getStyleValue } from "@mui/system"; */
import React, { useContext, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
//import numeral from 'numeral'

import { DataContext } from "../../context/DataContext";
import { inputNumber } from '../../resources/funciones'
import { FileFormats, FileInput } from "../layout/FileInput";
import { obtenerLocalStorage , isJustLAndN} from "../../resources/funciones";

export const TramiteCancelacion = ({
  index,
  register,
  watch,
  errors,
  /* fecha,
  item, */
  setValue,
  // getTotal,
  reset,
  cancelacionesRemove,
}) => {
  const { listaTipoMoneda, listaBancos, listaOperaciones } =
    useContext(DataContext);

  /* let monto = 0; */
  const datosTramiteLS = obtenerLocalStorage("tramite")

  useEffect(() => {
    if (
      watch(`ListaCancelaciones.${index}.Moneda`) ===
      watch(`ListaMediosPagos[0].Moneda`)
    ) {
      setValue(`ListaCancelaciones.${index}.TipoCambio`, null);
    }
    // eslint-disable-next-line
  }, [watch(`ListaCancelaciones.${index}.Moneda`),watch(`ListaMediosPagos[0].Moneda`)]);

  useEffect(() => {
    const newvalue = watch(`ListaCancelaciones.${index}.Operacion`)?.replace(".","-")
    setValue(`ListaCancelaciones.${index}.Operacion`,newvalue)
  }, [watch(`ListaCancelaciones.${index}.Operacion`)]);
  
 /*  useEffect(() => {
    const importe =   watch(`ListaCancelaciones.${index}.Importe`)
    const tc = watch(`ListaCancelaciones.${index}.TipoCambio`)
    const monedaCan = watch(`ListaCancelaciones.${index}.Moneda`)
    const monedaMd =  watch(`ListaMediosPagos[0].Moneda`)
    if(monedaCan === monedaMd){
      setValue(`ListaCancelaciones.${index}.MonCancelacion`,importe)
    }else{
      if(monedaMd === 'USD' && monedaCan == 'PEN'){
        setValue(`ListaCancelaciones.${index}.MonCancelacion`,tc&&tc!==0?Math.floor((importe/tc*100))/100:0)
      }else if(monedaMd === 'PEN' && monedaCan == 'USD'){
        setValue(`ListaCancelaciones.${index}.MonCancelacion`,importe*tc)
      }
    }     
    
    // eslint-disable-next-line
  }, [
    watch(`ListaCancelaciones.${index}.Importe`),
    watch(`ListaCancelaciones.${index}.TipoCambio`),    
    watch(`ListaMediosPagos[0].Moneda`)
  ]); */
  /* useEffect(()=>{
    const monCan = numeral(watch(`ListaCancelaciones.${index}.MonCancelacion`)).format('0,0.00')
    setValue(`ListaCancelaciones.${index}.MonCancelacion_formated`,monCan)
  },[]) */

  const calcularMontoCan = ()=>{
    const importe =   watch(`ListaCancelaciones.${index}.Importe`)
    const tc = watch(`ListaCancelaciones.${index}.TipoCambio`)
    const monedaCan = watch(`ListaCancelaciones.${index}.Moneda`)
    const monedaMd =  watch(`ListaMediosPagos[0].Moneda`)
    let calc/* ,formated */
    if(monedaCan === monedaMd){
      /* formated = numeral(importe).format('0,0.00') */
      setValue(`ListaCancelaciones.${index}.MonCancelacion`,importe)
      /* setValue(`ListaCancelaciones.${index}.MonCancelacion_formated`,formated) */
    }else{      
      if(monedaMd === 'USD' && monedaCan === 'PEN'){
        calc = tc&&tc!==0?Math.floor((importe/tc*100))/100:0
       /*  formated = numeral(calc).format('0,0.00') */
        setValue(`ListaCancelaciones.${index}.MonCancelacion`,calc)
        /* setValue(`ListaCancelaciones.${index}.MonCancelacion_formated`,formated) */
      }else if(monedaMd === 'PEN' && monedaCan === 'USD'){
        calc = Math.floor(importe*tc*100)/100
       /*  formated = numeral(calc).format('0,0.00') */
        setValue(`ListaCancelaciones.${index}.MonCancelacion`,calc)
        /* setValue(`ListaCancelaciones.${index}.MonCancelacion_formated`,formated) */
      }
    }   
  } 
  

  return (
    <>      
      {watch("ListaCancelaciones").length > 1 ? (
        <h5 className="pt-4">Forma de Cancelacion {index + 1}</h5>
      ) : (
        <h5 className="pt-4">Forma de Cancelacion</h5>
      )}
      <div className="row form-section border pb-3">
        <div className="col-md-3">
          <label className="form-label">Banco</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`ListaCancelaciones.${index}.Banco`)}
            value={watch(`ListaCancelaciones.${index}.Banco`)}
          >
            <option value="">---Seleccione--- </option>
            {listaBancos.map((ban) => {
              if (ban.BancEst === "S") {
                return <option key={ban.BancCod} value={ban.BancCod}>{ban.BancDes}</option>;
              } else {
                return null
              }
            })}
          </select>
        </div>

        <div className="col-md-3">
          <label className="form-label">Importe</label>
          <CurrencyInput
            {...register(`ListaCancelaciones.${index}.Importe`, {
              required: true,
            })}
            className="form-control"
            defaultValue={watch(`ListaCancelaciones.${index}.Importe`)}
            decimalSeparator="."
            groupSeparator=","
            decimalsLimit={2}
            decimalScale={2}
            onKeyDown={e=>inputNumber(e,true)}
            onValueChange={(value) =>
              {setValue(`ListaCancelaciones.${index}.Importe`, value)
              calcularMontoCan()}
            }
          />
          {errors.ListaCancelaciones?.[index]?.Importe && (
            <span className="error">El importe es requerido</span>
          )}
        </div>

        <div className="col-md-3">
          <div className="row">
            <div
              className={
                (watch(`ListaCancelaciones.${index}.Moneda`) !==
                watch(`ListaMediosPagos[0].Moneda`)) ? "col-6" : ""
              }
            >
              <label className="form-label">Moneda</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={watch(`ListaCancelaciones.${index}.Moneda`)}
                {...register(`ListaCancelaciones.${index}.Moneda`, {
                  required: true,
                })}
              >
                {listaTipoMoneda.map((tipMon) => {
                  if (tipMon.TipMonEst === "S") {
                    return (
                      <option 
                        key={tipMon.TipMonCod}
                        value={tipMon.TipMonCod}>
                        {tipMon.TipMonDes}
                      </option>
                    )
                  } else {
                    return null
                  }
                })}
              </select>
              {errors.ListaCancelaciones?.[index]?.Moneda && (
                <span className="error">El tipo de moneda es requerido</span>
              )}
            </div>

            {watch(`ListaCancelaciones.${index}.Moneda`) !==
              watch(`ListaMediosPagos[0].Moneda`) && (
                <div className="col-6">
                  <label className="form-label">T. Cambio</label>
                  <CurrencyInput
                    readOnly={
                      watch(`ListaCancelaciones.${index}.Moneda`) ===
                        watch(`ListaMediosPagos[0].Moneda`)
                        ? true
                        : false
                    }
                    className="form-control"
                    value={
                      watch(
                        `ListaCancelaciones.${index}.TipoCambio`
                      )?.toString() === ""
                        ? 0
                        : watch(`ListaCancelaciones.${index}.TipoCambio`)
                    }
                    decimalSeparator="."
                    groupSeparator=","
                    decimalsLimit={2}
                    decimalScale={2}
                    onValueChange={(value) => {
                      // console.log("Moneda Canc ", watch(`ListaCancelaciones.${index}.Moneda`))
                      // console.log("Moneda MP ", watch(`ListaMediosPagos[0].Moneda`))
                      // if(watch(`ListaCancelaciones.${index}.Moneda`) === watch(`ListaMediosPagos[0].Moneda`)){
                      //   console.log("cambio de value a 0")
                      //   value = 0
                      // }
                      calcularMontoCan()
                      if (
                        watch(`ListaCancelaciones.${index}.Moneda`) ===
                        watch(`ListaMediosPagos[0].Moneda`)
                      ) {
                        value = 0;
                      }
                      setValue(`ListaCancelaciones.${index}.TipoCambio`, value);
                    }}
                    {...register(`ListaCancelaciones.${index}.TipoCambio`, {
                      required:
                        watch(`ListaCancelaciones.${index}.Moneda`) ===
                        watch(`ListaMediosPagos[0].Moneda`),
                    })}
                  />
                  {errors.ListaCancelaciones?.[index]?.TipoCambio && (
                    <span className="error">El tipo de cambio es requerido</span>
                  )}
                </div>
              )}
          </div>
        </div>


        <div className="col-md-3">
          <label className="form-label">Cancelacion</label>
          <CurrencyInput
            {...register(`ListaCancelaciones.${index}.MonCancelacion`, {
              required: true,
            })}
            disabled={
              watch(`ListaCancelaciones.${index}.Moneda`) ===
                watch(`ListaMediosPagos[0].Moneda`)
                ? true
                : false
              }
            className="form-control"            
            decimalSeparator="."
            groupSeparator=","
            decimalsLimit={2}
            decimalScale={2}
            onKeyDown={e=>inputNumber(e,true)}
            value={watch(`ListaCancelaciones.${index}.MonCancelacion`)}
            onValueChange={(value)=>setValue(`ListaCancelaciones.${index}.MonCancelacion`,value)}
          />
          {/* <input 
            className='form-control' 
            type='text' 
            value={watch(`ListaCancelaciones.${index}.MonCancelacion_formated`)}
            onChange={(e)=>{
              const value = e.target.value
              const cleanValue = value.replace(/[^0-9.]/g, '')
              const formatedValue = numeral(cleanValue).format('0,0.00')
              setValue(`ListaCancelaciones.${index}.MonCancelacion`,cleanValue)
              setValue(`ListaCancelaciones.${index}.MonCancelacion_formated`,formatedValue)
            }}
          /> */}
        </div>


        <div className="col-md-3">
          <label className="form-label">Fecha</label>
          <input
            type="date"
            className="form-control"
            {...register(`ListaCancelaciones.${index}.Fecha`, {
              required: true,
            })}
          />
          {errors.ListaCancelaciones?.[index]?.Fecha && (
            <span className="error">La fecha es requerido</span>
          )}
        </div>
        
        <div className="col-md-3">
          <label className="form-label">Codigo</label>
          <select
            className="form-select"
            {...register(`ListaCancelaciones.${index}.Codigo`, {
              required: true,
            })}
          >
            <option value="">---Seleccione---</option>
            {listaOperaciones.map((op) => {
              return (
                <option key={op.codigo} value={op.codigo}>
                  {op.codigo} - {op.operacion}
                </option>
              );
            })}
          </select>
          {errors.ListaCancelaciones?.[index]?.Codigo && (
            <span className="error">El codigo es requerido</span>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label">Operación</label>
          <input
            type="text"
            className="form-control"
            {...register(`ListaCancelaciones.${index}.Operacion`,{required:true})}
            onChange={(e)=>isJustLAndN(e)}            
          />{errors.ListaCancelaciones?.[index]?.Operacion && (
            <span className="error">La Operación es requerida</span>
          )}
        </div>

        <div className="col-md-4">
          <label className="form-label ">
            Archivo PDF de Cancelacion Max 2MB
          </label>
          <FileInput 
            id={`inputFileCan${index}`}
            register={register}
            refText={`ListaCancelaciones.${index}.Pdf`}
            fileName={datosTramiteLS?.dataCancelacion?.[index] === undefined ? undefined : `Cancelacion_${datosTramiteLS.idTramite}_${index+1}.Pdf`}
            format={FileFormats.PDF}
            formatError={"El archivo no es un PDF"}
            min={0}
            minError={"El archivo no debe ser menor a 0 MB"}
            max={2*1024*1024}
            maxError={"El archivo no debe ser mayor a 2 MB"} 
          />
          {/* <input
            {...register(`ListaCancelaciones.${index}.Pdf`, {
              validate: (value) => {
                if (!value[0]) return true;
                return value[0].type === "application/pdf";
              },
              required: true,
            })}
            type="file"
            className="form-control"
            id={`inputFileCan${index}`}
            accept=".pdf"
          /> */}
          {errors.ListaCancelaciones?.[index]?.Pdf && (
            <span className="error">{/* El archivo es requerido */ errors.ListaCancelaciones?.[index]?.Pdf?.message}</span>
          )}
        </div>

       {/*  <div className="col-md-3">
          <label className="form-label">
            Vincular Medio de Pago
          </label>
          <select className="form-select" disabled={!watch(`ListaCancelaciones.${index}.Importe`)} onChange={e => calcularTC(index, e.target.value)}>
            <option value="">Seleccionar</option>
            {watch("ListaMediosPagos").map((mp, index) => (
              mp.Importe && (<option value={index}>{`Medio de Pago ${index + 1} - ${mp.Importe}`}</option>)
            ))}
          </select>
        </div> */}

        {watch("ListaCancelaciones").length >= 1 && (
          <div className="col-12 mt-3">
            <button
              className="btn btn-outline-danger"
              onClick={(e) => {
                e.preventDefault();
                cancelacionesRemove(index);
              }}
            >
              Eliminar Cancelación
            </button>
          </div>
        )}
      </div>
    </>
  );
};
