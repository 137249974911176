import packageJson from '../../package.json'

export default function caching (){
    
    let version = localStorage.getItem('version'); 
    window.location.reload(true)
    if(version!==packageJson.version){
        if('caches' in window){
            caches.keys().then(names => { // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
                console.log("Se ha encontrado una version mas reciente.")
            });
          //  window.location.reload(true)
            // Makes sure the page reloads. Changes are only visible after you refresh.
            
        }

        /*
        localStorage.clear();
        localStorage.setItem('version',packageJson.version);

        */
    }
}