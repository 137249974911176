import { desencriptar } from "../resources/funciones";
import { api, apiSeg } from "./configApi";

export const loginAuth = async (dataPOST) => {
    try{
        
        const res = await api.post("api/common/login", dataPOST)

        const resDesencriptado = desencriptar(res.data.body)
        
        if(res.status === 201){
            return{
                status: resDesencriptado.status,
                message: resDesencriptado.message,
                data: resDesencriptado
            }
        }
        
        if(res.status === 200){
            return{
                status: res.data.status,
                message: res.data.message ,
                data: ""
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error en el login",
                data: ""
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            data: ""
        };
    }
}

export const navegarOtraApp = async (dataPOST) => {
    try {

        const res = await api.post("api/common/OtrosSistemas", dataPOST)

        if (res?.data?.status === 1) {
            return {
                status: res.data.status,
                apps: res.data.rutas,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                apps: [],
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            apps: [],
            message: "Error en el servidor",
        };
    }
}

export const verificarUsuario = async (dataPOST) => {
    try {

        // console.log(dataPOST)

        const res = await api.post("api/common/VerificarUseMod", dataPOST)

        // console.log("res",res)
 
        // res.status 201
        if (res?.data?.status === 1) {
            return {
                status: res.data.status,
                autenticado: res.data.valor,
                message: res.data.message,
            }
        }

    } catch (error) {

        // console.log("error", error.response.data)

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                autenticado: false,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            autenticado: false,
            message: "Error en el servidor",
        };
    }
}

export const usuarioCambiarContrasenia = async (dataPOST) => {
    try {

        const res = await api.post("api/common/ActualizarContrasenia", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}