import React from 'react'; 
import { useEffect, useState } from 'react';
import { FaHourglassHalf, FaCheckCircle, FaTruck, FaTimesCircle } from 'react-icons/fa';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ChartsPie } from '../../middlewares/highcharts/ChartsPie';
import { ChartsLine } from '../../middlewares/highcharts/ChartsLine';
import { ChartsBar } from '../../middlewares/highcharts/ChartsBar';
import { ChartsBarRace } from '../../middlewares/highcharts/ChartsbarRace';
import { getDashboardApi } from '../../api/tramiteApi';

const coloresProceso = [
    { id: 0, estado: "Anuladas", background: "rgb(207, 21, 38)", background_hover: "rgb(207, 21, 38,0.8)" },
    { id: 1, estado: "Pendientes", background: "rgb(41, 30, 133)", background_hover: "rgb(41, 30, 133,0.8)" },
    { id: 2, estado: "Presentados", background: "rgb(32, 121, 233)", background_hover: "rgb(32, 121, 233,0.8)" },
    { id: 3, estado: "Placas", background: "rgb(246, 144, 14)", background_hover: "rgb(246, 144, 14 ,0.8)" },
    { id: 4, estado: "Entregas", background: "rgb(67, 160, 71)", background_hover: "rgb(67, 160, 71 ,0.8)" }
]


export const Dashboard = () => {

    const [listaInmatriculaciones, setListaInmatriculaciones] = useState({ cargo: false })


    useEffect(() => {


        const getdata = async () => {

            const dataPOST = []

            let resultado = await getDashboardApi(dataPOST)

            let ListaTramites = resultado.data.RptInmatriculacionesxEstado
            const ListaTramitesPendientes = ListaTramites.filter(item => item.TramitEst > "00" && item.TramitEst <= "08");
            const ListaTramitesPresentados = ListaTramites.filter(item => item.TramitEst == "09");
            const ListaTramitesInscritos = ListaTramites.filter(item => item.TramitEst == "107");
            const ListaTramitesPlacas = ListaTramites.filter(item => item.TramitEst == "10" || item.TramitEst == "11");
            const ListaTramitesEntregados = ListaTramites.filter(item => item.TramitEst == "12");
            const ListaTramitesAnulados = ListaTramites.filter(item => item.TramitEst == "00");

            let result = {
                cargo: true,
                RptInmatriculacionesxEstado: {
                    ListaTramitesPendientes, ListaTramitesPresentados, ListaTramitesAnulados, ListaTramitesInscritos, ListaTramitesPlacas, ListaTramitesEntregados, total: ListaTramites.length
                },
                listaSede: resultado.data.listaSede,
                RptInmatriculacionesxSede: resultado.data.RptInmatriculacionesxSede,
                dataMesesxSede: resultado.data.dataMesesxSede,
                dataUltimosMeses: resultado.data.dataUltimosMeses
            }

            setListaInmatriculaciones(result)


        }

        getdata()





    }, [])


    return (


        <div className="container-fluid p-0" style={{ background: "#f0f2f5" }} >


            { /* ----------------------- TITULO ------------------------------------ */}

            <div className="container-fluid  border-bottom border-1 border-secondary bg-gradient" style={{ background: "#43476a" }}>
                <div className="row">
                    <div className="col-6 text-center pt-2">
                        <h2 className="text-center fs-2 text-white">Dashboard</h2>
                    </div>
                    <div className="col-6 text-center">
                        <img className="img-fluid" src="https://daltongac.com/wp-content/uploads/2023/11/emzoom-color-gris-grafenoo.png"
                            alt="camioneta negra" style={{ borderRadius: "20px", height: "60px" }} />
                    </div>
                </div>
            </div>


            {listaInmatriculaciones.cargo &&
                <>

                    <div className="container-fluid m-0 p-0">

                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 m-0 p-0 pt-2">




                            <div className="p-2 pt-0">
                                <div className="p-2 d-flex align-items-center bg-gradient-3" style={{ borderRadius: "5px", background: `${coloresProceso[0].background}` }}>

                                    <div className='col-4'>
                                        <FaTimesCircle size={40} color="#FFFFFF" className='mx-1' />
                                    </div>
                                    <div className='row col-4'>
                                        <span style={{ color: '#ffffff', fontSize: '1.2rem', fontWeight: 'bold' }}>{listaInmatriculaciones.RptInmatriculacionesxEstado.ListaTramitesAnulados.length}&nbsp;Anuladas</span>
                                    </div>
                                </div>
                            </div>



                            <div className="p-3 pt-0">
                                <div className="p-2 d-flex align-items-center bg-gradient-3" style={{ borderRadius: "5px", background: `${coloresProceso[1].background}` }}>
                                    <div className='col-4'>
                                        <FaHourglassHalf size={40} color="#FFFFFF" className='mx-1' />
                                    </div>
                                    <div className='row col-4'>
                                        <span className='w-100' style={{ color: '#ffffff', fontSize: '1.2rem', fontWeight: 'bold' }}>{listaInmatriculaciones.RptInmatriculacionesxEstado.ListaTramitesPendientes.length}&nbsp;Pedientes</span>
                                    </div>

                                </div>

                            </div>


                            <div className="p-3 pt-0">
                                <div className="p-2 d-flex align-items-center bg-gradient-3" style={{ borderRadius: "5px", background: `${coloresProceso[2].background}` }}>

                                    <div className='col-4'>
                                        <FaCheckCircle size={40} color="#FFFFFF" className='mx-1' />
                                    </div>
                                    <div className='row col-4'>
                                        <span style={{ color: '#ffffff', fontSize: '1.2rem', fontWeight: 'bold' }}>{listaInmatriculaciones.RptInmatriculacionesxEstado.ListaTramitesPresentados.length}&nbsp;Presentados</span>
                                    </div>
                                </div>
                            </div>



                            <div className="p-3 pt-0">
                                <div className="p-2 d-flex align-items-center bg-gradient-3" style={{ borderRadius: "5px", background: `${coloresProceso[3].background}` }}>
                                    <div className='col-4'>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="40" fill="#FFFFFF" className="bi bi-calendar2-minus" viewBox="0 0 16 16">
                                            <path d="M5.5 10.5A.5.5 0 0 1 6 10h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                                            <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" />
                                        </svg>
                                    </div>
                                    <div className='row col-4'>
                                        <span style={{ color: '#ffffff', fontSize: '1.2rem', fontWeight: 'bold' }}>{listaInmatriculaciones.RptInmatriculacionesxEstado.ListaTramitesPlacas.length}&nbsp;Placa</span>
                                    </div>
                                </div>
                            </div>


                            <div className="p-3 pt-0">
                                <div className="p-2 d-flex align-items-center bg-gradient-3" style={{ borderRadius: "5px", background: `${coloresProceso[4].background}` }}>

                                    <div className='col-4'>
                                        <FaTruck size={40} color="#FFFFFF" className='mx-1' />
                                    </div>
                                    <div className='row col-4'>
                                        <span style={{ color: '#ffffff', fontSize: '1.2rem', fontWeight: 'bold' }}>{listaInmatriculaciones.RptInmatriculacionesxEstado.ListaTramitesEntregados.length}&nbsp;Entregadas</span>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="row m-0 p-0 ">

                            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 m-0 p-0" >
                                <div className="m-3 bg-white border-box rounded-2">
                                    {/*  <PieChartDemo datosPie={listaInmatriculaciones} /> */}
                                    <p className='fs-5 text-start ps-3' >
                                        {listaInmatriculaciones.RptInmatriculacionesxEstado.total} Inmatriculaciones:
                                    </p>
                                    <div className="col-12">
                                        <ChartsPie datosCharts={listaInmatriculaciones} />

                                    </div>
                                </div>

                            </div>

                            <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-0 p-0" >

                                <div className="m-3 bg-white border-box rounded-2">
                                    <p className='fs-5 text-start ps-3' >
                                        Inmatriculaciones de los ultimos meses:
                                    </p>
                                    <ChartsBar datosCharts={listaInmatriculaciones} />
                                </div>
                            </div>
                        </div>
                    </div>





                    <div className='p-3 '>

                        <div className="row m-0 p-0">


                            <div className="col-lg-8 col-md-12 col-sm-12 pb-5  " >
                                <div className="container-fluid bg-white border-box">

                                    <div className="pt-4">

                                        <div className=" " style={{ Height: "800px" }}>
                                            <div className="container row">
                                                <p className='pt-1 fs-5  ps-3 col-12'>
                                                    Inmatriculaciones x sede:
                                                </p>
                                            </div>


                                            <ChartsLine datosCharts={listaInmatriculaciones} />
                                            {false &&

                                                <div className="col  mt-3 p-3 border-box-5 p-4" style={{ "background": "#f7d97f", display: "inline-block" }} >
                                                    <p className='p-1'> <strong>Nota: </strong>
                                                        No estan incluidas las inmatriculaciones anuladas
                                                    </p>
                                                </div>}
                                        </div>
                                    </div>
                                </div>

                            </div>




                            { /* ----------------------------------------------------------- */}


                            <div className="col-lg-4 col-md-12 col-sm-12 " >
                                <div className="container text-center bg-white m-0 p-0 pt-4 border-box">

                                    <p className='fs-5 text-start ps-3' >
                                        # Top Inmatriculaciones
                                        <span className='pe-5' style={{ "float": "right" }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="green" className="bi bi-arrow-up-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                                            </svg>
                                        </span>
                                    </p>

                                    <ChartsBarRace datosCharts={listaInmatriculaciones} />

                                    <div className="row justify-content-md-center d-none">

                                        <div className="col-12 col-md-12 col-lg-10 bg-white m-0 p-0 pt-5 border-box">
                                            <table className="table text-end"  >


                                                <tbody className='bg-text '>

                                                    {listaInmatriculaciones.listaSede.map((item, index) => {



                                                        return (
                                                            <tr key={index}>
                                                                <td className='text-start fw-semibold ps-3 '> {item.sede.toLowerCase()} </td>
                                                                <td className='fw-bold' >#</td>
                                                                <td className='fw-bold pe-4' >{item.total}</td>
                                                            </tr>
                                                        )

                                                    })}



                                                </tbody>


                                            </table>



                                        </div>





                                    </div>


                                </div>




                            </div>



                            { /* ----------------------------------------------------------- 
                            
                              <div className="col-6"><PieChart datosPie={listaInmatriculaciones} />  </div>
                              
                              
                              */}









                        </div>
                    </div>

                </>

            }


        </div>


    )


}
