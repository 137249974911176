import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { encriptar, obtenerLocalStorage } from "../../resources/funciones";
import { usuarioCambiarContrasenia } from "../../api/loginApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const CambiarContrasenia = ({ open, handleClose }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      contrasenia_antigua: "",
      contrasenia_nueva: "",
      contrasenia_nueva_repetir: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [mostrar, setMostrar] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tipoAlerta, setTipoAlerta] = useState("");

  // Obtener los valores de las variables CSS cuando el componente se monta
  const computedStyles = getComputedStyle(document.documentElement);

  const usuarioAutenticado = obtenerLocalStorage("usuario");

  // const validarContrasenia = () => {
  //     if(watch("contrasenia_nueva") == watch("contrasenia_nueva_repetir")){
  //         console.log('Contraseñas coinciden')
  //     }else{
  //         console.log('Contraseñas NO coinciden')
  //     }
  // }

  const cambiarContrasenia = async (data) => {
    setIsLoading(true);
    if (data?.contrasenia_nueva == data?.contrasenia_nueva_repetir) {
      const dataPOST = {
        token: usuarioAutenticado?.token,
        pass_antigua: data.contrasenia_antigua,
        pass: data.contrasenia_nueva,
        confirm_pass: data.contrasenia_nueva_repetir,
      };
      // console.log(dataPOST)

      const dataPOSTEncriptado = {
        body: encriptar(dataPOST),
      };
      // console.log(dataPOSTEncriptado)

      const { status, message } = await usuarioCambiarContrasenia(
        dataPOSTEncriptado
      );
      setMostrar(true);
      setMensaje(message);
      if (status == 1) {
        setTipoAlerta("success");
        reset();
      } else {
        setTipoAlerta("warning");
      }
    } 
    else {
      setMostrar(true);
      setTipoAlerta("warning");
      setMensaje("Contraseña nueva no coinciden");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    reset();
    setIsLoading(false);
    setMostrar(false);
    setMensaje("");
    setTipoAlerta("");
  }, [open]);

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form
          id="CreateForm"
          onSubmit={handleSubmit(cambiarContrasenia)}
          className="row p-1 g-2"
        >
          <h3 className="text-center">Cambio de contraseña</h3>
          <div className="col-12 text-center mt-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill={computedStyles?.getPropertyValue("--color-button-primario")}
              className="bi bi-person-gear"
              viewBox="0 0 16 16"
            >
              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
            </svg>
          </div>
          <div className="col-12">
            <label className="text-start">Contraseña antigua</label>

            <input
              {...register("contrasenia_antigua", {
                required: {
                  value: true,
                },
              })}
              type="password"
              className="form-control form-control-sm"
              autoComplete="off"
              placeholder="Contraseña antigua"
              style={{ textTransform: "none" }}
            />
          </div>
          {errors.contrasenia_antigua &&
            errors.contrasenia_antigua.type === "required" && (
              <p role="alert" className="error px-2 mb-0">
                Ingrese contraseña antigua
              </p>
            )}
          <div className="col-12">
            <label className="text-start">Contraseña nueva</label>

            <input
              {...register("contrasenia_nueva", {
                required: {
                  value: true,
                },
              })}
              type="password"
              className="form-control form-control-sm"
              autoComplete="off"
              placeholder="Contraseña nueva"
              style={{ textTransform: "none" }}
            />
          </div>

          {errors.contrasenia_nueva &&
            errors.contrasenia_nueva.type === "required" && (
              <p role="alert" className="error px-2 mb-0">
                Ingrese contraseña nueva
              </p>
            )}

          <div className="col-12">
            <label className="text-start">Repetir contraseña nueva</label>

            <input
              {...register("contrasenia_nueva_repetir", {
                required: {
                  value: true,
                },
              })}
              type="password"
              className="form-control form-control-sm"
              autoComplete="off"
              placeholder="Repetir contraseña nueva"
              style={{ textTransform: "none" }}
            />
          </div>

          {errors.contrasenia_nueva_repetir &&
            errors.contrasenia_nueva_repetir.type === "required" && (
              <p role="alert" className="error px-2 mb-0">
                Ingrese la contraseña
              </p>
            )}
        </form>

        {isLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="spinner-border text-secondary spinner-border-sm mt-2"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        <div className="mt-3 botones-accion text-center">
          <button
            className="btn btn-sm btn-main col-md-4"
            type="submit"
            form="CreateForm"
            onClick={handleSubmit(cambiarContrasenia)}
          >
            Guardar
          </button>

          <button
            className="btn btn-sm btn-secundario col-md-4"
            onClick={handleClose}
          >
            Cancelar
          </button>
        </div>

        {mostrar && (
          <div
            className="mt-4 col-lg-12 text-center alert-error"
            style={{ margin: "0 !important" }}
          >
            <Stack spacing={2}>
              <Alert variant="outlined" severity={tipoAlerta}>
                {mensaje}
              </Alert>
            </Stack>
          </div>
        )}
      </Box>
    </Modal>
  );
};
