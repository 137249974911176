import { obtenerLocalStorage } from "../resources/funciones";
import { api } from "./configApi"
import axios from 'axios'

export const tramiteCrear = async (dataPOST) => {

    try {
        //console.log(dataPOST)
        const token = obtenerLocalStorage("usuario")?.token;

        const headers = {
            'token': token,
        };


        const resp = await api.post("api/tramite/registrarTramite", dataPOST, { headers })

        if (resp?.data?.status === 1) {
            return {
                status: 1,
                message: resp.data.mensaje,
                num_tramite: resp.data?.num_tramite
            }
        }

    } catch (error) {
        // console.log("Error", error)
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en el registro",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const tramiteMedioCancelacionesEditar = async (dataPOST) => {
    /* console.log("fsdfsdfsdfsdfsdf");
    console.log(dataPOST) */
    try {
        const resp = await api.post("api/tramite/actualizarMedioCancelaciones", dataPOST)
        /* console.log(dataPOST); */
        if (resp?.data?.status === 1) {
            return {
                status: 1,
                message: resp.data.mensaje,
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en el registro",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }

}



export const personaBuscar = async (dataPOST) => {
    try {
        ////console.log(dataPOST)
        const res = await api.post("api/persona/buscarPersona", dataPOST)
        ////console.log('res', res)
        //res.status 201
        if (res?.data?.status === 1) {
            return {
                status: res.data.status,
                persona: res.data.persona,
                message: res.data.message,
            }
        }

    } catch (error) {
        // console.log("Error", error)
        if (error.response && error.response.data.status === 0) {
            return {
                status: error.response.data.status,
                persona: null,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            persona: null,
            message: "Error en el servidor",
        };
    }
}

export const duaBuscar = async (dataPOST) => {
    try {
        const res = await api.post("api/tramite/consultarDua", dataPOST)

        if (res?.data?.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                existe: res.data.Existe_Dua,
                tramite: res.data.Tramite_conflicto
            }
        }
    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
                existe: "",
                tramite: ""
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            existe: "",
            tramite: ""
        };
    }
}

export const tramiteListar = async (dataPOST) => {
    try {
        //const res = await api.post("api/tramite/buscarTramite_sede", dataPOST)
        const res = await api.post("api/tramite/buscarTramite_sede", dataPOST)

        ////console.log('res', res)
        //res.status 201
        if (res?.data?.status === 1) {
            return {
                status: res.data.status,
                datos_tramite: res.data.datos_tramite.reverse(),
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status === 0) {
            return {
                status: error.response.data.status,
                datos_tramite: [],
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            datos_tramite: [],
            message: "Error en el servidor",
        };
    }
}

export const tramiteValidar = async (dataPOST) => {
    try {
        const res = await api.post("api/tramite/validar", dataPOST)
        if (res?.data?.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message
            }
        }
    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "No se ha podido validar"
            };
        } else {
            return {
                status: -1,
                message: "Error en el servidor"
            }
        }
    }
}

export const tramiteMostrar = async (dataPOST) => {
    try {
        ////console.log(dataPOST)
        const res = await api.post("api/tramite/buscarTramite_id", dataPOST)
        /* console.log('res', res) */
        //res.status 201        
        if (res?.data?.status === 1) {
            return {
                status: res.data.status,
                message: res.data?.message || "Tramite encontrado",
                datos_tramite: res.data.datos_tramite
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en la busqueda",
                datos_tramite: [],
                datos_compradores: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            datos_tramite: [],
            datos_compradores: []
        };
    }
}

export const tramiteAnular = async (dataPOST) => {
    try { 
        const res = await api.post("api/tramite/anularTramite", dataPOST) 
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error al anular el tramite",
            };
        }
        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const usuarioObtenerCompaniasYSucursales = async (dataPOST) => {
    try {

        const res = await api.post("api/common/UsuarioXSede", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                companias: res.data.compania,
                sucursales: res.data.sucursales,
                sedes: res.data.sedes
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status === 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
                companias: [],
                sucursales: [],
                sedes: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            companias: [],
            sucursales: [],
            sedes: []
        }
    }
}

export const obtenerSucursales = async (dataPOST) => {
    try {
        const res = await api.post("api/common/UsuarioXSucursal", dataPOST)

        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                sucursales: res.data.sucursales
            }
        }
    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en la busqueda",
            };
        }
        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}


export const obtenerCompaniaSucursales = async (dataPOST) => {
    try {
        const respuesta = await api.post("api/common/CompaniaSucursal", dataPOST)


        if (respuesta.data.status === 1) {
            return {
                status: respuesta.data.status,
                message: respuesta.data.message,
                sucursales: respuesta.data.resultado,
                sedes: respuesta.data.sedes
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en la busqueda",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}


export const placasPendientes = async (dataPOST) => {
    try {
        const res = await api.post("api/tramite/placaPendiente", dataPOST)

        if (res.status == 200) {
            return {
                status: 1,
                message: "Satisfactorio",
                lista: res.data
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en la busqueda",
                lista: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            lista: []
        };
    }
}


export const getDataReportApi = async (requestData) => {
    try {
        const res = await api.post("/api/tramite/rptCompania", requestData);
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                reportes: res.data.datos
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en la busqueda",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const getDashboardApi = async (requestData) => {
    try {
        const res = await api.post("/api/tramite/dashboard", requestData);
        if (res.status == 200) {
            return { data: res.data }
        } else {
            return { data: [] }
        }


    } catch (error) {
        console.log("error ", error);
        console.log(error.message);
        if (error.response && error.response) {
            return {
                status: 0,
                message: error.response || "Error en la busqueda",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}



export const usuarioCompania = async (requestData) => {
    try {
        const res = await api.post("/api/common/UsuarioCompania", requestData);
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                reportes: res.data.datos
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status === 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en la busqueda",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}


export const actualizarTramite = async (dataPost, token) => {
    try {
        const res = await api.post("api/tramite/actualizarTramite", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.status === 200) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
        if (res.status === 401) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor"
        }
    }
}

export const consultaReniec = async (dataPost) => {
    try {
        const res = await api.post("api/common/consultaReniec", dataPost)
        if (res.status === 200) {
            return {
                nombre: res.data.nombre,
                apellidop: res.data.apellidop,
                apellidom: res.data.apellidom,
                ubigeo: res.data.ubigeo,
                direccion: res.data.direccion,

            }
        }
        if (res.status === 401) {
            return {
                nombre: res.data.nombre,
                apellidop: res.data.apellidop,
                apellidom: res.data.apellidom
            }
        }
    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor"
        }
    }
}


