import { useEffect, useState } from "react"
export const FileFormats = {
    PDF: "application/pdf",
    XML: "text/xml",
    IMAGE: "image/jpg, image/jpeg, image/png"
}
export const FileInput = ({
    id,
    register,
    refText,
    fileName,
    format,
    formatError,
    min,
    minError,
    max,
    maxError, 
    otherValidation
}) => {
    const emptyMessage = 'Ningún archivo seleccionado.'
    const [nombreArchivo, setNombreArchivo] = useState(emptyMessage)
    useEffect(() => {
        const restoreFile = () => {
            if (fileName) {
                setNombreArchivo(fileName)
            }
        }
        restoreFile()
    }, [])
    const limitNameLength = (nombreArchivo) => {
        const nombre = nombreArchivo
        if (nombre.length > 30) {
            const tailChars = nombre.slice(-7)
            const visible = nombre.slice(0, 20)
            return visible + "..." + tailChars
        } else {
            return nombre
        }
    }
    const onChangeHandler = (e) => {
        if (e.target.value === "") {
            if (!fileName) {
                setNombreArchivo(emptyMessage)
            } else {
                setNombreArchivo(fileName)
            }
        } else {
            const value = e.target.value.replace('C:\\fakepath\\', "")
            setNombreArchivo(value)
        }
    }
    const requestValidation = (value) => {
        if (!value[0] && otherValidation === "editar") {            
            return true
        } else if (fileName || value[0]) {
            return true
        } else {
            return false || "El archivo es requerido"
        }
    }
    const typeValidation = (value) => {
        if (value[0]) {
            if (format) {
                if (value[0]?.type === format || format.includes(value[0]?.type)) {
                    return true
                } else {
                    return false || formatError
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }
    const minValidation = (value) => {
        if (value[0]) {
            if (!min) {
                return true
            } else {
                return (value[0]?.size > min) || minError
            }
        } else {
            return true
        }
    }
    const maxValidation = (value) => {
        if (value[0]) {
            if (!max) {
                return true
            } else {
                return (value[0]?.size < max) || maxError
            }
        } else {
            return true
        }
    }
    return (
        <>
            <label className="file-group" htmlFor={id}>
                <div className="file file-btn">Elegir archivo</div>
                <div className="file file-text">{limitNameLength(nombreArchivo)}</div>
            </label>
            <input
                {...register(refText, {
                    validate: {
                        required: value => requestValidation(value),
                        type: value => typeValidation(value),
                        maximum: value => maxValidation(value),
                        minimum: value => minValidation(value),                        
                    },
                    onChange: onChangeHandler
                })}
                id={id}
                className="d-none"
                type="file"
                accept={format}
            />
        </>
    )
}