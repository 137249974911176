import { api } from "./configApi"

export const vehiculoActualizar = async(dataPOST) => {
    try{
        const res = await api.post("api/vehicu/actualizarVehiculo", dataPOST)
        if(res?.data?.status === 1){
            return {
                status: 1,

            }
        }
    }
    catch (error){
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error al actualizar",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}