import { useEffect, useState } from "react";
import { getArchivo } from "../../api/gestorApi";
import { LoaderArchivos } from "./LoaderArchivos";
import XmlViewer from 'react-xml-viewer';

export const Archivo = ({
  listaArchivosVisualizar,
  idTramite,
  nombreArchivoVisualizar,
  numeroArchivoInicial,
}) => {
  const tipoArchivoInicial = nombreArchivoVisualizar?.split(".").pop();

  const [tipoArchivo, setTipoArchivo] = useState(tipoArchivoInicial);

  const [numeroPdf, setNumeroPdf] = useState(numeroArchivoInicial);

  const [file, setFile] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [disabledButtonIzq, setDisabledButtonIzq] = useState(
    numeroArchivoInicial == 1 ? true : false
  );
  const [disabledButtonDer, setDisabledButtonDer] = useState(
    numeroArchivoInicial == listaArchivosVisualizar?.length ? true : false
  );

  // Obtener los valores de las variables CSS cuando el componente se monta
  const computedStyles = getComputedStyle(document.documentElement);

  const colorIconosPrimario = computedStyles.getPropertyValue(
    "--color-button-primario"
  );

  const handlePreviousPage = () => {
    if (numeroPdf > 1) {
      setNumeroPdf(numeroPdf - 1);
      setDisabledButtonDer(false);
      setDisabledButtonIzq(numeroPdf - 1 == 1 ? true : false);
    } else {
      setDisabledButtonIzq(true);
    }
  };

  const handleNextPage = () => {
    if (numeroPdf < listaArchivosVisualizar?.length) {
      // console.log(numeroPdf + 1 == listaArchivosVisualizar?.length);
      setNumeroPdf(numeroPdf + 1);
      setDisabledButtonDer(
        numeroPdf + 1 == listaArchivosVisualizar?.length ? true : false
      );
      setDisabledButtonIzq(false);
    } else {
      setDisabledButtonDer(true);
    }
  };

  const obtenerBase64 = async (nombreArchivo) => {
    setIsLoading(true);
    const dataPOST = {
      TramitId: idTramite,
      nombreArchivo: nombreArchivo,
    };

    const { archivo } = await getArchivo(dataPOST);

    const tipoArchiv = nombreArchivo?.split(".").pop();

    setIsLoading(false);
    setFile(archivo);
    setTipoArchivo(tipoArchiv);
  };

  useEffect(() => {
    const nombreArchivo = listaArchivosVisualizar[numeroPdf - 1]?.Nom_archv;
    obtenerBase64(nombreArchivo);
  }, [numeroPdf]);

  useEffect(() => {
    obtenerBase64(nombreArchivoVisualizar);
  }, []);

  /* useEffect(() => {
        // Manipula el DOM después de que el componente se ha montado
        const iframe = document.getElementById("miIframe");
        console.log("iframe", iframe);
        if (iframe != null) {
          iframe.querySelector("#title").value = "archivo";
        }
  }, [nombreArchivoVisualizar])*/

  const customTheme = {
    "attributeKeyColor": "#994500",
    "attributeValueColor": "#1A1AA6",
    "tagColor": "#881280",
    "cdataColor": "#000000"
  };


  return (
    <div
      className="d-flex justify-content-center align-items-center h-100"
      style={{ background: "#F8F9F9" }}
    >
      <button
        className="btn btn-sm"
        onClick={handlePreviousPage}
        disabled={disabledButtonIzq ? true : false}
        style={{ borderColor: "transparent" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          fill={colorIconosPrimario}
          className="bi bi-arrow-left-square-fill"
          viewBox="0 0 16 16"
        >
          <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z" />
        </svg>
      </button>
      <div className="pt-2 pb-2" style={{ width: "1000px", height: "100%" }}>
        {!isLoading && file ? (
          <>
            {tipoArchivo === "pdf" ? (
              <div style={{ overflow: "hidden", width: "100%", height: "100%", scrollBehavior: "smooth" }}>
                <iframe
                  src={`data:application/pdf;base64,${file}#zoom=120`}
                  className="iframePersonalizado"
                  style={{ width: "100%", height: "100%" }}

                >

                </iframe>
              </div>
            ) : tipoArchivo === "xml" ? (
              <div style={{ overflow: "auto", width: "100%", height: "100%",background:"#e3e3e3", fontSize:"0.8rem" }} className="p-4" >
                <XmlViewer xml={atob(file)} style={{ width: "100%", height: "100%" }} theme={customTheme} />
              </div>
            ) : (
              <img
                src={`data:image/${tipoArchivo};base64,${file}`}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                alt="imagen"
              />
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <LoaderArchivos />
          </div>
        )}
      </div>

      <button
        className="btn btn-sm"
        onClick={handleNextPage}
        disabled={disabledButtonDer ? true : false}
        style={{ borderColor: "transparent" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          fill={colorIconosPrimario}
          className="bi bi-arrow-right-square-fill"
          viewBox="0 0 16 16"
        >
          <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
        </svg>
      </button>
    </div>
  );
};