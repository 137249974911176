// Para posicionar siempre al inicio de pagina en la navegacion

import { useEffect } from "react"
import { useLocation } from "react-router-dom"


export const ScrollToTop = () => {
  const {pathname} = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname])

}
