import { api } from "./configApi"

export const personaCrear = async(dataPOST) => {
    try {
        //console.log(dataPOST)
        const res = await api.post("api/persona/crearPersona", dataPOST)
        // console.log('res', res)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: 1,
                message: res.data.mensaje 
            }
        }

    } catch (error) {
        // console.log("Error", error)
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error en el registro",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const personaEditar = async(dataPOST) => {
    try {
        //console.log(dataPOST)
        const res = await api.post("api/persona/editarPersona", dataPOST)
        // console.log('res', res)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: 1,
                message: res.data.mensaje 
            }
        }

    } catch (error) {
        // console.log("Error", error)
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error en el registro",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const repLegalesListar = async(dataPOST) => {
    try {
        // console.log(dataPOST)
        const res = await api.post("api/persona/listarRepresLegal", dataPOST)
        // console.log('res', res)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: 1,
                listaRepresLegal: res.data.lista_repres_legal || []
            }
        }

    } catch (error) {
        // console.log("Error", error)
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error en el registro",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const personasListar = async(dataPOST) => {
    try {
        const res = await api.post("api/persona/listaPersona", dataPOST)
        // console.log('res', res)
        if (res.data.status === 1) {
            return {
                status: 1,
                message: res.data.message,
                datos_personas: res.data.datos_personas || []
            }
        }

    } catch (error) {
        // console.log("Error", error)
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error en el registro",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const agregarDireccion = async(dataPOST) => {
    try {
        const res = await api.post("api/persona/crearDireccion", dataPOST);

        if(res.data.status == 1) { 
            return {
                status: 1,
                message: res.data.message
            }
        }
    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error en el registro"
            };
        }

        return {
            status: -1,
            message: "Error en el servidor"
        }
    }
}

export const personaAnular = async(dataPOST) => {
    try {
        //console.log(dataPOST)
        const res = await api.post("api/persona/actualizarEstadoPersona", dataPOST)
        // console.log('res', res)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: 1,
                message: res.data.mensaje 
            }
        }

    } catch (error) {
        // console.log("Error", error)
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error en el registro",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const direccionEditar = async (dataPOST) => {
    try {
        const res = await api.post("api/persona/editarDireccion", dataPOST)

        if (res.data.status === 1) {
            return {
                status: 1,
                message: res.data.message
            }
        }
    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0,
                message: error.response.data?.message || "Error al editar direccion"
            }
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}