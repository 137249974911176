import axios from 'axios'

const URLdomain = window.location.host
const protocol = window.location.protocol

const config = protocol + "//" + URLdomain + '/cfg/config.json'
const serverUrl = await axios.get(config)

export const server = {
    "serverUrl": serverUrl.data.urlServer, //  http://192.168.195.101:4000/ | serverUrl.data.urlServer | https://srvdeveloper.notariapaino.com/
    "serverSeguridad": "http://192.168.195.54:4001/", //  http://92.205.24.231:4001/
    "servertestPV": "http://192.168.195.7:4000/"
}
