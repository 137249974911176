import React, { useState, useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";
import { api } from "./../../api/configApi"
//Api
import { getDataReportApi, obtenerCompaniaSucursales, obtenerSucursales } from '../../api/tramiteApi'

//Utils
import { obtenerLocalStorage } from '../../resources/funciones'

import Select from 'react-select';


// const fecha = new Date()
// fecha.setUTCHours(fecha.getUTCHours() - 5)
// const hoy = fecha.toISOString().slice(0, 10)
// fecha.setUTCDate(fecha.getUTCDate() - 15)
// const fechaAnterior = fecha.toISOString().slice(0, 10)

const fecha = new Date();
fecha.setUTCHours(fecha.getUTCHours() - 5);

const hoy = fecha.toISOString().slice(0, 10);

fecha.setUTCMonth(fecha.getUTCMonth() - 1); // Resta un mes al mes actual
const fechaAnterior = fecha.toISOString().slice(0, 10);





export function Inmatriculaciones() {


    const options = [
        { value: 'EBARDALES@EUROSHOP.COM.PE', label: 'EBARDALES@EUROSHOP.COM.PE' },
        { value: 'LCATACORA@EUROSHOP.COM.PE', label: 'LCATACORA@EUROSHOP.COM.PE' },
        { value: 'SDIAZ@EUROSHOP.COM.PE', label: 'SDIAZ@EUROSHOP.COM.PE' },
        { value: 'JRODRIGUEZ@EUROSHOP.COM.PE', label: 'RODRIGUEZ@EUROSHOP.COM.PE' },
        { value: 'DCHAVEZ@PERUWAGEN.COM.PE', label: 'DCHAVEZ@PERUWAGEN.COM.PE' },
    ]

    const tipoBusquedaFecha = [
        { value: "fregistro", label: "Registro" },
        { value: "fpresentado", label: "Presentado" },
        { value: "finscrito", label: "Inscrito" },
        { value: "fplaca", label: "Placa en tramite" },
        { value: "fconcluido", label: "Concluido" },
    ]

    let listaEstados = [
        { value: '00', label: 'Anulado' },
        { value: '01', label: 'Creado' },
        { value: '02', label: 'Registrado' },
        { value: '03', label: 'Procesado' },
        { value: '04', label: 'Reingresado' },
        { value: '05', label: 'Validado' },
        { value: '06', label: 'Enviado' },
        { value: '07', label: 'P.F. Notario' },
        { value: '08', label: 'Firmo Notario' },
        { value: '09', label: 'Presentado' },
        { value: '10', label: 'Inscrito' },
        { value: '11', label: 'Placa en tramite' },
        { value: '12', label: 'Concluido' },
    ]

    const token = obtenerLocalStorage("usuario")?.token;
    const estilosCompania = obtenerLocalStorage("usuario")?.estilo;
    const { control, register, watch, setValue, handleSubmit } = useForm({})

    const [listaSucursales, setListaSucursales] = useState([])
    const [listaSedes, setListaSedes] = useState([]);
    const [listaReportes, setListaReportes] = useState([]);
    const [filtros, setfiltros] = useState(false);
    const [filaActiva, setFilaActiva] = useState(null);
    const [promedios, setPromedios] = useState(null)

    const [orden, setOrden] = useState({
        columna: 'fecha', // Columna inicial para ordenar
        direccion: 'asc', // Dirección inicial del orden ('asc' o 'desc')
    });


    const manejarClickFila = (indice) => {
        // Si la fila clicada ya está activa, la desactivamos; si no, la activamos
        setFilaActiva(filaActiva === indice ? null : indice);
    };



    const alturaMaxima = "70vh"



    const colores = {
        datos: "#347eaa",
        datosTitle: "linear-gradient( 45deg, rgb(68 146 192), rgb(39 111 154))",
        titulo: "#1d7faf",
        placa: "#7480a3",
        estadistica: "#ff8d3a",
        estadisticaCli: "#ffb580",
        firmas: "rgb(63 100 138)",
        autohub: "#814357",
        resumen: "rgb(175 43 50)",
        color0:"#4492c0",
        color01:"",
        color1:"#276f9a",
        color12:"",
        color2:"#565877",
        color23:"",
        color3:"#a83039",
        color34:"",
        color4:"#c51616",
 


    }

    useEffect(() => {
        // obtenerListaSedes(watch('sucursal'))
    }, [watch('sucursal')]);



    useEffect(() => {
        const func = async () => {
            const { sucursales } = await obtenerCompaniasYSucursales();
        }



        func()
    }, [])




    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            "Estado": "T",
            "Codigo": "EUROMOTORS",
        }

        const dataPOST2 = {
            token
        }

        let { sucursales, sedes } = await obtenerCompaniaSucursales(dataPOST);
        setListaSucursales(sucursales);
        setListaSedes(sedes);

        return {
            sucursales,
        };
    }




    const obtenerListaSucursales = async () => {
        const dataPOST = {
            token: token,
        }
        const { sucursales } = await obtenerSucursales(dataPOST);
        setListaSucursales(sucursales)
        setValue("sucursal", sucursales[0].id)
    }


    const getDataReport = async (data) => {
        const dataPOST = {
            token: token,
        }

        const { reportes } = await getDataReportApi(data);
        setListaReportes(reportes.listaTramites);
        let promedios = {
            promedioUsuario: reportes.promedioUsuario,
            promedioInscripcion: reportes.promedioInscripcion,
            promedioPlacas: reportes.promedioPlacas,
            promedioAutoHub: reportes.promedioAutoHub,
            promedioTotal: reportes.promedioTotal,
        }
        setPromedios(promedios)
    }


    const obtenerListaSedes = async (idSucursal) => {
        let sedes = []
        const dataPOST = {
            token: token,
        }
        const { sucursales } = await obtenerSucursales(dataPOST)
        if (idSucursal === "") {
            sucursales.forEach(item => {
                sedes = sedes.concat(item.sedes)
            });
        } else {
            const sucursal = sucursales.find((item) => item.id === idSucursal);
            sedes = sucursal.sedes;
        }
        setListaSedes(sedes);
        setValue("sede", sedes.length > 1 ? "" : sedes[0]?.id)
    }

    const reporteDescargarXls = async (data) => {
        try {
            console.log("entro a reporteDescargarXls ");
            console.log(data);
            const dataPOST = { data };
            const respuesta = await api.post("api/tramite/reporteInmatriculacionXLS", dataPOST)
            // console.log(" json Data ", respuesta.data);

            const base64String = respuesta.data.base64;
            const filename = respuesta.data.name;

            // Crear un enlace para la descarga
            const link = document.createElement('a');
            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
            link.download = filename;

            // Simular el clic en el enlace para iniciar la descarga
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.log(error);
            console.log(error.message);

        }



    }

    const ordenarDatos = (columna, direccion) => {
        const datosOrdenados = [...listaReportes].sort((a, b) => {
            let valorA = a[columna];
            let valorB = b[columna];

            // Comprobando el tipo de valor
            if (typeof valorA === 'number' && typeof valorB === 'number') {
                return direccion === 'asc' ? valorA - valorB : valorB - valorA;
            } else if (typeof valorA === 'string' && typeof valorB === 'string') {
                return direccion === 'asc' ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
            } else if (valorA instanceof Date && valorB instanceof Date) {
                return direccion === 'asc' ? valorA - valorB : valorB - valorA;
            }

            // Comparación por defecto (asumiendo que son strings)
            return direccion === 'asc' ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
        });

        setListaReportes(datosOrdenados);
        setOrden({ columna, direccion });
    };


    const handleOrdenar = (columna) => {
        const nuevaDireccion =
            orden.columna === columna && orden.direccion === 'asc' ? 'desc' : 'asc';
        ordenarDatos(columna, nuevaDireccion);
    };


    const updateFiltros = () => {
        setfiltros(!filtros)
    };

    return (
        <div className="">

            <div className="container-fluid p-3">
                <h4 className="text-center mt-3 ">Reporte de  Inmatriculaciones</h4>

                <div className="row">
                    <div className="col-11">
                        <div className="container-fluid">
                            <div className="row">





                                <div className='col-4 col-md-4 col-lg-2'>
                                    <label className="form-label m-0 bold" htmlFor="form-sucursal">Sucursal</label>
                                    <select className='form-select form-select-sm'
                                        {...register('sucursal')}
                                    >
                                        {listaSucursales.length > 1 && <option value={""}>TODOS</option>}
                                        {
                                            listaSucursales?.length > 0 &&
                                            listaSucursales.map((i, index) => (
                                                <option key={index} value={i?.CompCod}>{i?.CompNom}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className='col-4 col-md-4 col-lg-2'>
                                    <label className="form-label m-0 bold">Sede</label>
                                    <select
                                        className="form-select form-select-sm"
                                        {...register("sede")}
                                        value={watch("sede")}
                                    >
                                        {listaSedes.length > 1 && <option value={""}>TODOS</option>}
                                        {
                                            listaSedes?.length > 0 &&
                                            listaSedes.map((e, index) => (
                                                <option key={index} value={e?.SedCod}>
                                                    {e?.SedDesc}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='col-4 col-md-4 col-lg-2'>
                                    <label className="form-label m-0 bold">Inmatriculacion</label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        {...register("inmatriculacion")}
                                    />
                                </div>
                                <div className='col-4 col-md-4 col-lg-2' style={{ zIndex: 3 }}>
                                    <label className="form-label m-0 bold ">Fecha busqueda</label>
                                    <Controller name="tipoFecha" control={control} render={({ field }) => (
                                        <Select {...field} options={tipoBusquedaFecha} placeholder="Seleccione" className='select-react' defaultValue={tipoBusquedaFecha[4]} />
                                    )} />
                                </div>


                                <div className='col-4 col-md-4 col-lg-2'>
                                    <label className="form-label m-0 bold">Desde</label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="date"
                                        defaultValue={fechaAnterior}
                                        {...register("fchDesde")}
                                    />
                                </div>
                                <div className='col-4 col-md-4 col-lg-2'>
                                    <label className="form-label m-0 bold">Hasta</label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="date"
                                        defaultValue={hoy}
                                        {...register("fchHasta")}
                                    />
                                </div>


                                <div className="col-12 p-0 m-0 ">
                                    <div className="col-12 ps-3">
                                        <div className='d-inline' onClick={updateFiltros} >
                                            <label className="form-label bold mouse"  >Filtros avanzados</label>
                                            &nbsp;
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up mouse" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className={filtros ? "row p-0 m-0 border pt-2 pb-3 " : "row p-0 m-0 border pt-2 pb-3 d-none"}>

                                        <div className="col-md-1">
                                            <label className="form-label m-0 bold">kardex</label>
                                            <input className="form-control form-control-sm" type="input" {...register("kardex")} />
                                        </div>



                                        <div className="col-md-2">
                                            <label className="form-label m-0 bold">Chasis</label>
                                            <input className="form-control form-control-sm" type="input" {...register("chasis")} />
                                        </div>

                                       {/* 
                                       
                                        <div className="col-md-4" style={{ zIndex: 2 }}>
                                            <label className="form-label m-0 bold">Asesor</label>
                                            <Controller
                                                name="asesor"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={options}
                                                        isMulti
                                                        placeholder="Selecciona una o más opciones"
                                                    />
                                                )}
                                            />
                                        </div>
                                        
                                        */ }

                                        <div className="col-md-4" style={{ zIndex: 2 }}>
                                            <label className="form-label m-0 bold">Estado</label>
                                            <Controller
                                                name="estado" control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field} options={listaEstados} isMulti placeholder="Selecciona una o más opciones"
                                                    />
                                                )}
                                            />
                                        </div>



                                    </div>


                                </div>
 

                            </div>

                        </div>

                    </div>
                    <div className="col-1">
                        <div className=" " style={{ marginTop: "3px" }}>
                            <button title='Buscar Inmatriculaciones' className="col-12 btn btn-sm btn-primary" style={{ "height": "52px" }} onClick={handleSubmit(getDataReport)} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>

                            </button>
                        </div>

                    </div>

                </div>



            </div>



            <div className="px-3 mt-3">

                <div className="col-12 ">



                    {promedios &&


                        <div className="container-fluid bg-light p-0 m-0">



                            <div className='row  m-0 border col-12 pt-3' >


                                <div className='col text-center'>
                                    <label className="form-label m-0 bold">Firma Cliente</label>
                                    <p> {promedios.promedioUsuario} dias</p>
                                </div>
                                <div className='col text-center'>
                                    <label className="form-label m-0 bold"> Inscripciones</label>
                                    <p> {promedios.promedioInscripcion} dias</p>
                                </div>
                                <div className='col text-center'>
                                    <label className="form-label m-0 bold">Entrega Placas</label>
                                    <p> {promedios.promedioPlacas} dias</p>
                                </div>
                                <div className='col text-center'>
                                    <label className="form-label m-0 bold">Total Autohub</label>
                                    <p>{promedios.promedioAutoHub} dias </p>
                                </div>



                                <div className='col-1 text-end'>
                                    < button className='btn btn-success' title='Descargar Desporte' onClick={(e) => reporteDescargarXls(listaReportes)} >&nbsp;
                                   
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                                        </svg>&nbsp;
                                        
                                    </button>
                                </div>




                            </div>

                        </div>


                    }


                </div>

                <div className="p-0 m-0" style={{ maxHeight: alturaMaxima, overflow: 'auto' }}>

                    <table className="table table-bordered table-hover table-reporte" style={{ width: "max-content" }}>
                        {/* <thead className='' style={{ position: 'sticky', top: 0, zIndex: 1, background: '#ffffff' }}> */}
                        <thead className=''  >
                            <tr>
                                <th colSpan={12} className='text-center bold-1' style={{ background: `linear-gradient(45deg, ${colores.color0}, ${colores.color1})` }} >DATOS DE INMATRICULACION</th>
                                <th colSpan={16} className='text-center bold-1' style={{ background: `linear-gradient(45deg, ${colores.color1}, ${colores.color4})` }} >DATOS ESTADISTICOS</th>
                            </tr>
                            <tr>
                                <th colSpan={12} className='text-center' style={{ background:`linear-gradient(45deg, ${colores.color0}, ${colores.color1})` }}>   <p className='fs-6 bold text-start'>Busqueda: {listaReportes.length} inmatriculaciones</p> </th>
                                <th colSpan={5} className='text-center' style={{ background: `linear-gradient(45deg, ${colores.color1}, ${colores.color2})` }}>Duracion desde el inicio hasta la firma del cliente </th>
                                <th colSpan={8} className='text-center' style={{ background: `linear-gradient(45deg, ${colores.color2}, ${colores.color3})` }}>Duracion desde firmas de cliente hasta entrega de placas (AutoHub)</th>
                                <th colSpan={3} className='text-center' style={{ background: `linear-gradient(45deg, ${colores.color3}, ${colores.color4})` }}>Resumen</th>
                            </tr>


                            <tr style={{ lineHeight: "12px" }}>
                                {/*  <th className='text-center mouse' style={{ position: "sticky", left: "0", background: colores.datos }} onClick={() => handleOrdenar('inmatriculacion')} >*/}
                                <th className='text-center mouse' style={{ background: colores.datos }} onClick={() => handleOrdenar('inmatriculacion')} >
                                    #Inmatriculacion
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "10rem", background: colores.datos }} onClick={() => handleOrdenar('chasis')}> Chasis
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ background: colores.datos }} onClick={() => handleOrdenar('kardex')}>Kardex
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "7rem", background: colores.datos }} onClick={() => handleOrdenar('titulo')}>Titulo
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "5rem", background: colores.datos }} onClick={() => handleOrdenar('placa')}>Placa
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ minWidth: "10rem", background: colores.datos }} onClick={() => handleOrdenar('sede')} >Sede
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "15rem", background: colores.datos }} onClick={() => handleOrdenar('asesor')}>Asesor
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "15rem", background: colores.datos }} onClick={() => handleOrdenar('representante')}>Representante
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "20rem", background: colores.datos }} onClick={() => handleOrdenar('comprador')}>Cliente
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "7rem", background: colores.datos }} >Estado
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>



                                <th className='text-center mouse' style={{ width: "10rem", background: colores.datos }}>Recibido por
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "10rem", background: colores.datos }}>Observaciones
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                {/* ------------------------------------------------------------------ ESTADISTICAS  -------------------------------------------------------------------*/}

                                <th className='text-center mouse' style={{width: "7rem", background: colores.firmas }} onClick={() => handleOrdenar('fecha')}>Inicio
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "6rem", background: colores.firmas }} onClick={() => handleOrdenar('dias0')}>Duracion
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ background: colores.firmas }} onClick={() => handleOrdenar('fechaenviado')}>Enviado Iofe
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "6rem", background: colores.firmas }} onClick={() => handleOrdenar('dias1')}>Duracion
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ background: colores.firmas }} onClick={() => handleOrdenar('fechafirma')}>Firma cliente
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "6rem", background: colores.autohub }} onClick={() => handleOrdenar('dias2')}>Duracion
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "8rem", background: colores.autohub }} onClick={() => handleOrdenar('titulofechapresentado')}>Presentado
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "7rem", background: colores.autohub }} onClick={() => handleOrdenar('dias3')}>Duracion
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "8rem", background: colores.autohub }} onClick={() => handleOrdenar('titulofechainscrito')}>Inscrito
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "7rem", background: colores.autohub }} onClick={() => handleOrdenar('dias4')}>Duracion
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "7rem", background: colores.autohub }} onClick={() => handleOrdenar('placatramite')}>Tramitado
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "7rem", background: colores.autohub }} onClick={() => handleOrdenar('dias5')}>Duracion
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "7rem", background: colores.autohub }} onClick={() => handleOrdenar('placaentregado')}>Entregado
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>
                                <th className='text-center mouse' style={{ width: "8rem", background: colores.resumen }} onClick={() => handleOrdenar('totalUsuario')}>Usuario
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "7rem", background: colores.resumen }} onClick={() => handleOrdenar('totalAutohub')}>Autohub
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                                <th className='text-center mouse' style={{ width: "7rem", background: colores.resumen }} onClick={() => handleOrdenar('totalTiempo')}>Total
                                    &nbsp;
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                listaReportes.map((item, index) => (
                                    <tr key={index} className={filaActiva === index ? 'mouse activeRow' : 'mouse'} onDoubleClick={() => manejarClickFila(index)}>
                                        {/* <td   style={{ position: "sticky", left: "0",background:"white", color:"black"}} className={filaActiva === index ? 'text-center mouse activeRow' : 'text-center mouse'} > <strong>{item.inmatriculacion}</strong> </td>*/}
                                        <td className={filaActiva === index ? 'text-center mouse activeRow' : 'text-center mouse'} > <strong>{item.inmatriculacion}</strong> </td>
                                        <td className='text-center'>{item.chasis}</td>
                                        <td className='text-center'>{item.kardex}</td>
                                        <td className='text-center'>{item.tituloAnio} - {item.titulo}</td>
                                        <td className='text-center'>{item.placa}</td>
                                        <td className='text-center' >{item.sede}</td>

                                        <td className='text-center' >{item.asesor}</td>
                                        <td className='text-center' >{item.representante}</td>
                                        <td className='text-center' >{item.comprador}</td>
                                        <td className='text-center' >{item.estado}</td>

                                        <td className='text-center'>{item.placarecepcionado}</td>
                                        <td className='text-center'>{item.observaciones}</td>
                                        <td className='text-center' >{item.fecha}</td>
                                        <td className='text-center'>{item.dias0} dias</td>
                                        <td className='text-center' >{item.fechaenviado}</td>
                                        <td className='text-center'>{item.dias1} dias</td>
                                        <td className='text-center' >{item.fechafirma}</td>
                                        <td className='text-center'>{item.dias2} dias</td>
                                        <td className='text-center'>{item.titulofechapresentado}</td>

                                        <td className='text-center'>{item.dias3} dias</td>
                                        <td className='text-center'>{item.titulofechainscrito}</td>
                                        <td className='text-center'>{item.dias4} dias</td>
                                        <td className='text-center'>{item.placatramite}</td>
                                        <td className='text-center'>{item.dias5} dias</td>
                                        <td className='text-center'>{item.placaentregado}</td>
                                        <td className='text-center'>{item.totalUsuario} dias</td>
                                        <td className='text-center'>{item.totalAutohub} dias</td>
                                        <td className='text-center'>{item.totalTiempo} dias</td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                </div>

                <div className="col-12 p-5">
                    &nbsp;

                </div>
            </div>


        </div >
    )
}
