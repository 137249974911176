import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ruta_historial, ruta_tramite_agregar } from "../../constants/constants-rutas-codigo";

export const ConfirmacionRegistro = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const num_tramite = location.state.num_tramite;

  //console.log(location)
  //console.log(num_tramite)

  const irHistorial = () => {
    navigate(ruta_historial);
  };

  const irRegistro = () => {
    navigate(ruta_tramite_agregar);
  };

  return (
    <>
      <div className="container text-center">
        <h2 className="pt-4">El trámite se ha regitrado exitosamente.</h2>
        <h1>N° {num_tramite}</h1>
      </div>
      <div className="container my-5 text-center">
        <button className="btn btn-main mt-3 mx-4" onClick={irRegistro}>
          Nuevo Registro
        </button>
        <button className="btn btn-main mt-3 mx-4" onClick={irHistorial}>
          Ir a bandeja
        </button>
      </div>
    </>
  );
};
