import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';

export const ChartsBarRace = ({ datosCharts }) => {
    const [idCharts, setidCharts] = useState(0)

    const generarNumeroAleatorio = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    if (idCharts == 0) {
        const idAleatorio = generarNumeroAleatorio(10000, 99999);
        setidCharts(idAleatorio)
    }


 



    useEffect(() => { 
        Highcharts.chart(`${idCharts}-chart-container`, {
            chart: {
                type: 'bar'
            },
            title: {
                text: null
            },
            tooltip: {
                valueSuffix: ' #'
            },
            plotOptions: {
                bar: {
                    borderRadius: '30%',
                    dataLabels: {
                        enabled: true
                    },
                    groupPadding: 0.1
                }
            },
            
            credits: {
                enabled: false
            },

            series: [
                {
                    name: 'Inmatriculaciones',
                    data: datosCharts.RptInmatriculacionesxSede.InmTotalConcat,
                     color: '#544fc5',
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}', // Formato del texto de los data labels                    
                    }
                } 
            ],
           
            xAxis: {
                categories: datosCharts.RptInmatriculacionesxSede.InmSedesConcat,
                title: {
                    text: null
                },
                gridLineWidth: 1,
                lineWidth: 0
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Cantidad',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                },
                gridLineWidth: 0
            },




        });
    }, []);



    return <div id={`${idCharts}-chart-container`} style={{"height":"600px"}} ></div>;
};