import { useEffect, useState } from "react"
import Swal from 'sweetalert2';
export const FileFormats = {
    PDF: "application/pdf",
    XML: "text/xml",
    IMAGE: "image/jpg, image/jpeg, image/png"
}
export const DNIInput = ({
    id,
    register,
    refText,
    fileName,
    format,
    formatError,
    otherValidation,
    unionDeHecho,
    ConyApellidoM,
    accion
}) => {
    const emptyMessage = 'Ningún archivo seleccionado.';
    const [nombreArchivo, setNombreArchivo] = useState(emptyMessage);
    useEffect(() => {
        const restoreFile = () => {
            if (accion === "agregar") {
                setNombreArchivo(emptyMessage)
            } else if (unionDeHecho && ConyApellidoM === "") {
                setNombreArchivo(emptyMessage)
            } else if (unionDeHecho && ConyApellidoM !== "") {
                setNombreArchivo(fileName)
            } else if (fileName) {
                setNombreArchivo(fileName)
            }
        }
        restoreFile()
    }, [])
    const limitNameLength = (nombreArchivo) => {
        const nombre = nombreArchivo
        if (nombre?.length > 30) {
            const tailChars = nombre.slice(-7)
            const visible = nombre.slice(0, 20)
            return visible + "..." + tailChars
        } else {
            return nombre
        }
    }
    const onChangeHandler = async (e) => {
        let nameInput = e.target.name.split('.').pop();



        let detalles1 = "";
        let detalles2 = "";
        const file = e.target.files[0];
        let base64String = ""
        if (file) {
            try {
                base64String = await getBase64FromInputFile(file);
            } catch (error) {
                console.error('Error al convertir archivo a Base64:', error);
            }
        }
        if (e.target.value === "") {
            if (!fileName) {
                setNombreArchivo(emptyMessage)
            } else {
                setNombreArchivo(fileName)
            }
        } else {
            const value = e.target.value.replace('C:\\fakepath\\', "")
            setNombreArchivo(value)
            if (nameInput.includes("ImagenFrontal")) {
                nameInput = "Imagen frontal seleccionada:";
                detalles1 = `
                <div style="border: 2px solid #9b9b9b; border-radius: 8px; padding: 15px; margin: 10px 0; font-family: Arial, sans-serif; color: #333;" >
                    <h3 style="color: #2c3e50; margin- bottom: 15px; font-size: 18px; padding-bottom: 10px;">Verifique lo siguiente:</h3>
                    <ul style="list-style-type: none; padding-left: 0; margin: 0; text-align: left">
                        <li style="margin-bottom: 10px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
                </svg>
<span style="font-size: 0.8rem"> Imagen nítida.</span></li>
                        <li style="margin-bottom: 10px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
</svg>
<span style="font-size: 0.8rem"> Verificar fecha de caducidad (validez).</span></li>
                        <li style="margin-bottom: 10px;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
</svg>
<span style="font-size: 0.8rem"> Preguntar al cliente si el estado civil del documento es el actual.</span></li>
                        <li style="margin-bottom: 10px;">                        
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
</svg>
<span style="font-size: 0.8rem"> Si el cliente está casado y en el documento no está el estado civil actualizado: <br/>
  &nbsp;&nbsp;&nbsp; &nbsp;* Se debe adjuntar el acta de matrimonio en los documentos de inmatriculación.
</span></li>                        
                        
                    </ul>
                </div>
                `;
            } else {
                nameInput = "Imagen dorsal seleccionada:";
                detalles2 = `               
                    <p class="text-center" style="color: #2c3e50; font-size: 1.1rem; margin-bottom: 15px; padding-bottom: 10px;">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
                </svg>
 Verifique que la Imagen sea nítida.</p>                               
                `;
            }
            const html = `
                <div class="d-flex align-items-center h-100">
                <div>
<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#FFD700" class="bi bi-exclamation-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
</svg>
                    <h3 class="py-2" style="font-weight: 400;">${nameInput}</h3>
                    <div class="container p-0 m-0" >
                        <div class="row p-0 m-0">
                            ${detalles1 !== "" ? `
                                <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                    <img class="img-fluid" src="${base64String}" alt="Imagen seleccionada" style="margin-bottom: 20px; max-height: 900px" />
                                </div>
                                <div class="col-12 col-md-6 p-0 ">                                                                 
                                        ${detalles1}                                 
                                </div>
                            ` : `
                                <div class="col-12 col-md-12 ">
                                    ${detalles2}
                                    <img class="img-fluid" src="${base64String}" alt="Imagen seleccionada" style="max-width: 90%; max-height: 500px; width: auto; height: auto;" />
                                </div>
                            `}
                        </div>
                    </div>
                    <div class="py-2">
                        <button id="closeAlertBtn" class="btn btn-main">Cerrar</button>
                    </div>
                </div>
            <div>
            `;
            Swal.fire({
                html: html,
                width: 'auto',
                maxWidth: 1100,
                height: 'auto',
                maxHeight: '90vh',
                grow: 'fullscreen',
                showConfirmButton: false,
                didOpen: () => {
                    const closeBtn = document.getElementById('closeAlertBtn');
                    closeBtn.addEventListener('click', () => {
                        Swal.close();
                    });
                }
            });
        }
    }
    const typeValidation = (value) => {
        if (value[0]) {
            if (format) {
                if (value[0]?.type === format || format.includes(value[0]?.type)) {
                    return true
                } else {
                    return false || formatError
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }
    const requestValidation = (value) => {
        if (!value[0] && otherValidation === "editar") {
            return true
        } else if (fileName || value[0]) {
            return true
        } else {
            return false || "El DNI es requerido"
        }
    }
    const getBase64FromInputFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    return (
        <>
            <label className="file-group" htmlFor={id}>
                <div className="file file-btn" >Elegir Archivo</div>
                <div className="file file-text">{limitNameLength(nombreArchivo)}</div>
            </label>
            <input
                {...register(refText, {
                    validate: {
                        required: value => requestValidation(value),
                        type: value => typeValidation(value)
                    },
                    onChange: onChangeHandler
                })}
                id={id}
                className="d-none"
                type="file"
                accept={format}
            />
        </>
    )
}