export const ubigeo = [
  {
    TUbiCod: "010101",
    TUbiDes: "Amazonas/Chachapoyas/Chachapoyas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010102",
    TUbiDes: "Amazonas/Chachapoyas/Asuncion",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010103",
    TUbiDes: "Amazonas/Chachapoyas/Balsas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010104",
    TUbiDes: "Amazonas/Chachapoyas/Cheto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010105",
    TUbiDes: "Amazonas/Chachapoyas/Chiliquin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010106",
    TUbiDes: "Amazonas/Chachapoyas/Chuquibamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010107",
    TUbiDes: "Amazonas/Chachapoyas/Granada",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010108",
    TUbiDes: "Amazonas/Chachapoyas/Huancas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010109",
    TUbiDes: "Amazonas/Chachapoyas/La Jalca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010110",
    TUbiDes: "Amazonas/Chachapoyas/Leimebamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010111",
    TUbiDes: "Amazonas/Chachapoyas/Levanto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010112",
    TUbiDes: "Amazonas/Chachapoyas/Magdalena",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010113",
    TUbiDes: "Amazonas/Chachapoyas/Mariscal Castilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010114",
    TUbiDes: "Amazonas/Chachapoyas/Molinopampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010115",
    TUbiDes: "Amazonas/Chachapoyas/Montevideo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010116",
    TUbiDes: "Amazonas/Chachapoyas/Olleros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010117",
    TUbiDes: "Amazonas/Chachapoyas/Quinjalca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010118",
    TUbiDes: "Amazonas/Chachapoyas/San Francisco de Daguas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010119",
    TUbiDes: "Amazonas/Chachapoyas/San Isidro de Maino",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010120",
    TUbiDes: "Amazonas/Chachapoyas/Soloco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010121",
    TUbiDes: "Amazonas/Chachapoyas/Sonche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010201",
    TUbiDes: "Amazonas/Bagua/La Peca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010202",
    TUbiDes: "Amazonas/Bagua/Aramango",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010203",
    TUbiDes: "Amazonas/Bagua/Copallin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010204",
    TUbiDes: "Amazonas/Bagua/El Parco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010205",
    TUbiDes: "Amazonas/Bagua/Bagua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010206",
    TUbiDes: "Amazonas/Bagua/Imaza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010301",
    TUbiDes: "Amazonas/Bongara/Jumbilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010302",
    TUbiDes: "Amazonas/Bongara/Corosha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010303",
    TUbiDes: "Amazonas/Bongara/Cuispes",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010304",
    TUbiDes: "Amazonas/Bongara/Chisquilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010305",
    TUbiDes: "Amazonas/Bongara/Churuja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010306",
    TUbiDes: "Amazonas/Bongara/Florida",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010307",
    TUbiDes: "Amazonas/Bongara/Recta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010308",
    TUbiDes: "Amazonas/Bongara/San Carlos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010309",
    TUbiDes: "Amazonas/Bongara/Shipasbamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010310",
    TUbiDes: "Amazonas/Bongara/Valera",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010311",
    TUbiDes: "Amazonas/Bongara/Yambrasbamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010312",
    TUbiDes: "Amazonas/Bongara/Jazan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010401",
    TUbiDes: "Amazonas/Luya/Lamud",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010402",
    TUbiDes: "Amazonas/Luya/Camporredondo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010403",
    TUbiDes: "Amazonas/Luya/Cocabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010404",
    TUbiDes: "Amazonas/Luya/Colcamar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010405",
    TUbiDes: "Amazonas/Luya/Conila",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010406",
    TUbiDes: "Amazonas/Luya/Inguilpata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010407",
    TUbiDes: "Amazonas/Luya/Longuita",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010408",
    TUbiDes: "Amazonas/Luya/Lonya Chico",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010409",
    TUbiDes: "Amazonas/Luya/Luya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010410",
    TUbiDes: "Amazonas/Luya/Luya Viejo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010411",
    TUbiDes: "Amazonas/Luya/Maria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010412",
    TUbiDes: "Amazonas/Luya/Ocalli",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010413",
    TUbiDes: "Amazonas/Luya/Ocumal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010414",
    TUbiDes: "Amazonas/Luya/Pisuquia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010415",
    TUbiDes: "Amazonas/Luya/San Cristobal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010416",
    TUbiDes: "Amazonas/Luya/San Francisco del Yeso",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010417",
    TUbiDes: "Amazonas/Luya/San Jeronimo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010418",
    TUbiDes: "Amazonas/Luya/San Juan de Lopecancha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010419",
    TUbiDes: "Amazonas/Luya/Santa Catalina",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010420",
    TUbiDes: "Amazonas/Luya/Santo Tomas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010421",
    TUbiDes: "Amazonas/Luya/Tingo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010422",
    TUbiDes: "Amazonas/Luya/Trita",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010423",
    TUbiDes: "Amazonas/Luya/Providencia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010501",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/San Nicolas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010502",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Cochamal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010503",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Chirimoto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010504",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Huambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010505",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Limabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010506",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Longar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010507",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Milpuc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010508",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Mariscal Benavides",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010509",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Omia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010510",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010511",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Totora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010512",
    TUbiDes: "Amazonas/Rodriguez de Mendoza/Vista Alegre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010601",
    TUbiDes: "Amazonas/Condorcanqui/Nieva",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010602",
    TUbiDes: "Amazonas/Condorcanqui/Rio Santiago",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010603",
    TUbiDes: "Amazonas/Condorcanqui/El Cenepa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010701",
    TUbiDes: "Amazonas/Utcubamba/Bagua Grande",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010702",
    TUbiDes: "Amazonas/Utcubamba/Cajaruro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010703",
    TUbiDes: "Amazonas/Utcubamba/Cumba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010704",
    TUbiDes: "Amazonas/Utcubamba/El Milagro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010705",
    TUbiDes: "Amazonas/Utcubamba/Jamalca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010706",
    TUbiDes: "Amazonas/Utcubamba/Lonya Grande",
    TUbiEst: "S",
  },
  {
    TUbiCod: "010707",
    TUbiDes: "Amazonas/Utcubamba/Yamon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020101",
    TUbiDes: "Ancash/Huaraz/Huaraz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020102",
    TUbiDes: "Ancash/Huaraz/Independencia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020103",
    TUbiDes: "Ancash/Huaraz/Cochabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020104",
    TUbiDes: "Ancash/Huaraz/Colcabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020105",
    TUbiDes: "Ancash/Huaraz/Huanchay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020106",
    TUbiDes: "Ancash/Huaraz/Jangas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020107",
    TUbiDes: "Ancash/Huaraz/La Libertad",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020108",
    TUbiDes: "Ancash/Huaraz/Olleros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020109",
    TUbiDes: "Ancash/Huaraz/Pampas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020110",
    TUbiDes: "Ancash/Huaraz/Pariacoto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020111",
    TUbiDes: "Ancash/Huaraz/Pira",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020112",
    TUbiDes: "Ancash/Huaraz/Tarica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020201",
    TUbiDes: "Ancash/Aija/Aija",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020203",
    TUbiDes: "Ancash/Aija/Coris",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020205",
    TUbiDes: "Ancash/Aija/Huacllan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020206",
    TUbiDes: "Ancash/Aija/La Merced",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020208",
    TUbiDes: "Ancash/Aija/Succha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020301",
    TUbiDes: "Ancash/Bolognesi/Chiquian",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020302",
    TUbiDes: "Ancash/Bolognesi/Abelardo Pardo Lezameta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020304",
    TUbiDes: "Ancash/Bolognesi/Aquia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020305",
    TUbiDes: "Ancash/Bolognesi/Cajacay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020310",
    TUbiDes: "Ancash/Bolognesi/Huayllacayan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020311",
    TUbiDes: "Ancash/Bolognesi/Huasta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020313",
    TUbiDes: "Ancash/Bolognesi/Mangas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020315",
    TUbiDes: "Ancash/Bolognesi/Pacllon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020317",
    TUbiDes: "Ancash/Bolognesi/San Miguel de Corpanqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020320",
    TUbiDes: "Ancash/Bolognesi/Ticllos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020321",
    TUbiDes: "Ancash/Bolognesi/Antonio Raymondi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020322",
    TUbiDes: "Ancash/Bolognesi/Canis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020323",
    TUbiDes: "Ancash/Bolognesi/Colquioc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020324",
    TUbiDes: "Ancash/Bolognesi/La Primavera",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020325",
    TUbiDes: "Ancash/Bolognesi/Huallanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020401",
    TUbiDes: "Ancash/Carhuaz/Carhuaz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020402",
    TUbiDes: "Ancash/Carhuaz/Acopampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020403",
    TUbiDes: "Ancash/Carhuaz/Amashca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020404",
    TUbiDes: "Ancash/Carhuaz/Anta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020405",
    TUbiDes: "Ancash/Carhuaz/Ataquero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020406",
    TUbiDes: "Ancash/Carhuaz/Marcara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020407",
    TUbiDes: "Ancash/Carhuaz/Pariahuanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020408",
    TUbiDes: "Ancash/Carhuaz/San Miguel de Aco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020409",
    TUbiDes: "Ancash/Carhuaz/Shilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020410",
    TUbiDes: "Ancash/Carhuaz/Tinco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020411",
    TUbiDes: "Ancash/Carhuaz/Yungar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020501",
    TUbiDes: "Ancash/Casma/Casma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020502",
    TUbiDes: "Ancash/Casma/Buena Vista Alta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020503",
    TUbiDes: "Ancash/Casma/Comandante Noel",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020505",
    TUbiDes: "Ancash/Casma/Yautan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020601",
    TUbiDes: "Ancash/Corongo/Corongo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020602",
    TUbiDes: "Ancash/Corongo/Aco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020603",
    TUbiDes: "Ancash/Corongo/Bambas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020604",
    TUbiDes: "Ancash/Corongo/Cusca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020605",
    TUbiDes: "Ancash/Corongo/La Pampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020606",
    TUbiDes: "Ancash/Corongo/Yanac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020607",
    TUbiDes: "Ancash/Corongo/Yupan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020701",
    TUbiDes: "Ancash/Huaylas/Caraz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020702",
    TUbiDes: "Ancash/Huaylas/Huallanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020703",
    TUbiDes: "Ancash/Huaylas/Huata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020704",
    TUbiDes: "Ancash/Huaylas/Huaylas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020705",
    TUbiDes: "Ancash/Huaylas/Mato",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020706",
    TUbiDes: "Ancash/Huaylas/Pamparomas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020707",
    TUbiDes: "Ancash/Huaylas/Pueblo Libre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020708",
    TUbiDes: "Ancash/Huaylas/Santa Cruz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020709",
    TUbiDes: "Ancash/Huaylas/Yuracmarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020710",
    TUbiDes: "Ancash/Huaylas/Santo Toribio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020801",
    TUbiDes: "Ancash/Huari/Huari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020802",
    TUbiDes: "Ancash/Huari/Cajay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020803",
    TUbiDes: "Ancash/Huari/Chavin de Huantar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020804",
    TUbiDes: "Ancash/Huari/Huacachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020805",
    TUbiDes: "Ancash/Huari/Huachis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020806",
    TUbiDes: "Ancash/Huari/Huacchis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020807",
    TUbiDes: "Ancash/Huari/Huantar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020808",
    TUbiDes: "Ancash/Huari/Masin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020809",
    TUbiDes: "Ancash/Huari/Paucas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020810",
    TUbiDes: "Ancash/Huari/Ponto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020811",
    TUbiDes: "Ancash/Huari/Rahuapampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020812",
    TUbiDes: "Ancash/Huari/Rapayan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020813",
    TUbiDes: "Ancash/Huari/San Marcos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020814",
    TUbiDes: "Ancash/Huari/San Pedro de Chana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020815",
    TUbiDes: "Ancash/Huari/Uco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020816",
    TUbiDes: "Ancash/Huari/Anra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020901",
    TUbiDes: "Ancash/Mariscal Luzuriaga/Piscobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020902",
    TUbiDes: "Ancash/Mariscal Luzuriaga/Casca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020903",
    TUbiDes: "Ancash/Mariscal Luzuriaga/Lucma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020904",
    TUbiDes: "Ancash/Mariscal Luzuriaga/Fidel Olivas Escudero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020905",
    TUbiDes: "Ancash/Mariscal Luzuriaga/Llama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020906",
    TUbiDes: "Ancash/Mariscal Luzuriaga/Llumpa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020907",
    TUbiDes: "Ancash/Mariscal Luzuriaga/Musga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "020908",
    TUbiDes: "Ancash/Mariscal Luzuriaga/Eleazar Guzman Barron",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021001",
    TUbiDes: "Ancash/Pallasca/Cabana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021002",
    TUbiDes: "Ancash/Pallasca/Bolognesi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021003",
    TUbiDes: "Ancash/Pallasca/Conchucos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021004",
    TUbiDes: "Ancash/Pallasca/Huacaschuque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021005",
    TUbiDes: "Ancash/Pallasca/Huandoval",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021006",
    TUbiDes: "Ancash/Pallasca/Lacabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021007",
    TUbiDes: "Ancash/Pallasca/Llapo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021008",
    TUbiDes: "Ancash/Pallasca/Pallasca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021009",
    TUbiDes: "Ancash/Pallasca/Pampas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021010",
    TUbiDes: "Ancash/Pallasca/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021011",
    TUbiDes: "Ancash/Pallasca/Tauca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021101",
    TUbiDes: "Ancash/Pomabamba/Pomabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021102",
    TUbiDes: "Ancash/Pomabamba/Huayllan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021103",
    TUbiDes: "Ancash/Pomabamba/Parobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021104",
    TUbiDes: "Ancash/Pomabamba/Quinuabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021201",
    TUbiDes: "Ancash/Recuay/Recuay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021202",
    TUbiDes: "Ancash/Recuay/Cotaparaco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021203",
    TUbiDes: "Ancash/Recuay/Huayllapampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021204",
    TUbiDes: "Ancash/Recuay/Marca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021205",
    TUbiDes: "Ancash/Recuay/PampasChico",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021206",
    TUbiDes: "Ancash/Recuay/Pararin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021207",
    TUbiDes: "Ancash/Recuay/Tapacocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021208",
    TUbiDes: "Ancash/Recuay/Ticapampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021209",
    TUbiDes: "Ancash/Recuay/Llacllin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021210",
    TUbiDes: "Ancash/Recuay/Catac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021301",
    TUbiDes: "Ancash/Santa/Chimbote",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021302",
    TUbiDes: "Ancash/Santa/Caceres del Peru",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021303",
    TUbiDes: "Ancash/Santa/Macate",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021304",
    TUbiDes: "Ancash/Santa/Moro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021305",
    TUbiDes: "Ancash/Santa/Nepeña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021306",
    TUbiDes: "Ancash/Santa/Samanco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021307",
    TUbiDes: "Ancash/Santa/Santa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021308",
    TUbiDes: "Ancash/Santa/Coishco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021309",
    TUbiDes: "Ancash/Santa/Nuevo Chimbote",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021401",
    TUbiDes: "Ancash/Sihuas/Sihuas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021402",
    TUbiDes: "Ancash/Sihuas/Alfonso Ugarte",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021403",
    TUbiDes: "Ancash/Sihuas/Chingalpo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021404",
    TUbiDes: "Ancash/Sihuas/Huayllabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021405",
    TUbiDes: "Ancash/Sihuas/Quiches",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021406",
    TUbiDes: "Ancash/Sihuas/Sicsibamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021407",
    TUbiDes: "Ancash/Sihuas/Acobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021408",
    TUbiDes: "Ancash/Sihuas/Cashapampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021409",
    TUbiDes: "Ancash/Sihuas/Ragash",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021410",
    TUbiDes: "Ancash/Sihuas/San Juan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021501",
    TUbiDes: "Ancash/Yungay/Yungay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021502",
    TUbiDes: "Ancash/Yungay/Cascapara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021503",
    TUbiDes: "Ancash/Yungay/Mancos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021504",
    TUbiDes: "Ancash/Yungay/Matacoto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021505",
    TUbiDes: "Ancash/Yungay/Quillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021506",
    TUbiDes: "Ancash/Yungay/Ranrahirca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021507",
    TUbiDes: "Ancash/Yungay/Shupluy",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021508",
    TUbiDes: "Ancash/Yungay/Yanama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021601",
    TUbiDes: "Ancash/Antonio Raymondi/Llamellin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021602",
    TUbiDes: "Ancash/Antonio Raymondi/Aczo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021603",
    TUbiDes: "Ancash/Antonio Raymondi/Chaccho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021604",
    TUbiDes: "Ancash/Antonio Raymondi/Chingas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021605",
    TUbiDes: "Ancash/Antonio Raymondi/Mirgas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021606",
    TUbiDes: "Ancash/Antonio Raymondi/San JuandeRontoy",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021701",
    TUbiDes: "Ancash/Carlos Fermin Fitzca/San Luis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021702",
    TUbiDes: "Ancash/Carlos Fermin Fitzca/Yauya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021703",
    TUbiDes: "Ancash/Carlos Fermin Fitzca/San Nicolas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021801",
    TUbiDes: "Ancash/Asuncion/Chacas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021802",
    TUbiDes: "Ancash/Asuncion/Acochaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021901",
    TUbiDes: "Ancash/Huarmey/Huarmey",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021902",
    TUbiDes: "Ancash/Huarmey/Cochapeti",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021903",
    TUbiDes: "Ancash/Huarmey/Huayan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021904",
    TUbiDes: "Ancash/Huarmey/Malvas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "021905",
    TUbiDes: "Ancash/Huarmey/Culebras",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022001",
    TUbiDes: "Ancash/Ocros/Acas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022002",
    TUbiDes: "Ancash/Ocros/Cajamarquilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022003",
    TUbiDes: "Ancash/Ocros/Carhuapampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022004",
    TUbiDes: "Ancash/Ocros/Cochas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022005",
    TUbiDes: "Ancash/Ocros/Congas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022006",
    TUbiDes: "Ancash/Ocros/Llipa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022007",
    TUbiDes: "Ancash/Ocros/Ocros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022008",
    TUbiDes: "Ancash/Ocros/San Cristobal de Rajan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022009",
    TUbiDes: "Ancash/Ocros/San Pedro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "022010",
    TUbiDes: "Ancash/Ocros/Santiago de Chilcas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030101",
    TUbiDes: "Apurimac/Abancay/Abancay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030102",
    TUbiDes: "Apurimac/Abancay/Circa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030103",
    TUbiDes: "Apurimac/Abancay/Curahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030104",
    TUbiDes: "Apurimac/Abancay/Chacoche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030105",
    TUbiDes: "Apurimac/Abancay/Huanipaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030106",
    TUbiDes: "Apurimac/Abancay/Lambrama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030107",
    TUbiDes: "Apurimac/Abancay/Pichirhua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030108",
    TUbiDes: "Apurimac/Abancay/San Pedro de Cachora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030109",
    TUbiDes: "Apurimac/Abancay/Tamburco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030201",
    TUbiDes: "Apurimac/Aymaraes/Chalhuanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030202",
    TUbiDes: "Apurimac/Aymaraes/Capaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030203",
    TUbiDes: "Apurimac/Aymaraes/Caraybamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030204",
    TUbiDes: "Apurimac/Aymaraes/Colcabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030205",
    TUbiDes: "Apurimac/Aymaraes/Cotaruse",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030206",
    TUbiDes: "Apurimac/Aymaraes/Chapimarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030207",
    TUbiDes: "Apurimac/Aymaraes/Huayllo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030208",
    TUbiDes: "Apurimac/Aymaraes/Lucre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030209",
    TUbiDes: "Apurimac/Aymaraes/Pocohuanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030210",
    TUbiDes: "Apurimac/Aymaraes/Sañayca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030211",
    TUbiDes: "Apurimac/Aymaraes/Soraya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030212",
    TUbiDes: "Apurimac/Aymaraes/Tapairihua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030213",
    TUbiDes: "Apurimac/Aymaraes/Tintay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030214",
    TUbiDes: "Apurimac/Aymaraes/Toraya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030215",
    TUbiDes: "Apurimac/Aymaraes/Yanaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030216",
    TUbiDes: "Apurimac/Aymaraes/San Juan de Chacña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030217",
    TUbiDes: "Apurimac/Aymaraes/Justo Apu Sahuaraura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030301",
    TUbiDes: "Apurimac/Andahuaylas/Andahuaylas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030302",
    TUbiDes: "Apurimac/Andahuaylas/Andarapa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030303",
    TUbiDes: "Apurimac/Andahuaylas/Chiara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030304",
    TUbiDes: "Apurimac/Andahuaylas/Huancarama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030305",
    TUbiDes: "Apurimac/Andahuaylas/Huancaray",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030306",
    TUbiDes: "Apurimac/Andahuaylas/Kishuara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030307",
    TUbiDes: "Apurimac/Andahuaylas/Pacobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030308",
    TUbiDes: "Apurimac/Andahuaylas/Pampachiri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030309",
    TUbiDes: "Apurimac/Andahuaylas/San Antonio de Cachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030310",
    TUbiDes: "Apurimac/Andahuaylas/San Jeronimo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030311",
    TUbiDes: "Apurimac/Andahuaylas/Talavera",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030312",
    TUbiDes: "Apurimac/Andahuaylas/Turpo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030313",
    TUbiDes: "Apurimac/Andahuaylas/Pacucha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030314",
    TUbiDes: "Apurimac/Andahuaylas/Pomacocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030315",
    TUbiDes: "Apurimac/Andahuaylas/Santa Maria de Chicmo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030316",
    TUbiDes: "Apurimac/Andahuaylas/Tumay Huaraca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030317",
    TUbiDes: "Apurimac/Andahuaylas/Huayana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030318",
    TUbiDes: "Apurimac/Andahuaylas/San Miguel de Chaccrampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030319",
    TUbiDes: "Apurimac/Andahuaylas/Kaquiabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030401",
    TUbiDes: "Apurimac/Antabamba/Antabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030402",
    TUbiDes: "Apurimac/Antabamba/ElOro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030403",
    TUbiDes: "Apurimac/Antabamba/Huaquirca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030404",
    TUbiDes: "Apurimac/Antabamba/Juan Espinoza Medrano",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030405",
    TUbiDes: "Apurimac/Antabamba/Oropesa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030406",
    TUbiDes: "Apurimac/Antabamba/Pachaconas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030407",
    TUbiDes: "Apurimac/Antabamba/Sabaino",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030501",
    TUbiDes: "Apurimac/Cotabambas/Tambobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030502",
    TUbiDes: "Apurimac/Cotabambas/Coyllurqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030503",
    TUbiDes: "Apurimac/Cotabambas/Cotabambas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030504",
    TUbiDes: "Apurimac/Cotabambas/Haquira",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030505",
    TUbiDes: "Apurimac/Cotabambas/Mara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030506",
    TUbiDes: "Apurimac/Cotabambas/Challhuahuacho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030601",
    TUbiDes: "Apurimac/Grau/Chuquibambilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030602",
    TUbiDes: "Apurimac/Grau/Curpahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030603",
    TUbiDes: "Apurimac/Grau/Huayllati",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030604",
    TUbiDes: "Apurimac/Grau/Mamara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030605",
    TUbiDes: "Apurimac/Grau/Gamarra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030606",
    TUbiDes: "Apurimac/Grau/Micaela Bastidas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030607",
    TUbiDes: "Apurimac/Grau/Progreso",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030608",
    TUbiDes: "Apurimac/Grau/Pataypampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030609",
    TUbiDes: "Apurimac/Grau/San Antonio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030610",
    TUbiDes: "Apurimac/Grau/Turpay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030611",
    TUbiDes: "Apurimac/Grau/Vilcabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030612",
    TUbiDes: "Apurimac/Grau/Virundo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030613",
    TUbiDes: "Apurimac/Grau/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030614",
    TUbiDes: "Apurimac/Grau/Curasco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030701",
    TUbiDes: "Apurimac/Chincheros/Chincheros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030702",
    TUbiDes: "Apurimac/Chincheros/Ongoy",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030703",
    TUbiDes: "Apurimac/Chincheros/Ocobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030704",
    TUbiDes: "Apurimac/Chincheros/Cocharcas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030705",
    TUbiDes: "Apurimac/Chincheros/Anco_Huallo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030706",
    TUbiDes: "Apurimac/Chincheros/Huaccana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030707",
    TUbiDes: "Apurimac/Chincheros/Uranmarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "030708",
    TUbiDes: "Apurimac/Chincheros/Ranracancha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040101",
    TUbiDes: "Arequipa/Arequipa/Arequipa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040102",
    TUbiDes: "Arequipa/Arequipa/Cayma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040103",
    TUbiDes: "Arequipa/Arequipa/Cerro Colorado",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040104",
    TUbiDes: "Arequipa/Arequipa/Characato",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040105",
    TUbiDes: "Arequipa/Arequipa/Chiguata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040106",
    TUbiDes: "Arequipa/Arequipa/La Joya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040107",
    TUbiDes: "Arequipa/Arequipa/Miraflores",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040108",
    TUbiDes: "Arequipa/Arequipa/Mollebaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040109",
    TUbiDes: "Arequipa/Arequipa/Paucarpata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040110",
    TUbiDes: "Arequipa/Arequipa/Pocsi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040111",
    TUbiDes: "Arequipa/Arequipa/Polobaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040112",
    TUbiDes: "Arequipa/Arequipa/Quequeña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040113",
    TUbiDes: "Arequipa/Arequipa/Sabandia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040114",
    TUbiDes: "Arequipa/Arequipa/Sachaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040115",
    TUbiDes: "Arequipa/Arequipa/San Juan de Siguas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040116",
    TUbiDes: "Arequipa/Arequipa/San Juan de Tarucani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040117",
    TUbiDes: "Arequipa/Arequipa/Santa Isabel de Siguas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040118",
    TUbiDes: "Arequipa/Arequipa/Santa Rita de Siguas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040119",
    TUbiDes: "Arequipa/Arequipa/Socabaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040120",
    TUbiDes: "Arequipa/Arequipa/Tiabaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040121",
    TUbiDes: "Arequipa/Arequipa/Uchumayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040122",
    TUbiDes: "Arequipa/Arequipa/Vitor",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040123",
    TUbiDes: "Arequipa/Arequipa/Yanahuara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040124",
    TUbiDes: "Arequipa/Arequipa/Yarabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040125",
    TUbiDes: "Arequipa/Arequipa/Yura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040126",
    TUbiDes: "Arequipa/Arequipa/Mariano Melgar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040127",
    TUbiDes: "Arequipa/Arequipa/Jacobo Hunter",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040128",
    TUbiDes: "Arequipa/Arequipa/Alto Selva Alegre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040129",
    TUbiDes: "Arequipa/Arequipa/Jose Luis Bustamante y Rivero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040201",
    TUbiDes: "Arequipa/Caylloma/Chivay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040202",
    TUbiDes: "Arequipa/Caylloma/Achoma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040203",
    TUbiDes: "Arequipa/Caylloma/Cabanaconde",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040204",
    TUbiDes: "Arequipa/Caylloma/Caylloma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040205",
    TUbiDes: "Arequipa/Caylloma/Callalli",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040206",
    TUbiDes: "Arequipa/Caylloma/Coporaque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040207",
    TUbiDes: "Arequipa/Caylloma/Huambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040208",
    TUbiDes: "Arequipa/Caylloma/Huanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040209",
    TUbiDes: "Arequipa/Caylloma/Ichupampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040210",
    TUbiDes: "Arequipa/Caylloma/Lari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040211",
    TUbiDes: "Arequipa/Caylloma/Lluta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040212",
    TUbiDes: "Arequipa/Caylloma/Maca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040213",
    TUbiDes: "Arequipa/Caylloma/Madrigal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040214",
    TUbiDes: "Arequipa/Caylloma/San Antonio de Chuca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040215",
    TUbiDes: "Arequipa/Caylloma/Sibayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040216",
    TUbiDes: "Arequipa/Caylloma/Tapay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040217",
    TUbiDes: "Arequipa/Caylloma/Tisco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040218",
    TUbiDes: "Arequipa/Caylloma/Tuti",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040219",
    TUbiDes: "Arequipa/Caylloma/Yanque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040220",
    TUbiDes: "Arequipa/Caylloma/Majes",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040301",
    TUbiDes: "Arequipa/Camana/Camana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040302",
    TUbiDes: "Arequipa/Camana/Jose Maria Quimper",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040303",
    TUbiDes: "Arequipa/Camana/Mariano Nicolas Valcarcel",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040304",
    TUbiDes: "Arequipa/Camana/Mariscal Caceres",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040305",
    TUbiDes: "Arequipa/Camana/Nicolas de Pierola",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040306",
    TUbiDes: "Arequipa/Camana/Ocoña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040307",
    TUbiDes: "Arequipa/Camana/Quilca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040308",
    TUbiDes: "Arequipa/Camana/Samuel Pastor",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040401",
    TUbiDes: "Arequipa/Caraveli/Caraveli",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040402",
    TUbiDes: "Arequipa/Caraveli/Acari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040403",
    TUbiDes: "Arequipa/Caraveli/Atico",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040404",
    TUbiDes: "Arequipa/Caraveli/Atiquipa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040405",
    TUbiDes: "Arequipa/Caraveli/BellaUnion",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040406",
    TUbiDes: "Arequipa/Caraveli/Cahuacho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040407",
    TUbiDes: "Arequipa/Caraveli/Chala",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040408",
    TUbiDes: "Arequipa/Caraveli/Chaparra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040409",
    TUbiDes: "Arequipa/Caraveli/Huanuhuanu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040410",
    TUbiDes: "Arequipa/Caraveli/Jaqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040411",
    TUbiDes: "Arequipa/Caraveli/Lomas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040412",
    TUbiDes: "Arequipa/Caraveli/Quicacha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040413",
    TUbiDes: "Arequipa/Caraveli/Yauca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040501",
    TUbiDes: "Arequipa/Castilla/Aplao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040502",
    TUbiDes: "Arequipa/Castilla/Andagua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040503",
    TUbiDes: "Arequipa/Castilla/Ayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040504",
    TUbiDes: "Arequipa/Castilla/Chachas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040505",
    TUbiDes: "Arequipa/Castilla/Chilcaymarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040506",
    TUbiDes: "Arequipa/Castilla/Choco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040507",
    TUbiDes: "Arequipa/Castilla/Huancarqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040508",
    TUbiDes: "Arequipa/Castilla/Machaguay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040509",
    TUbiDes: "Arequipa/Castilla/Orcopampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040510",
    TUbiDes: "Arequipa/Castilla/Pampacolca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040511",
    TUbiDes: "Arequipa/Castilla/Tipan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040512",
    TUbiDes: "Arequipa/Castilla/Uraca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040513",
    TUbiDes: "Arequipa/Castilla/Uñon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040514",
    TUbiDes: "Arequipa/Castilla/Viraco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040601",
    TUbiDes: "Arequipa/Condesuyos/Chuquibamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040602",
    TUbiDes: "Arequipa/Condesuyos/Andaray",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040603",
    TUbiDes: "Arequipa/Condesuyos/Cayarani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040604",
    TUbiDes: "Arequipa/Condesuyos/Chichas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040605",
    TUbiDes: "Arequipa/Condesuyos/Iray",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040606",
    TUbiDes: "Arequipa/Condesuyos/Salamanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040607",
    TUbiDes: "Arequipa/Condesuyos/Yanaquihua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040608",
    TUbiDes: "Arequipa/Condesuyos/Rio Grande",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040701",
    TUbiDes: "Arequipa/Islay/Mollendo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040702",
    TUbiDes: "Arequipa/Islay/Cocachacra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040703",
    TUbiDes: "Arequipa/Islay/Dean Valdivia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040704",
    TUbiDes: "Arequipa/Islay/Islay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040705",
    TUbiDes: "Arequipa/Islay/Mejia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040706",
    TUbiDes: "Arequipa/Islay/Punta de Bombon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040801",
    TUbiDes: "Arequipa/La Union/Cotahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040802",
    TUbiDes: "Arequipa/La Union/Alca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040803",
    TUbiDes: "Arequipa/La Union/Charcana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040804",
    TUbiDes: "Arequipa/La Union/Huaynacotas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040805",
    TUbiDes: "Arequipa/La Union/Pampamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040806",
    TUbiDes: "Arequipa/La Union/Puyca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040807",
    TUbiDes: "Arequipa/La Union/Quechualla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040808",
    TUbiDes: "Arequipa/La Union/Sayla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040809",
    TUbiDes: "Arequipa/La Union/Tauria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040810",
    TUbiDes: "Arequipa/La Union/Tomepampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "040811",
    TUbiDes: "Arequipa/La Union/Toro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050101",
    TUbiDes: "Ayacucho/Huamanga/Ayacucho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050102",
    TUbiDes: "Ayacucho/Huamanga/Acos Vinchos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050103",
    TUbiDes: "Ayacucho/Huamanga/Carmen Alto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050104",
    TUbiDes: "Ayacucho/Huamanga/Chiara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050105",
    TUbiDes: "Ayacucho/Huamanga/Quinua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050106",
    TUbiDes: "Ayacucho/Huamanga/San Jose de Ticllas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050107",
    TUbiDes: "Ayacucho/Huamanga/San Juan Bautista",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050108",
    TUbiDes: "Ayacucho/Huamanga/Santiago de Pischa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050109",
    TUbiDes: "Ayacucho/Huamanga/Vinchos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050110",
    TUbiDes: "Ayacucho/Huamanga/Tambillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050111",
    TUbiDes: "Ayacucho/Huamanga/Acocro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050112",
    TUbiDes: "Ayacucho/Huamanga/Socos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050113",
    TUbiDes: "Ayacucho/Huamanga/Ocros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050114",
    TUbiDes: "Ayacucho/Huamanga/Pacaycasa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050115",
    TUbiDes: "Ayacucho/Huamanga/Jesus Nazareno",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050201",
    TUbiDes: "Ayacucho/Cangallo/Cangallo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050204",
    TUbiDes: "Ayacucho/Cangallo/Chuschi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050206",
    TUbiDes: "Ayacucho/Cangallo/Los Morochucos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050207",
    TUbiDes: "Ayacucho/Cangallo/Paras",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050208",
    TUbiDes: "Ayacucho/Cangallo/Totos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050211",
    TUbiDes: "Ayacucho/Cangallo/Maria Parado de Bellido",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050301",
    TUbiDes: "Ayacucho/Huanta/Huanta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050302",
    TUbiDes: "Ayacucho/Huanta/Ayahuanco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050303",
    TUbiDes: "Ayacucho/Huanta/Huamanguilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050304",
    TUbiDes: "Ayacucho/Huanta/Iguain",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050305",
    TUbiDes: "Ayacucho/Huanta/Luricocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050307",
    TUbiDes: "Ayacucho/Huanta/Santillana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050308",
    TUbiDes: "Ayacucho/Huanta/Sivia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050309",
    TUbiDes: "Ayacucho/Huanta/Llochegua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050401",
    TUbiDes: "Ayacucho/La Mar/San Miguel",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050402",
    TUbiDes: "Ayacucho/La Mar/Anco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050403",
    TUbiDes: "Ayacucho/La Mar/Ayna",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050404",
    TUbiDes: "Ayacucho/La Mar/Chilcas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050405",
    TUbiDes: "Ayacucho/La Mar/Chungui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050406",
    TUbiDes: "Ayacucho/La Mar/Tambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050407",
    TUbiDes: "Ayacucho/La Mar/Luis Carranza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050408",
    TUbiDes: "Ayacucho/La Mar/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050409",
    TUbiDes: "Ayacucho/La Mar/Samugari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050501",
    TUbiDes: "Ayacucho/Lucanas/Puquio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050502",
    TUbiDes: "Ayacucho/Lucanas/Aucara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050503",
    TUbiDes: "Ayacucho/Lucanas/Cabana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050504",
    TUbiDes: "Ayacucho/Lucanas/Carmen Salcedo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050506",
    TUbiDes: "Ayacucho/Lucanas/Chaviña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050508",
    TUbiDes: "Ayacucho/Lucanas/Chipao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050510",
    TUbiDes: "Ayacucho/Lucanas/Huac Huas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050511",
    TUbiDes: "Ayacucho/Lucanas/Laramate",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050512",
    TUbiDes: "Ayacucho/Lucanas/Leoncio Prado",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050513",
    TUbiDes: "Ayacucho/Lucanas/Lucanas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050514",
    TUbiDes: "Ayacucho/Lucanas/Llauta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050516",
    TUbiDes: "Ayacucho/Lucanas/Ocaña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050517",
    TUbiDes: "Ayacucho/Lucanas/Otoca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050520",
    TUbiDes: "Ayacucho/Lucanas/Sancos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050521",
    TUbiDes: "Ayacucho/Lucanas/San Juan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050522",
    TUbiDes: "Ayacucho/Lucanas/San Pedro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050524",
    TUbiDes: "Ayacucho/Lucanas/Santa Ana de Huaycahuacho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050525",
    TUbiDes: "Ayacucho/Lucanas/Santa Lucia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050529",
    TUbiDes: "Ayacucho/Lucanas/Saisa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050531",
    TUbiDes: "Ayacucho/Lucanas/San Pedro de Palco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050532",
    TUbiDes: "Ayacucho/Lucanas/San Cristobal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050601",
    TUbiDes: "Ayacucho/Parinacochas/Coracora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050604",
    TUbiDes: "Ayacucho/Parinacochas/Coronel Castañeda",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050605",
    TUbiDes: "Ayacucho/Parinacochas/Chumpi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050608",
    TUbiDes: "Ayacucho/Parinacochas/Pacapausa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050611",
    TUbiDes: "Ayacucho/Parinacochas/Pullo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050612",
    TUbiDes: "Ayacucho/Parinacochas/Puyusca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050615",
    TUbiDes: "Ayacucho/Parinacochas/San Francisco de Ravacayco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050616",
    TUbiDes: "Ayacucho/Parinacochas/Upahuacho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050701",
    TUbiDes: "Ayacucho/Victor Fajardo/Huancapi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050702",
    TUbiDes: "Ayacucho/Victor Fajardo/Alcamenca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050703",
    TUbiDes: "Ayacucho/Victor Fajardo/Apongo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050704",
    TUbiDes: "Ayacucho/Victor Fajardo/Canaria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050706",
    TUbiDes: "Ayacucho/Victor Fajardo/Cayara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050707",
    TUbiDes: "Ayacucho/Victor Fajardo/Colca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050708",
    TUbiDes: "Ayacucho/Victor Fajardo/Huaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050709",
    TUbiDes: "Ayacucho/Victor Fajardo/Huamanquiquia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050710",
    TUbiDes: "Ayacucho/Victor Fajardo/Huancaraylla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050713",
    TUbiDes: "Ayacucho/Victor Fajardo/Sarhua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050714",
    TUbiDes: "Ayacucho/Victor Fajardo/Vilcanchos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050715",
    TUbiDes: "Ayacucho/Victor Fajardo/Asquipata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050801",
    TUbiDes: "Ayacucho/Huanca Sancos/Sancos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050802",
    TUbiDes: "Ayacucho/Huanca Sancos/Sacsamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050803",
    TUbiDes: "Ayacucho/Huanca Sancos/Santiago de Lucanamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050804",
    TUbiDes: "Ayacucho/Huanca Sancos/Carapo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050901",
    TUbiDes: "Ayacucho/Vilcas Huaman/Vilcas Huaman",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050902",
    TUbiDes: "Ayacucho/Vilcas Huaman/Vischongo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050903",
    TUbiDes: "Ayacucho/Vilcas Huaman/Accomarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050904",
    TUbiDes: "Ayacucho/Vilcas Huaman/Carhuanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050905",
    TUbiDes: "Ayacucho/Vilcas Huaman/Concepcion",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050906",
    TUbiDes: "Ayacucho/Vilcas Huaman/Huambalpa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050907",
    TUbiDes: "Ayacucho/Vilcas Huaman/Saurama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "050908",
    TUbiDes: "Ayacucho/Vilcas Huaman/Independencia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051001",
    TUbiDes: "Ayacucho/Paucar del SaraSara/Pausa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051002",
    TUbiDes: "Ayacucho/Paucar del SaraSara/Colta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051003",
    TUbiDes: "Ayacucho/Paucar del SaraSara/Corculla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051004",
    TUbiDes: "Ayacucho/Paucar del SaraSara/Lampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051005",
    TUbiDes: "Ayacucho/Paucar del SaraSara/Marcabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051006",
    TUbiDes: "Ayacucho/Paucar del SaraSara/Oyolo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051007",
    TUbiDes: "Ayacucho/Paucar del SaraSara/Pararca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051008",
    TUbiDes: "Ayacucho/Paucar del SaraSara/San Javier de Alpabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051009",
    TUbiDes: "Ayacucho/Paucar del SaraSara/San Jose de Ushua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051010",
    TUbiDes: "Ayacucho/Paucar del SaraSara/Sara Sara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051101",
    TUbiDes: "Ayacucho/Sucre/Querobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051102",
    TUbiDes: "Ayacucho/Sucre/Belen",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051103",
    TUbiDes: "Ayacucho/Sucre/Chalcos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051104",
    TUbiDes: "Ayacucho/Sucre/San Salvador de Quije",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051105",
    TUbiDes: "Ayacucho/Sucre/Paico",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051106",
    TUbiDes: "Ayacucho/Sucre/Santiago de Paucaray",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051107",
    TUbiDes: "Ayacucho/Sucre/SanPedro de Larcay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051108",
    TUbiDes: "Ayacucho/Sucre/Soras",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051109",
    TUbiDes: "Ayacucho/Sucre/Huacaña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051110",
    TUbiDes: "Ayacucho/Sucre/Chilcayoc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "051111",
    TUbiDes: "Ayacucho/Sucre/Morcolla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060101",
    TUbiDes: "Cajamarca/Cajamarca/Cajamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060102",
    TUbiDes: "Cajamarca/Cajamarca/Asuncion",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060103",
    TUbiDes: "Cajamarca/Cajamarca/Cospan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060104",
    TUbiDes: "Cajamarca/Cajamarca/Chetilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060105",
    TUbiDes: "Cajamarca/Cajamarca/Encañada",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060106",
    TUbiDes: "Cajamarca/Cajamarca/Jesus",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060107",
    TUbiDes: "Cajamarca/Cajamarca/Los Baños del Inca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060108",
    TUbiDes: "Cajamarca/Cajamarca/Llacanora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060109",
    TUbiDes: "Cajamarca/Cajamarca/Magdalena",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060110",
    TUbiDes: "Cajamarca/Cajamarca/Matara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060111",
    TUbiDes: "Cajamarca/Cajamarca/Namora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060112",
    TUbiDes: "Cajamarca/Cajamarca/San Juan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060201",
    TUbiDes: "Cajamarca/Cajabamba/Cajabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060202",
    TUbiDes: "Cajamarca/Cajabamba/Cachachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060203",
    TUbiDes: "Cajamarca/Cajabamba/Condebamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060205",
    TUbiDes: "Cajamarca/Cajabamba/Sitacocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060301",
    TUbiDes: "Cajamarca/Celendin/Celendin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060302",
    TUbiDes: "Cajamarca/Celendin/Cortegana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060303",
    TUbiDes: "Cajamarca/Celendin/Chumuch",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060304",
    TUbiDes: "Cajamarca/Celendin/Huasmin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060305",
    TUbiDes: "Cajamarca/Celendin/Jorge Chavez",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060306",
    TUbiDes: "Cajamarca/Celendin/Jose Galvez",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060307",
    TUbiDes: "Cajamarca/Celendin/Miguel Iglesias",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060308",
    TUbiDes: "Cajamarca/Celendin/Oxamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060309",
    TUbiDes: "Cajamarca/Celendin/Sorochuco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060310",
    TUbiDes: "Cajamarca/Celendin/Sucre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060311",
    TUbiDes: "Cajamarca/Celendin/Utco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060312",
    TUbiDes: "Cajamarca/Celendin/La Libertad de Pallan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060401",
    TUbiDes: "Cajamarca/Contumaza/Contumaza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060403",
    TUbiDes: "Cajamarca/Contumaza/Chilete",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060404",
    TUbiDes: "Cajamarca/Contumaza/Guzmango",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060405",
    TUbiDes: "Cajamarca/Contumaza/San Benito",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060406",
    TUbiDes: "Cajamarca/Contumaza/Cupisnique",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060407",
    TUbiDes: "Cajamarca/Contumaza/Tantarica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060408",
    TUbiDes: "Cajamarca/Contumaza/Yonan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060409",
    TUbiDes: "Cajamarca/Contumaza/Santa Cruz de Toled",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060501",
    TUbiDes: "Cajamarca/Cutervo/Cutervo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060502",
    TUbiDes: "Cajamarca/Cutervo/Callayuc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060503",
    TUbiDes: "Cajamarca/Cutervo/Cujillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060504",
    TUbiDes: "Cajamarca/Cutervo/Choros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060505",
    TUbiDes: "Cajamarca/Cutervo/La Ramada",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060506",
    TUbiDes: "Cajamarca/Cutervo/Pimpingos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060507",
    TUbiDes: "Cajamarca/Cutervo/Querocotillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060508",
    TUbiDes: "Cajamarca/Cutervo/San Andres de Cutervo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060509",
    TUbiDes: "Cajamarca/Cutervo/San Juan de Cutervo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060510",
    TUbiDes: "Cajamarca/Cutervo/San Luis de Lucma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060511",
    TUbiDes: "Cajamarca/Cutervo/Santa Cruz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060512",
    TUbiDes: "Cajamarca/Cutervo/Santo Domingo de La Capilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060513",
    TUbiDes: "Cajamarca/Cutervo/Santo Tomas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060514",
    TUbiDes: "Cajamarca/Cutervo/Socota",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060515",
    TUbiDes: "Cajamarca/Cutervo/Toribio Casanova",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060601",
    TUbiDes: "Cajamarca/Chota/Chota",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060602",
    TUbiDes: "Cajamarca/Chota/Anguia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060603",
    TUbiDes: "Cajamarca/Chota/Cochabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060604",
    TUbiDes: "Cajamarca/Chota/Conchan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060605",
    TUbiDes: "Cajamarca/Chota/Chadin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060606",
    TUbiDes: "Cajamarca/Chota/Chiguirip",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060607",
    TUbiDes: "Cajamarca/Chota/Chimban",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060608",
    TUbiDes: "Cajamarca/Chota/Huambos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060609",
    TUbiDes: "Cajamarca/Chota/Lajas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060610",
    TUbiDes: "Cajamarca/Chota/Llama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060611",
    TUbiDes: "Cajamarca/Chota/Miracosta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060612",
    TUbiDes: "Cajamarca/Chota/Paccha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060613",
    TUbiDes: "Cajamarca/Chota/Pion",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060614",
    TUbiDes: "Cajamarca/Chota/Querocoto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060615",
    TUbiDes: "Cajamarca/Chota/Tacabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060616",
    TUbiDes: "Cajamarca/Chota/Tocmoche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060617",
    TUbiDes: "Cajamarca/Chota/San Juan de Licupis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060618",
    TUbiDes: "Cajamarca/Chota/Choropampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060619",
    TUbiDes: "Cajamarca/Chota/Chalamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060701",
    TUbiDes: "Cajamarca/Hualgayoc/Bambamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060702",
    TUbiDes: "Cajamarca/Hualgayoc/Chugur",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060703",
    TUbiDes: "Cajamarca/Hualgayoc/Hualgayoc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060801",
    TUbiDes: "Cajamarca/Jaen/Jaen",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060802",
    TUbiDes: "Cajamarca/Jaen/Bellavista",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060803",
    TUbiDes: "Cajamarca/Jaen/Colasay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060804",
    TUbiDes: "Cajamarca/Jaen/Chontali",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060805",
    TUbiDes: "Cajamarca/Jaen/Pomahuaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060806",
    TUbiDes: "Cajamarca/Jaen/Pucara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060807",
    TUbiDes: "Cajamarca/Jaen/Sallique",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060808",
    TUbiDes: "Cajamarca/Jaen/San Felipe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060809",
    TUbiDes: "Cajamarca/Jaen/San Jose del Alto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060810",
    TUbiDes: "Cajamarca/Jaen/San taRosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060811",
    TUbiDes: "Cajamarca/Jaen/Las Pirias",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060812",
    TUbiDes: "Cajamarca/Jaen/Huabal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060901",
    TUbiDes: "Cajamarca/SantaCruz/Santa Cruz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060902",
    TUbiDes: "Cajamarca/SantaCruz/Catache",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060903",
    TUbiDes: "Cajamarca/SantaCruz/Chancaybaños",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060904",
    TUbiDes: "Cajamarca/SantaCruz/La Esperanza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060905",
    TUbiDes: "Cajamarca/SantaCruz/Ninabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060906",
    TUbiDes: "Cajamarca/SantaCruz/Pulan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060907",
    TUbiDes: "Cajamarca/SantaCruz/Sexi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060908",
    TUbiDes: "Cajamarca/SantaCruz/Uticyacu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060909",
    TUbiDes: "Cajamarca/SantaCruz/Yauyucan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060910",
    TUbiDes: "Cajamarca/SantaCruz/Andabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "060911",
    TUbiDes: "Cajamarca/SantaCruz/Saucepampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061001",
    TUbiDes: "Cajamarca/San Miguel/San Miguel",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061002",
    TUbiDes: "Cajamarca/San Miguel/Calquis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061003",
    TUbiDes: "Cajamarca/San Miguel/La Florida",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061004",
    TUbiDes: "Cajamarca/San Miguel/Llapa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061005",
    TUbiDes: "Cajamarca/San Miguel/Nanchoc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061006",
    TUbiDes: "Cajamarca/San Miguel/Niepos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061007",
    TUbiDes: "Cajamarca/San Miguel/San Gregorio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061008",
    TUbiDes: "Cajamarca/San Miguel/San Silvestre de Cochan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061009",
    TUbiDes: "Cajamarca/San Miguel/El Prado",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061010",
    TUbiDes: "Cajamarca/San Miguel/Union Agua Blanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061011",
    TUbiDes: "Cajamarca/San Miguel/Tongod",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061012",
    TUbiDes: "Cajamarca/San Miguel/Catilluc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061013",
    TUbiDes: "Cajamarca/San Miguel/Bolivar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061101",
    TUbiDes: "Cajamarca/San Ignacio/San Ignacio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061102",
    TUbiDes: "Cajamarca/San Ignacio/Chirinos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061103",
    TUbiDes: "Cajamarca/San Ignacio/Huarango",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061104",
    TUbiDes: "Cajamarca/San Ignacio/Namballe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061105",
    TUbiDes: "Cajamarca/San Ignacio/La Coipa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061106",
    TUbiDes: "Cajamarca/San Ignacio/San Jose de Lourdes",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061107",
    TUbiDes: "Cajamarca/San Ignacio/Tabaconas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061201",
    TUbiDes: "Cajamarca/San Marcos/Pedro Galvez",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061202",
    TUbiDes: "Cajamarca/San Marcos/Ichocan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061203",
    TUbiDes: "Cajamarca/San Marcos/Gregorio Pita",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061204",
    TUbiDes: "Cajamarca/San Marcos/Jose Manuel Quiroz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061205",
    TUbiDes: "Cajamarca/San Marcos/Eduardo Villanueva",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061206",
    TUbiDes: "Cajamarca/San Marcos/Jose Sabogal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061207",
    TUbiDes: "Cajamarca/San Marcos/Chancay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061301",
    TUbiDes: "Cajamarca/San Pablo/San Pablo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061302",
    TUbiDes: "Cajamarca/San Pablo/San Bernardino",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061303",
    TUbiDes: "Cajamarca/San Pablo/San Luis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "061304",
    TUbiDes: "Cajamarca/San Pablo/Tumbaden",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070101",
    TUbiDes: "Cusco/Cusco/Cusco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070102",
    TUbiDes: "Cusco/Cusco/Ccorca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070103",
    TUbiDes: "Cusco/Cusco/Poroy",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070104",
    TUbiDes: "Cusco/Cusco/San Jeronimo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070105",
    TUbiDes: "Cusco/Cusco/San Sebastian",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070106",
    TUbiDes: "Cusco/Cusco/Santiago",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070107",
    TUbiDes: "Cusco/Cusco/Saylla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070108",
    TUbiDes: "Cusco/Cusco/Wanchaq",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070201",
    TUbiDes: "Cusco/Acomayo/Acomayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070202",
    TUbiDes: "Cusco/Acomayo/Acopia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070203",
    TUbiDes: "Cusco/Acomayo/Acos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070204",
    TUbiDes: "Cusco/Acomayo/Pomacanchi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070205",
    TUbiDes: "Cusco/Acomayo/Rondocan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070206",
    TUbiDes: "Cusco/Acomayo/Sangarara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070207",
    TUbiDes: "Cusco/Acomayo/Mosoc Llacta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070301",
    TUbiDes: "Cusco/Anta/Anta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070302",
    TUbiDes: "Cusco/Anta/Chinchaypujio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070303",
    TUbiDes: "Cusco/Anta/Huarocondo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070304",
    TUbiDes: "Cusco/Anta/Limatambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070305",
    TUbiDes: "Cusco/Anta/Mollepata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070306",
    TUbiDes: "Cusco/Anta/Pucyura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070307",
    TUbiDes: "Cusco/Anta/Zurite",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070308",
    TUbiDes: "Cusco/Anta/Cachimayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070309",
    TUbiDes: "Cusco/Anta/Ancahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070401",
    TUbiDes: "Cusco/Calca/Calca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070402",
    TUbiDes: "Cusco/Calca/Coya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070403",
    TUbiDes: "Cusco/Calca/Lamay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070404",
    TUbiDes: "Cusco/Calca/Lares",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070405",
    TUbiDes: "Cusco/Calca/Pisac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070406",
    TUbiDes: "Cusco/Calca/San Salvador",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070407",
    TUbiDes: "Cusco/Calca/Taray",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070408",
    TUbiDes: "Cusco/Calca/Yanatile",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070501",
    TUbiDes: "Cusco/Canas/Yanaoca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070502",
    TUbiDes: "Cusco/Canas/Checca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070503",
    TUbiDes: "Cusco/Canas/Kunturkanki",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070504",
    TUbiDes: "Cusco/Canas/Langui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070505",
    TUbiDes: "Cusco/Canas/Layo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070506",
    TUbiDes: "Cusco/Canas/Pampamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070507",
    TUbiDes: "Cusco/Canas/Quehue",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070508",
    TUbiDes: "Cusco/Canas/Tupac Amaru",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070601",
    TUbiDes: "Cusco/Canchis/Sicuani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070602",
    TUbiDes: "Cusco/Canchis/Combapata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070603",
    TUbiDes: "Cusco/Canchis/Checacupe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070604",
    TUbiDes: "Cusco/Canchis/Marangani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070605",
    TUbiDes: "Cusco/Canchis/Pitumarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070606",
    TUbiDes: "Cusco/Canchis/San Pablo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070607",
    TUbiDes: "Cusco/Canchis/San Pedro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070608",
    TUbiDes: "Cusco/Canchis/Tinta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070701",
    TUbiDes: "Cusco/Chumbivilcas/Santo Tomas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070702",
    TUbiDes: "Cusco/Chumbivilcas/Capacmarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070703",
    TUbiDes: "Cusco/Chumbivilcas/Colquemarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070704",
    TUbiDes: "Cusco/Chumbivilcas/Chamaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070705",
    TUbiDes: "Cusco/Chumbivilcas/Livitaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070706",
    TUbiDes: "Cusco/Chumbivilcas/Llusco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070707",
    TUbiDes: "Cusco/Chumbivilcas/Quiñota",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070708",
    TUbiDes: "Cusco/Chumbivilcas/Velille",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070801",
    TUbiDes: "Cusco/Espinar/Espinar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070802",
    TUbiDes: "Cusco/Espinar/Condoroma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070803",
    TUbiDes: "Cusco/Espinar/Coporaque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070804",
    TUbiDes: "Cusco/Espinar/Ocoruro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070805",
    TUbiDes: "Cusco/Espinar/Pallpata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070806",
    TUbiDes: "Cusco/Espinar/Pichigua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070807",
    TUbiDes: "Cusco/Espinar/Suyckutambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070808",
    TUbiDes: "Cusco/Espinar/AltoPichigua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070901",
    TUbiDes: "Cusco/La Convencion/Santa Ana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070902",
    TUbiDes: "Cusco/La Convencion/Echarate",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070903",
    TUbiDes: "Cusco/La Convencion/Huayopata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070904",
    TUbiDes: "Cusco/La Convencion/Maranura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070905",
    TUbiDes: "Cusco/La Convencion/Ocobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070906",
    TUbiDes: "Cusco/La Convencion/Santa Teresa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070907",
    TUbiDes: "Cusco/La Convencion/Vilcabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070908",
    TUbiDes: "Cusco/La Convencion/Quellouno",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070909",
    TUbiDes: "Cusco/La Convencion/Kimbiri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "070910",
    TUbiDes: "Cusco/La Convencion/Pichari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071001",
    TUbiDes: "Cusco/Paruro/Paruro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071002",
    TUbiDes: "Cusco/Paruro/Accha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071003",
    TUbiDes: "Cusco/Paruro/Ccapi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071004",
    TUbiDes: "Cusco/Paruro/Colcha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071005",
    TUbiDes: "Cusco/Paruro/Huanoquite",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071006",
    TUbiDes: "Cusco/Paruro/Omacha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071007",
    TUbiDes: "Cusco/Paruro/Yaurisque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071008",
    TUbiDes: "Cusco/Paruro/Paccaritambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071009",
    TUbiDes: "Cusco/Paruro/Pillpinto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071101",
    TUbiDes: "Cusco/Paucartambo/Paucartambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071102",
    TUbiDes: "Cusco/Paucartambo/Caicay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071103",
    TUbiDes: "Cusco/Paucartambo/Colquepata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071104",
    TUbiDes: "Cusco/Paucartambo/Challabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071105",
    TUbiDes: "Cusco/Paucartambo/Kosñipata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071106",
    TUbiDes: "Cusco/Paucartambo/Huancarani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071201",
    TUbiDes: "Cusco/Quispicanchi/Urcos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071202",
    TUbiDes: "Cusco/Quispicanchi/Andahuaylillas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071203",
    TUbiDes: "Cusco/Quispicanchi/Camanti",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071204",
    TUbiDes: "Cusco/Quispicanchi/Ccarhuayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071205",
    TUbiDes: "Cusco/Quispicanchi/Ccatca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071206",
    TUbiDes: "Cusco/Quispicanchi/Cusipata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071207",
    TUbiDes: "Cusco/Quispicanchi/Huaro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071208",
    TUbiDes: "Cusco/Quispicanchi/Lucre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071209",
    TUbiDes: "Cusco/Quispicanchi/Marcapata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071210",
    TUbiDes: "Cusco/Quispicanchi/Ocongate",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071211",
    TUbiDes: "Cusco/Quispicanchi/Oropesa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071212",
    TUbiDes: "Cusco/Quispicanchi/Quiquijana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071301",
    TUbiDes: "Cusco/Urubamba/Urubamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071302",
    TUbiDes: "Cusco/Urubamba/Chinchero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071303",
    TUbiDes: "Cusco/Urubamba/Huayllabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071304",
    TUbiDes: "Cusco/Urubamba/Machupicchu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071305",
    TUbiDes: "Cusco/Urubamba/Maras",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071306",
    TUbiDes: "Cusco/Urubamba/Ollantaytambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "071307",
    TUbiDes: "Cusco/Urubamba/Yucay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080101",
    TUbiDes: "Huancavelica/Huancavelica/Huancavelica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080102",
    TUbiDes: "Huancavelica/Huancavelica/Acobambilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080103",
    TUbiDes: "Huancavelica/Huancavelica/Acoria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080104",
    TUbiDes: "Huancavelica/Huancavelica/Conayca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080105",
    TUbiDes: "Huancavelica/Huancavelica/Cuenca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080106",
    TUbiDes: "Huancavelica/Huancavelica/Huachocolpa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080108",
    TUbiDes: "Huancavelica/Huancavelica/Huayllahuara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080109",
    TUbiDes: "Huancavelica/Huancavelica/Izcuchaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080110",
    TUbiDes: "Huancavelica/Huancavelica/Laria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080111",
    TUbiDes: "Huancavelica/Huancavelica/Manta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080112",
    TUbiDes: "Huancavelica/Huancavelica/Mariscal Caceres",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080113",
    TUbiDes: "Huancavelica/Huancavelica/Moya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080114",
    TUbiDes: "Huancavelica/Huancavelica/Nuevo Occoro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080115",
    TUbiDes: "Huancavelica/Huancavelica/Palca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080116",
    TUbiDes: "Huancavelica/Huancavelica/Pilchaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080117",
    TUbiDes: "Huancavelica/Huancavelica/Vilca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080118",
    TUbiDes: "Huancavelica/Huancavelica/Yauli",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080119",
    TUbiDes: "Huancavelica/Huancavelica/Ascension",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080120",
    TUbiDes: "Huancavelica/Huancavelica/Huando",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080201",
    TUbiDes: "Huancavelica/Acobamba/Acobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080202",
    TUbiDes: "Huancavelica/Acobamba/Anta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080203",
    TUbiDes: "Huancavelica/Acobamba/Andabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080204",
    TUbiDes: "Huancavelica/Acobamba/Caja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080205",
    TUbiDes: "Huancavelica/Acobamba/Marcas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080206",
    TUbiDes: "Huancavelica/Acobamba/Paucara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080207",
    TUbiDes: "Huancavelica/Acobamba/Pomacocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080208",
    TUbiDes: "Huancavelica/Acobamba/Rosario",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080301",
    TUbiDes: "Huancavelica/Angaraes/Lircay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080302",
    TUbiDes: "Huancavelica/Angaraes/Anchonga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080303",
    TUbiDes: "Huancavelica/Angaraes/Callanmarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080304",
    TUbiDes: "Huancavelica/Angaraes/Congalla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080305",
    TUbiDes: "Huancavelica/Angaraes/Chincho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080306",
    TUbiDes: "Huancavelica/Angaraes/Huayllay Grande",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080307",
    TUbiDes: "Huancavelica/Angaraes/Huanca Huanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080308",
    TUbiDes: "Huancavelica/Angaraes/Julcamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080309",
    TUbiDes: "Huancavelica/Angaraes/San Antonio de Antaparco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080310",
    TUbiDes: "Huancavelica/Angaraes/Santo Tomas de Pata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080311",
    TUbiDes: "Huancavelica/Angaraes/Secclla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080312",
    TUbiDes: "Huancavelica/Angaraes/Ccochaccasa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080401",
    TUbiDes: "Huancavelica/Castrovirreyna/Castrovirreyna",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080402",
    TUbiDes: "Huancavelica/Castrovirreyna/Arma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080403",
    TUbiDes: "Huancavelica/Castrovirreyna/Aurahua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080405",
    TUbiDes: "Huancavelica/Castrovirreyna/Capillas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080406",
    TUbiDes: "Huancavelica/Castrovirreyna/Cocas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080408",
    TUbiDes: "Huancavelica/Castrovirreyna/Chupamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080409",
    TUbiDes: "Huancavelica/Castrovirreyna/Huachos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080410",
    TUbiDes: "Huancavelica/Castrovirreyna/Huamatambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080414",
    TUbiDes: "Huancavelica/Castrovirreyna/Mollepampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080422",
    TUbiDes: "Huancavelica/Castrovirreyna/San Juan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080427",
    TUbiDes: "Huancavelica/Castrovirreyna/Tantara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080428",
    TUbiDes: "Huancavelica/Castrovirreyna/Ticrapo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080429",
    TUbiDes: "Huancavelica/Castrovirreyna/Santa Ana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080501",
    TUbiDes: "Huancavelica/Tayacaja/Pampas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080502",
    TUbiDes: "Huancavelica/Tayacaja/Acostambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080503",
    TUbiDes: "Huancavelica/Tayacaja/Acraquia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080504",
    TUbiDes: "Huancavelica/Tayacaja/Ahuaycha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080506",
    TUbiDes: "Huancavelica/Tayacaja/Colcabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080509",
    TUbiDes: "Huancavelica/Tayacaja/Daniel Hernandez",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080511",
    TUbiDes: "Huancavelica/Tayacaja/Huachocolpa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080512",
    TUbiDes: "Huancavelica/Tayacaja/Huaribamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080515",
    TUbiDes: "Huancavelica/Tayacaja/Ñahuimpuquio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080517",
    TUbiDes: "Huancavelica/Tayacaja/Pazos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080518",
    TUbiDes: "Huancavelica/Tayacaja/Quishuar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080519",
    TUbiDes: "Huancavelica/Tayacaja/Salcabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080520",
    TUbiDes: "Huancavelica/Tayacaja/San Marcos de Rocchac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080523",
    TUbiDes: "Huancavelica/Tayacaja/Surcubamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080525",
    TUbiDes: "Huancavelica/Tayacaja/Tintay Puncu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080526",
    TUbiDes: "Huancavelica/Tayacaja/Salcahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080601",
    TUbiDes: "Huancavelica/Huaytara/Ayavi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080602",
    TUbiDes: "Huancavelica/Huaytara/Cordova",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080603",
    TUbiDes: "Huancavelica/Huaytara/Huayacundo Arma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080604",
    TUbiDes: "Huancavelica/Huaytara/Huaytara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080605",
    TUbiDes: "Huancavelica/Huaytara/Laramarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080606",
    TUbiDes: "Huancavelica/Huaytara/Ocoyo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080607",
    TUbiDes: "Huancavelica/Huaytara/Pilpichaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080608",
    TUbiDes: "Huancavelica/Huaytara/Querco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080609",
    TUbiDes: "Huancavelica/Huaytara/Quito Arma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080610",
    TUbiDes: "Huancavelica/Huaytara/San Antonio de Cusicancha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080611",
    TUbiDes: "Huancavelica/Huaytara/San Francisco de Sangayaico",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080612",
    TUbiDes: "Huancavelica/Huaytara/San Isidro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080613",
    TUbiDes: "Huancavelica/Huaytara/Santiago de Chocorvos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080614",
    TUbiDes: "Huancavelica/Huaytara/Santiago de Quirahuara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080615",
    TUbiDes: "Huancavelica/Huaytara/Santo Domingo de Capillas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080616",
    TUbiDes: "Huancavelica/Huaytara/Tambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080701",
    TUbiDes: "Huancavelica/Churcampa/Churcampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080702",
    TUbiDes: "Huancavelica/Churcampa/Anco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080703",
    TUbiDes: "Huancavelica/Churcampa/Chinchihuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080704",
    TUbiDes: "Huancavelica/Churcampa/El Carmen",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080705",
    TUbiDes: "Huancavelica/Churcampa/La Merced",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080706",
    TUbiDes: "Huancavelica/Churcampa/Locroja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080707",
    TUbiDes: "Huancavelica/Churcampa/Paucarbamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080708",
    TUbiDes: "Huancavelica/Churcampa/San Miguel de Mayocc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080709",
    TUbiDes: "Huancavelica/Churcampa/San Pedro de Coris",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080710",
    TUbiDes: "Huancavelica/Churcampa/Pachamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "080711",
    TUbiDes: "Huancavelica/Churcampa/Cosme",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090101",
    TUbiDes: "Huanuco/Huanuco/Huanuco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090102",
    TUbiDes: "Huanuco/Huanuco/Chinchao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090103",
    TUbiDes: "Huanuco/Huanuco/Churubamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090104",
    TUbiDes: "Huanuco/Huanuco/Margos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090105",
    TUbiDes: "Huanuco/Huanuco/Quisqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090106",
    TUbiDes: "Huanuco/Huanuco/San Francisco de Cayran",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090107",
    TUbiDes: "Huanuco/Huanuco/San Pedro de Chaulan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090108",
    TUbiDes: "Huanuco/Huanuco/Santa Maria del Valle",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090109",
    TUbiDes: "Huanuco/Huanuco/Yarumayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090110",
    TUbiDes: "Huanuco/Huanuco/Amarilis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090111",
    TUbiDes: "Huanuco/Huanuco/Pillco Marca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090112",
    TUbiDes: "Huanuco/Huanuco/Yacus",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090201",
    TUbiDes: "Huanuco/Ambo/Ambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090202",
    TUbiDes: "Huanuco/Ambo/Cayna",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090203",
    TUbiDes: "Huanuco/Ambo/Colpas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090204",
    TUbiDes: "Huanuco/Ambo/Conchamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090205",
    TUbiDes: "Huanuco/Ambo/Huacar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090206",
    TUbiDes: "Huanuco/Ambo/San Francisco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090207",
    TUbiDes: "Huanuco/Ambo/San Rafael",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090208",
    TUbiDes: "Huanuco/Ambo/Tomay Kichwa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090301",
    TUbiDes: "Huanuco/Dos de Mayo/La Union",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090307",
    TUbiDes: "Huanuco/Dos de Mayo/Chuquis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090312",
    TUbiDes: "Huanuco/Dos de Mayo/Marias",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090314",
    TUbiDes: "Huanuco/Dos de Mayo/Pachas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090316",
    TUbiDes: "Huanuco/Dos de Mayo/Quivilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090317",
    TUbiDes: "Huanuco/Dos de Mayo/Ripan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090321",
    TUbiDes: "Huanuco/Dos de Mayo/Shunqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090322",
    TUbiDes: "Huanuco/Dos de Mayo/Sillapata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090323",
    TUbiDes: "Huanuco/Dos de Mayo/Yanas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090401",
    TUbiDes: "Huanuco/Huamalies/Llata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090402",
    TUbiDes: "Huanuco/Huamalies/Arancay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090403",
    TUbiDes: "Huanuco/Huamalies/Chavin de Pariarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090404",
    TUbiDes: "Huanuco/Huamalies/Jacas Grande",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090405",
    TUbiDes: "Huanuco/Huamalies/Jircan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090406",
    TUbiDes: "Huanuco/Huamalies/Miraflores",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090407",
    TUbiDes: "Huanuco/Huamalies/Monzon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090408",
    TUbiDes: "Huanuco/Huamalies/Punchao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090409",
    TUbiDes: "Huanuco/Huamalies/Puños",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090410",
    TUbiDes: "Huanuco/Huamalies/Singa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090411",
    TUbiDes: "Huanuco/Huamalies/Tantamayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090501",
    TUbiDes: "Huanuco/Marañon/Huacrachuco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090502",
    TUbiDes: "Huanuco/Marañon/Cholon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090505",
    TUbiDes: "Huanuco/Marañon/San Buenaventura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090601",
    TUbiDes: "Huanuco/Leoncio Prado/Rupa Rupa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090602",
    TUbiDes: "Huanuco/Leoncio Prado/Daniel Alomias Robles",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090603",
    TUbiDes: "Huanuco/Leoncio Prado/Hermilio Valdizan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090604",
    TUbiDes: "Huanuco/Leoncio Prado/Luyando",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090605",
    TUbiDes: "Huanuco/Leoncio Prado/Mariano Damaso Beraun",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090606",
    TUbiDes: "Huanuco/Leoncio Prado/Jose Crespo y Castillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090701",
    TUbiDes: "Huanuco/Pachitea/Panao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090702",
    TUbiDes: "Huanuco/Pachitea/Chaglla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090704",
    TUbiDes: "Huanuco/Pachitea/Molino",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090706",
    TUbiDes: "Huanuco/Pachitea/Umari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090801",
    TUbiDes: "Huanuco/Puerto Inca/Honoria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090802",
    TUbiDes: "Huanuco/Puerto Inca/Puerto Inca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090803",
    TUbiDes: "Huanuco/Puerto Inca/Codo del Pozuzo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090804",
    TUbiDes: "Huanuco/Puerto Inca/Tournavista",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090805",
    TUbiDes: "Huanuco/Puerto Inca/Yuyapichis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090901",
    TUbiDes: "Huanuco/Huacaybamba/Huacaybamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090902",
    TUbiDes: "Huanuco/Huacaybamba/Pinra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090903",
    TUbiDes: "Huanuco/Huacaybamba/Canchabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "090904",
    TUbiDes: "Huanuco/Huacaybamba/Cochabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091001",
    TUbiDes: "Huanuco/Lauricocha/Jesus",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091002",
    TUbiDes: "Huanuco/Lauricocha/Baños",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091003",
    TUbiDes: "Huanuco/Lauricocha/San Francisco de Asis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091004",
    TUbiDes: "Huanuco/Lauricocha/Queropalca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091005",
    TUbiDes: "Huanuco/Lauricocha/San Miguel de Cauri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091006",
    TUbiDes: "Huanuco/Lauricocha/Rondos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091007",
    TUbiDes: "Huanuco/Lauricocha/Jivia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091101",
    TUbiDes: "Huanuco/Yarowilca/Chavinillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091102",
    TUbiDes: "Huanuco/Yarowilca/Aparicio Pomares",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091103",
    TUbiDes: "Huanuco/Yarowilca/Cahuac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091104",
    TUbiDes: "Huanuco/Yarowilca/Chacabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091105",
    TUbiDes: "Huanuco/Yarowilca/Jacas Chico",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091106",
    TUbiDes: "Huanuco/Yarowilca/Obas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091107",
    TUbiDes: "Huanuco/Yarowilca/Pampamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "091108",
    TUbiDes: "Huanuco/Yarowilca/Choras",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100101",
    TUbiDes: "Ica/Ica/Ica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100102",
    TUbiDes: "Ica/Ica/La Tinguiña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100103",
    TUbiDes: "Ica/Ica/Los Aquijes",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100104",
    TUbiDes: "Ica/Ica/Parcona",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100105",
    TUbiDes: "Ica/Ica/Pueblo Nuevo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100106",
    TUbiDes: "Ica/Ica/Salas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100107",
    TUbiDes: "Ica/Ica/San Jose de los Molinos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100108",
    TUbiDes: "Ica/Ica/San Juan Bautista",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100109",
    TUbiDes: "Ica/Ica/Santiago",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100110",
    TUbiDes: "Ica/Ica/Subtanjalla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100111",
    TUbiDes: "Ica/Ica/Yauca del Rosario",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100112",
    TUbiDes: "Ica/Ica/Tate",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100113",
    TUbiDes: "Ica/Ica/Pachacutec",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100114",
    TUbiDes: "Ica/Ica/Ocucaje",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100201",
    TUbiDes: "Ica/Chincha/Chincha Alta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100202",
    TUbiDes: "Ica/Chincha/Chavin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100203",
    TUbiDes: "Ica/Chincha/Chincha Baja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100204",
    TUbiDes: "Ica/Chincha/El Carmen",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100205",
    TUbiDes: "Ica/Chincha/Grocio Prado",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100206",
    TUbiDes: "Ica/Chincha/San Pedro de Huacarpana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100207",
    TUbiDes: "Ica/Chincha/Sunampe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100208",
    TUbiDes: "Ica/Chincha/Tambo de Mora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100209",
    TUbiDes: "Ica/Chincha/Alto Laran",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100210",
    TUbiDes: "Ica/Chincha/Pueblo Nuevo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100211",
    TUbiDes: "Ica/Chincha/San Juan de Yanac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100301",
    TUbiDes: "Ica/Nazca/Nazca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100302",
    TUbiDes: "Ica/Nazca/Changuillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100303",
    TUbiDes: "Ica/Nazca/El Ingenio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100304",
    TUbiDes: "Ica/Nazca/Marcona",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100305",
    TUbiDes: "Ica/Nazca/Vista Alegre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100401",
    TUbiDes: "Ica/Pisco/Pisco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100402",
    TUbiDes: "Ica/Pisco/Huancano",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100403",
    TUbiDes: "Ica/Pisco/Humay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100404",
    TUbiDes: "Ica/Pisco/Independencia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100405",
    TUbiDes: "Ica/Pisco/Paracas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100406",
    TUbiDes: "Ica/Pisco/San Andres",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100407",
    TUbiDes: "Ica/Pisco/San Clemente",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100408",
    TUbiDes: "Ica/Pisco/Tupac Amaru Inca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100501",
    TUbiDes: "Ica/Palpa/Palpa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100502",
    TUbiDes: "Ica/Palpa/Llipata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100503",
    TUbiDes: "Ica/Palpa/Rio Grande",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100504",
    TUbiDes: "Ica/Palpa/Santa Cruz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "100505",
    TUbiDes: "Ica/Palpa/Tibillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110101",
    TUbiDes: "Junin/Huancayo/Huancayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110103",
    TUbiDes: "Junin/Huancayo/Carhuacallanga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110104",
    TUbiDes: "Junin/Huancayo/Colca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110105",
    TUbiDes: "Junin/Huancayo/Cullhuas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110106",
    TUbiDes: "Junin/Huancayo/Chacapampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110107",
    TUbiDes: "Junin/Huancayo/Chicche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110108",
    TUbiDes: "Junin/Huancayo/Chilca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110109",
    TUbiDes: "Junin/Huancayo/Chongos Alto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110112",
    TUbiDes: "Junin/Huancayo/Chupuro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110113",
    TUbiDes: "Junin/Huancayo/El Tambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110114",
    TUbiDes: "Junin/Huancayo/Huacrapuquio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110116",
    TUbiDes: "Junin/Huancayo/Hualhuas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110118",
    TUbiDes: "Junin/Huancayo/Huancan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110119",
    TUbiDes: "Junin/Huancayo/Huasicancha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110120",
    TUbiDes: "Junin/Huancayo/Huayucachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110121",
    TUbiDes: "Junin/Huancayo/Ingenio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110122",
    TUbiDes: "Junin/Huancayo/Pariahuanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110123",
    TUbiDes: "Junin/Huancayo/Pilcomayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110124",
    TUbiDes: "Junin/Huancayo/Pucara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110125",
    TUbiDes: "Junin/Huancayo/Quichuay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110126",
    TUbiDes: "Junin/Huancayo/Quilcas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110127",
    TUbiDes: "Junin/Huancayo/San Agustin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110128",
    TUbiDes: "Junin/Huancayo/San Jeronimo de Tunan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110131",
    TUbiDes: "Junin/Huancayo/Santo Domingo de Acobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110132",
    TUbiDes: "Junin/Huancayo/Saño",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110133",
    TUbiDes: "Junin/Huancayo/Sapallanga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110134",
    TUbiDes: "Junin/Huancayo/Sicaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110136",
    TUbiDes: "Junin/Huancayo/Viques",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110201",
    TUbiDes: "Junin/Concepcion/Concepcion",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110202",
    TUbiDes: "Junin/Concepcion/Aco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110203",
    TUbiDes: "Junin/Concepcion/Andamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110204",
    TUbiDes: "Junin/Concepcion/Comas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110205",
    TUbiDes: "Junin/Concepcion/Cochas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110206",
    TUbiDes: "Junin/Concepcion/Chambara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110207",
    TUbiDes: "Junin/Concepcion/Heroinas Toledo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110208",
    TUbiDes: "Junin/Concepcion/Manzanares",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110209",
    TUbiDes: "Junin/Concepcion/Mariscal Castilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110210",
    TUbiDes: "Junin/Concepcion/Matahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110211",
    TUbiDes: "Junin/Concepcion/Mito",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110212",
    TUbiDes: "Junin/Concepcion/Nueve de Julio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110213",
    TUbiDes: "Junin/Concepcion/Orcotuna",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110214",
    TUbiDes: "Junin/Concepcion/Santa Rosa de Ocopa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110215",
    TUbiDes: "Junin/Concepcion/San Jose de Quero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110301",
    TUbiDes: "Junin/Jauja/Jauja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110302",
    TUbiDes: "Junin/Jauja/Acolla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110303",
    TUbiDes: "Junin/Jauja/Apata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110304",
    TUbiDes: "Junin/Jauja/Ataura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110305",
    TUbiDes: "Junin/Jauja/Canchayllo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110306",
    TUbiDes: "Junin/Jauja/El Mantaro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110307",
    TUbiDes: "Junin/Jauja/Huamali",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110308",
    TUbiDes: "Junin/Jauja/Huaripampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110309",
    TUbiDes: "Junin/Jauja/Huertas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110310",
    TUbiDes: "Junin/Jauja/Janjaillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110311",
    TUbiDes: "Junin/Jauja/Julcan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110312",
    TUbiDes: "Junin/Jauja/Leonor Ordoñez",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110313",
    TUbiDes: "Junin/Jauja/Llocllapampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110314",
    TUbiDes: "Junin/Jauja/Marco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110315",
    TUbiDes: "Junin/Jauja/Masma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110316",
    TUbiDes: "Junin/Jauja/Molinos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110317",
    TUbiDes: "Junin/Jauja/Monobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110318",
    TUbiDes: "Junin/Jauja/Muqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110319",
    TUbiDes: "Junin/Jauja/Muquiyauyo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110320",
    TUbiDes: "Junin/Jauja/Paca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110321",
    TUbiDes: "Junin/Jauja/Paccha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110322",
    TUbiDes: "Junin/Jauja/Pancan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110323",
    TUbiDes: "Junin/Jauja/Parco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110324",
    TUbiDes: "Junin/Jauja/Pomacancha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110325",
    TUbiDes: "Junin/Jauja/Ricran",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110326",
    TUbiDes: "Junin/Jauja/San Lorenzo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110327",
    TUbiDes: "Junin/Jauja/San Pedro de Chunan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110328",
    TUbiDes: "Junin/Jauja/Sincos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110329",
    TUbiDes: "Junin/Jauja/Tunan Marca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110330",
    TUbiDes: "Junin/Jauja/Yauli",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110331",
    TUbiDes: "Junin/Jauja/Curicaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110332",
    TUbiDes: "Junin/Jauja/Masma Chicche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110333",
    TUbiDes: "Junin/Jauja/Sausa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110334",
    TUbiDes: "Junin/Jauja/Yauyos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110401",
    TUbiDes: "Junin/Junin/Junin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110402",
    TUbiDes: "Junin/Junin/Carhuamayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110403",
    TUbiDes: "Junin/Junin/Ondores",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110404",
    TUbiDes: "Junin/Junin/Ulcumayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110501",
    TUbiDes: "Junin/Tarma/Tarma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110502",
    TUbiDes: "Junin/Tarma/Acobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110503",
    TUbiDes: "Junin/Tarma/Huaricolca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110504",
    TUbiDes: "Junin/Tarma/Huasahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110505",
    TUbiDes: "Junin/Tarma/La Union",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110506",
    TUbiDes: "Junin/Tarma/Palca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110507",
    TUbiDes: "Junin/Tarma/Palcamayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110508",
    TUbiDes: "Junin/Tarma/San Pedro de Cajas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110509",
    TUbiDes: "Junin/Tarma/Tapo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110601",
    TUbiDes: "Junin/Yauli/La Oroya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110602",
    TUbiDes: "Junin/Yauli/Chacapalpa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110603",
    TUbiDes: "Junin/Yauli/Huay Huay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110604",
    TUbiDes: "Junin/Yauli/Marcapomacocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110605",
    TUbiDes: "Junin/Yauli/Morococha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110606",
    TUbiDes: "Junin/Yauli/Paccha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110607",
    TUbiDes: "Junin/Yauli/Santa Barbara de Carhuacayan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110608",
    TUbiDes: "Junin/Yauli/Suitucancha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110609",
    TUbiDes: "Junin/Yauli/Yauli",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110610",
    TUbiDes: "Junin/Yauli/Santa Rosa de Sacco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110701",
    TUbiDes: "Junin/Satipo/Satipo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110702",
    TUbiDes: "Junin/Satipo/Coviriali",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110703",
    TUbiDes: "Junin/Satipo/Llaylla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110704",
    TUbiDes: "Junin/Satipo/Mazamari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110705",
    TUbiDes: "Junin/Satipo/Pampa Hermosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110706",
    TUbiDes: "Junin/Satipo/Pangoa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110707",
    TUbiDes: "Junin/Satipo/Rio Negro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110708",
    TUbiDes: "Junin/Satipo/Rio Tambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110801",
    TUbiDes: "Junin/Chanchamayo/Chanchamayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110802",
    TUbiDes: "Junin/Chanchamayo/San Ramon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110803",
    TUbiDes: "Junin/Chanchamayo/Vitoc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110804",
    TUbiDes: "Junin/Chanchamayo/San Luis de Shuaro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110805",
    TUbiDes: "Junin/Chanchamayo/Pichanaqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110806",
    TUbiDes: "Junin/Chanchamayo/Perene",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110901",
    TUbiDes: "Junin/Chupaca/Chupaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110902",
    TUbiDes: "Junin/Chupaca/Ahuac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110903",
    TUbiDes: "Junin/Chupaca/Chongos Bajo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110904",
    TUbiDes: "Junin/Chupaca/Huachac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110905",
    TUbiDes: "Junin/Chupaca/Huamancaca Chico",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110906",
    TUbiDes: "Junin/Chupaca/San Juan de Yscos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110907",
    TUbiDes: "Junin/Chupaca/San Juan de Jarpa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110908",
    TUbiDes: "Junin/Chupaca/Tres de Diciembre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "110909",
    TUbiDes: "Junin/Chupaca/Yanacancha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120101",
    TUbiDes: "La Libertad/Trujillo/Trujillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120102",
    TUbiDes: "La Libertad/Trujillo/Huanchaco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120103",
    TUbiDes: "La Libertad/Trujillo/Laredo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120104",
    TUbiDes: "La Libertad/Trujillo/Moche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120105",
    TUbiDes: "La Libertad/Trujillo/Salaverry",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120106",
    TUbiDes: "La Libertad/Trujillo/Simbal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120107",
    TUbiDes: "La Libertad/Trujillo/Victor Larco Herrera",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120109",
    TUbiDes: "La Libertad/Trujillo/Poroto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120110",
    TUbiDes: "La Libertad/Trujillo/El Porvenir",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120111",
    TUbiDes: "La Libertad/Trujillo/La Esperanza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120112",
    TUbiDes: "La Libertad/Trujillo/Florencia de Mora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120201",
    TUbiDes: "La Libertad/Bolivar/Bolivar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120202",
    TUbiDes: "La Libertad/Bolivar/Bambamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120203",
    TUbiDes: "La Libertad/Bolivar/Condormarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120204",
    TUbiDes: "La Libertad/Bolivar/Longotea",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120205",
    TUbiDes: "La Libertad/Bolivar/Ucuncha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120206",
    TUbiDes: "La Libertad/Bolivar/Uchumarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120301",
    TUbiDes: "La Libertad/Sanchez Carrion/Huamachuco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120302",
    TUbiDes: "La Libertad/Sanchez Carrion/Cochorco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120303",
    TUbiDes: "La Libertad/Sanchez Carrion/Curgos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120304",
    TUbiDes: "La Libertad/Sanchez Carrion/Chugay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120305",
    TUbiDes: "La Libertad/Sanchez Carrion/Marcabal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120306",
    TUbiDes: "La Libertad/Sanchez Carrion/Sanagoran",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120307",
    TUbiDes: "La Libertad/Sanchez Carrion/Sarin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120308",
    TUbiDes: "La Libertad/Sanchez Carrion/Sartimbamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120401",
    TUbiDes: "La Libertad/Otuzco/Otuzco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120402",
    TUbiDes: "La Libertad/Otuzco/Agallpampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120403",
    TUbiDes: "La Libertad/Otuzco/Charat",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120404",
    TUbiDes: "La Libertad/Otuzco/Huaranchal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120405",
    TUbiDes: "La Libertad/Otuzco/LaCuesta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120408",
    TUbiDes: "La Libertad/Otuzco/Paranday",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120409",
    TUbiDes: "La Libertad/Otuzco/Salpo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120410",
    TUbiDes: "La Libertad/Otuzco/Sinsicap",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120411",
    TUbiDes: "La Libertad/Otuzco/Usquil",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120413",
    TUbiDes: "La Libertad/Otuzco/Mache",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120501",
    TUbiDes: "La Libertad/Pacasmayo/San Pedro de Lloc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120503",
    TUbiDes: "La Libertad/Pacasmayo/Guadalupe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120504",
    TUbiDes: "La Libertad/Pacasmayo/Jequetepeque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120506",
    TUbiDes: "La Libertad/Pacasmayo/Pacasmayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120508",
    TUbiDes: "La Libertad/Pacasmayo/San Jose",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120601",
    TUbiDes: "La Libertad/Pataz/Tayabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120602",
    TUbiDes: "La Libertad/Pataz/Buldibuyo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120603",
    TUbiDes: "La Libertad/Pataz/Chillia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120604",
    TUbiDes: "La Libertad/Pataz/Huaylillas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120605",
    TUbiDes: "La Libertad/Pataz/Huancaspata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120606",
    TUbiDes: "La Libertad/Pataz/Huayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120607",
    TUbiDes: "La Libertad/Pataz/Ongon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120608",
    TUbiDes: "La Libertad/Pataz/Parcoy",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120609",
    TUbiDes: "La Libertad/Pataz/Pataz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120610",
    TUbiDes: "La Libertad/Pataz/Pias",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120611",
    TUbiDes: "La Libertad/Pataz/Taurija",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120612",
    TUbiDes: "La Libertad/Pataz/Urpay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120613",
    TUbiDes: "La Libertad/Pataz/Santiago de Challas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120701",
    TUbiDes: "La Libertad/Santiago de Chuco/Santiago de Chuco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120702",
    TUbiDes: "La Libertad/Santiago de Chuco/Cachicadan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120703",
    TUbiDes: "La Libertad/Santiago de Chuco/Mollebamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120704",
    TUbiDes: "La Libertad/Santiago de Chuco/Mollepata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120705",
    TUbiDes: "La Libertad/Santiago de Chuco/Quiruvilca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120706",
    TUbiDes: "La Libertad/Santiago de Chuco/Santa Cruz de Chuca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120707",
    TUbiDes: "La Libertad/Santiago de Chuco/Sitabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120708",
    TUbiDes: "La Libertad/Santiago de Chuco/Angasmarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120801",
    TUbiDes: "La Libertad/Ascope/Ascope",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120802",
    TUbiDes: "La Libertad/Ascope/Chicama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120803",
    TUbiDes: "La Libertad/Ascope/Chocope",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120804",
    TUbiDes: "La Libertad/Ascope/Santiago de Cao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120805",
    TUbiDes: "La Libertad/Ascope/Magdalena de Cao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120806",
    TUbiDes: "La Libertad/Ascope/Paijan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120807",
    TUbiDes: "La Libertad/Ascope/Razuri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120808",
    TUbiDes: "La Libertad/Ascope/Casa Grande",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120901",
    TUbiDes: "La Libertad/Chepen/Chepen",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120902",
    TUbiDes: "La Libertad/Chepen/Pacanga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "120903",
    TUbiDes: "La Libertad/Chepen/Pueblo Nuevo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121001",
    TUbiDes: "La Libertad/Julcan/Julcan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121002",
    TUbiDes: "La Libertad/Julcan/Carabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121003",
    TUbiDes: "La Libertad/Julcan/Calamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121004",
    TUbiDes: "La Libertad/Julcan/Huaso",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121101",
    TUbiDes: "La Libertad/Gran Chimu/Cascas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121102",
    TUbiDes: "La Libertad/Gran Chimu/Lucma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121103",
    TUbiDes: "La Libertad/Gran Chimu/Marmot",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121104",
    TUbiDes: "La Libertad/Gran Chimu/Sayapullo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121201",
    TUbiDes: "La Libertad/Viru/Viru",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121202",
    TUbiDes: "La Libertad/Viru/Chao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "121203",
    TUbiDes: "La Libertad/Viru/Guadalupito",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130101",
    TUbiDes: "Lambayeque/Chiclayo/Chiclayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130102",
    TUbiDes: "Lambayeque/Chiclayo/Chongoyape",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130103",
    TUbiDes: "Lambayeque/Chiclayo/Eten",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130104",
    TUbiDes: "Lambayeque/Chiclayo/Eten Puerto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130105",
    TUbiDes: "Lambayeque/Chiclayo/Lagunas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130106",
    TUbiDes: "Lambayeque/Chiclayo/Monsefu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130107",
    TUbiDes: "Lambayeque/Chiclayo/Nueva Arica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130108",
    TUbiDes: "Lambayeque/Chiclayo/Oyotun",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130109",
    TUbiDes: "Lambayeque/Chiclayo/Picsi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130110",
    TUbiDes: "Lambayeque/Chiclayo/Pimentel",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130111",
    TUbiDes: "Lambayeque/Chiclayo/Reque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130112",
    TUbiDes: "Lambayeque/Chiclayo/Jose Leonardo Ortiz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130113",
    TUbiDes: "Lambayeque/Chiclayo/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130114",
    TUbiDes: "Lambayeque/Chiclayo/Saña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130115",
    TUbiDes: "Lambayeque/Chiclayo/La Victoria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130116",
    TUbiDes: "Lambayeque/Chiclayo/Cayalti",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130117",
    TUbiDes: "Lambayeque/Chiclayo/Patapo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130118",
    TUbiDes: "Lambayeque/Chiclayo/Pomalca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130119",
    TUbiDes: "Lambayeque/Chiclayo/Pucala",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130120",
    TUbiDes: "Lambayeque/Chiclayo/Tuman",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130201",
    TUbiDes: "Lambayeque/Ferreñafe/Ferreñafe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130202",
    TUbiDes: "Lambayeque/Ferreñafe/Incahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130203",
    TUbiDes: "Lambayeque/Ferreñafe/Cañaris",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130204",
    TUbiDes: "Lambayeque/Ferreñafe/Pitipo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130205",
    TUbiDes: "Lambayeque/Ferreñafe/Pueblo Nuevo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130206",
    TUbiDes: "Lambayeque/Ferreñafe/Manuel Antonio Mesones Muro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130301",
    TUbiDes: "Lambayeque/Lambayeque/Lambayeque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130302",
    TUbiDes: "Lambayeque/Lambayeque/Chochope",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130303",
    TUbiDes: "Lambayeque/Lambayeque/Illimo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130304",
    TUbiDes: "Lambayeque/Lambayeque/Jayanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130305",
    TUbiDes: "Lambayeque/Lambayeque/Mochumi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130306",
    TUbiDes: "Lambayeque/Lambayeque/Morrope",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130307",
    TUbiDes: "Lambayeque/Lambayeque/Motupe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130308",
    TUbiDes: "Lambayeque/Lambayeque/Olmos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130309",
    TUbiDes: "Lambayeque/Lambayeque/Pacora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130310",
    TUbiDes: "Lambayeque/Lambayeque/Salas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130311",
    TUbiDes: "Lambayeque/Lambayeque/San Jose",
    TUbiEst: "S",
  },
  {
    TUbiCod: "130312",
    TUbiDes: "Lambayeque/Lambayeque/Tucume",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140101",
    TUbiDes: "Lima/Lima/Lima",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140102",
    TUbiDes: "Lima/Lima/Ancon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140103",
    TUbiDes: "Lima/Lima/Ate",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140104",
    TUbiDes: "Lima/Lima/Breña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140105",
    TUbiDes: "Lima/Lima/Carabayllo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140106",
    TUbiDes: "Lima/Lima/Comas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140107",
    TUbiDes: "Lima/Lima/Chaclacayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140108",
    TUbiDes: "Lima/Lima/Chorrillos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140109",
    TUbiDes: "Lima/Lima/La Victoria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140110",
    TUbiDes: "Lima/Lima/La Molina",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140111",
    TUbiDes: "Lima/Lima/Lince",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140112",
    TUbiDes: "Lima/Lima/Lurigancho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140113",
    TUbiDes: "Lima/Lima/Lurin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140114",
    TUbiDes: "Lima/Lima/Magdalena del Mar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140115",
    TUbiDes: "Lima/Lima/Miraflores",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140116",
    TUbiDes: "Lima/Lima/Pachacamac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140117",
    TUbiDes: "Lima/Lima/Pueblo Libre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140118",
    TUbiDes: "Lima/Lima/Pucusana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140119",
    TUbiDes: "Lima/Lima/Puente Piedra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140120",
    TUbiDes: "Lima/Lima/Punta Hermosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140121",
    TUbiDes: "Lima/Lima/Punta Negra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140122",
    TUbiDes: "Lima/Lima/Rimac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140123",
    TUbiDes: "Lima/Lima/San Bartolo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140124",
    TUbiDes: "Lima/Lima/San Isidro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140125",
    TUbiDes: "Lima/Lima/Barranco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140126",
    TUbiDes: "Lima/Lima/San Martin de Porres",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140127",
    TUbiDes: "Lima/Lima/San Miguel",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140128",
    TUbiDes: "Lima/Lima/Santa Maria del Mar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140129",
    TUbiDes: "Lima/Lima/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140130",
    TUbiDes: "Lima/Lima/Santiago de Surco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140131",
    TUbiDes: "Lima/Lima/Surquillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140132",
    TUbiDes: "Lima/Lima/Villa Maria del Triunfo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140133",
    TUbiDes: "Lima/Lima/Jesus Maria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140134",
    TUbiDes: "Lima/Lima/Independencia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140135",
    TUbiDes: "Lima/Lima/El Agustino",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140136",
    TUbiDes: "Lima/Lima/San Juan de Miraflores",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140137",
    TUbiDes: "Lima/Lima/San Juan de Lurigancho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140138",
    TUbiDes: "Lima/Lima/San Luis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140139",
    TUbiDes: "Lima/Lima/Cieneguilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140140",
    TUbiDes: "Lima/Lima/San Borja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140141",
    TUbiDes: "Lima/Lima/Villa El Salvador",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140142",
    TUbiDes: "Lima/Lima/Los Olivos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140143",
    TUbiDes: "Lima/Lima/Santa Anita",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140201",
    TUbiDes: "Lima/Cajatambo/Cajatambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140205",
    TUbiDes: "Lima/Cajatambo/Copa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140206",
    TUbiDes: "Lima/Cajatambo/Gorgor",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140207",
    TUbiDes: "Lima/Cajatambo/Huancapon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140208",
    TUbiDes: "Lima/Cajatambo/Manas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140301",
    TUbiDes: "Lima/Canta/Canta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140302",
    TUbiDes: "Lima/Canta/Arahuay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140303",
    TUbiDes: "Lima/Canta/Huamantanga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140304",
    TUbiDes: "Lima/Canta/Huaros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140305",
    TUbiDes: "Lima/Canta/Lachaqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140306",
    TUbiDes: "Lima/Canta/San Buenaventura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140307",
    TUbiDes: "Lima/Canta/Santa Rosa de Quives",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140401",
    TUbiDes: "Lima/Cañete/San Vicente de Cañete",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140402",
    TUbiDes: "Lima/Cañete/Calango",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140403",
    TUbiDes: "Lima/Cañete/Cerro Azul",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140404",
    TUbiDes: "Lima/Cañete/Coayllo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140405",
    TUbiDes: "Lima/Cañete/Chilca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140406",
    TUbiDes: "Lima/Cañete/Imperial",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140407",
    TUbiDes: "Lima/Cañete/Lunahuana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140408",
    TUbiDes: "Lima/Cañete/Mala",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140409",
    TUbiDes: "Lima/Cañete/Nuevo Imperial",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140410",
    TUbiDes: "Lima/Cañete/Pacaran",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140411",
    TUbiDes: "Lima/Cañete/Quilmana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140412",
    TUbiDes: "Lima/Cañete/San Antonio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140413",
    TUbiDes: "Lima/Cañete/San Luis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140414",
    TUbiDes: "Lima/Cañete/Santa Cruz de Flores",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140415",
    TUbiDes: "Lima/Cañete/Zuñiga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140416",
    TUbiDes: "Lima/Cañete/Asia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140501",
    TUbiDes: "Lima/Huaura/Huacho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140502",
    TUbiDes: "Lima/Huaura/Ambar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140504",
    TUbiDes: "Lima/Huaura/Caleta de Carquin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140505",
    TUbiDes: "Lima/Huaura/Checras",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140506",
    TUbiDes: "Lima/Huaura/Hualmay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140507",
    TUbiDes: "Lima/Huaura/Huaura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140508",
    TUbiDes: "Lima/Huaura/Leoncio Prado",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140509",
    TUbiDes: "Lima/Huaura/Paccho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140511",
    TUbiDes: "Lima/Huaura/Santa Leonor",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140512",
    TUbiDes: "Lima/Huaura/Santa Maria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140513",
    TUbiDes: "Lima/Huaura/Sayan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140516",
    TUbiDes: "Lima/Huaura/Vegueta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140601",
    TUbiDes: "Lima/Huarochiri/Matucana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140602",
    TUbiDes: "Lima/Huarochiri/Antioquia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140603",
    TUbiDes: "Lima/Huarochiri/Callahuanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140604",
    TUbiDes: "Lima/Huarochiri/Carampoma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140605",
    TUbiDes: "Lima/Huarochiri/San Pedro de Casta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140606",
    TUbiDes: "Lima/Huarochiri/Cuenca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140607",
    TUbiDes: "Lima/Huarochiri/Chicla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140608",
    TUbiDes: "Lima/Huarochiri/Huanza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140609",
    TUbiDes: "Lima/Huarochiri/Huarochiri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140610",
    TUbiDes: "Lima/Huarochiri/Lahuaytambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140611",
    TUbiDes: "Lima/Huarochiri/Langa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140612",
    TUbiDes: "Lima/Huarochiri/Mariatana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140613",
    TUbiDes: "Lima/Huarochiri/Ricardo Palma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140614",
    TUbiDes: "Lima/Huarochiri/San Andres de Tupicocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140615",
    TUbiDes: "Lima/Huarochiri/San Antonio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140616",
    TUbiDes: "Lima/Huarochiri/San Bartolome",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140617",
    TUbiDes: "Lima/Huarochiri/San Damian",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140618",
    TUbiDes: "Lima/Huarochiri/Sangallaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140619",
    TUbiDes: "Lima/Huarochiri/San Juan de Tantaranche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140620",
    TUbiDes: "Lima/Huarochiri/San Lorenzo de Quinti",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140621",
    TUbiDes: "Lima/Huarochiri/San Mateo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140622",
    TUbiDes: "Lima/Huarochiri/San Mateode Otao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140623",
    TUbiDes: "Lima/Huarochiri/San Pedrode Huancayre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140624",
    TUbiDes: "Lima/Huarochiri/Santa Cruzde Cocachacra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140625",
    TUbiDes: "Lima/Huarochiri/SantaEulalia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140626",
    TUbiDes: "Lima/Huarochiri/SantiagodeAnchucaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140627",
    TUbiDes: "Lima/Huarochiri/Santiago de Tuna",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140628",
    TUbiDes: "Lima/Huarochiri/Santo Domingo de los Olleros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140629",
    TUbiDes: "Lima/Huarochiri/Surco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140630",
    TUbiDes: "Lima/Huarochiri/Huachupampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140631",
    TUbiDes: "Lima/Huarochiri/Laraos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140632",
    TUbiDes: "Lima/Huarochiri/San Juande Iris",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140701",
    TUbiDes: "Lima/Yauyos/Yauyos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140702",
    TUbiDes: "Lima/Yauyos/Alis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140703",
    TUbiDes: "Lima/Yauyos/Ayauca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140704",
    TUbiDes: "Lima/Yauyos/Ayaviri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140705",
    TUbiDes: "Lima/Yauyos/Azangaro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140706",
    TUbiDes: "Lima/Yauyos/Cacra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140707",
    TUbiDes: "Lima/Yauyos/Carania",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140708",
    TUbiDes: "Lima/Yauyos/Cochas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140709",
    TUbiDes: "Lima/Yauyos/Colonia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140710",
    TUbiDes: "Lima/Yauyos/Chocos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140711",
    TUbiDes: "Lima/Yauyos/Huampara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140712",
    TUbiDes: "Lima/Yauyos/Huancaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140713",
    TUbiDes: "Lima/Yauyos/Huangascar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140714",
    TUbiDes: "Lima/Yauyos/Huantan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140715",
    TUbiDes: "Lima/Yauyos/Huañec",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140716",
    TUbiDes: "Lima/Yauyos/Laraos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140717",
    TUbiDes: "Lima/Yauyos/Lincha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140718",
    TUbiDes: "Lima/Yauyos/Miraflores",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140719",
    TUbiDes: "Lima/Yauyos/Omas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140720",
    TUbiDes: "Lima/Yauyos/Quinches",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140721",
    TUbiDes: "Lima/Yauyos/Quinocay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140722",
    TUbiDes: "Lima/Yauyos/San Joaquin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140723",
    TUbiDes: "Lima/Yauyos/San Pedro de Pilas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140724",
    TUbiDes: "Lima/Yauyos/Tanta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140725",
    TUbiDes: "Lima/Yauyos/Tauripampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140726",
    TUbiDes: "Lima/Yauyos/Tupe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140727",
    TUbiDes: "Lima/Yauyos/Tomas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140728",
    TUbiDes: "Lima/Yauyos/Viñac",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140729",
    TUbiDes: "Lima/Yauyos/Vitis",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140730",
    TUbiDes: "Lima/Yauyos/Hongos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140731",
    TUbiDes: "Lima/Yauyos/Madean",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140732",
    TUbiDes: "Lima/Yauyos/Putinza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140733",
    TUbiDes: "Lima/Yauyos/Catahuasi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140801",
    TUbiDes: "Lima/Huaral/Huaral",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140802",
    TUbiDes: "Lima/Huaral/Atavillos Alto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140803",
    TUbiDes: "Lima/Huaral/Atavillos Bajo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140804",
    TUbiDes: "Lima/Huaral/Aucallama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140805",
    TUbiDes: "Lima/Huaral/Chancay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140806",
    TUbiDes: "Lima/Huaral/Ihuari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140807",
    TUbiDes: "Lima/Huaral/Lampian",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140808",
    TUbiDes: "Lima/Huaral/Pacaraos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140809",
    TUbiDes: "Lima/Huaral/San Miguel de Acos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140810",
    TUbiDes: "Lima/Huaral/Veintisiete de Noviembre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140811",
    TUbiDes: "Lima/Huaral/Santa Cruz de Andamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140812",
    TUbiDes: "Lima/Huaral/Sumbilca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140901",
    TUbiDes: "Lima/Barranca/Barranca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140902",
    TUbiDes: "Lima/Barranca/Paramonga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140903",
    TUbiDes: "Lima/Barranca/Pativilca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140904",
    TUbiDes: "Lima/Barranca/Supe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "140905",
    TUbiDes: "Lima/Barranca/Supe Puerto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "141001",
    TUbiDes: "Lima/Oyon/Oyon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "141002",
    TUbiDes: "Lima/Oyon/Navan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "141003",
    TUbiDes: "Lima/Oyon/Caujul",
    TUbiEst: "S",
  },
  {
    TUbiCod: "141004",
    TUbiDes: "Lima/Oyon/Andajes",
    TUbiEst: "S",
  },
  {
    TUbiCod: "141005",
    TUbiDes: "Lima/Oyon/Pachangara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "141006",
    TUbiDes: "Lima/Oyon/Cochamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150101",
    TUbiDes: "Loreto/Maynas/Iquitos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150102",
    TUbiDes: "Loreto/Maynas/Alto Nanay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150103",
    TUbiDes: "Loreto/Maynas/Fernando Lores",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150104",
    TUbiDes: "Loreto/Maynas/Las Amazonas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150105",
    TUbiDes: "Loreto/Maynas/Mazan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150106",
    TUbiDes: "Loreto/Maynas/Napo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150107",
    TUbiDes: "Loreto/Maynas/Putumayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150108",
    TUbiDes: "Loreto/Maynas/Torres Causana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150110",
    TUbiDes: "Loreto/Maynas/Indiana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150111",
    TUbiDes: "Loreto/Maynas/Punchana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150112",
    TUbiDes: "Loreto/Maynas/Belen",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150113",
    TUbiDes: "Loreto/Maynas/San Juan Bautista",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150114",
    TUbiDes: "Loreto/Maynas/Teniente Manuel Clavero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150201",
    TUbiDes: "Loreto/Alto Amazonas/Yurimaguas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150202",
    TUbiDes: "Loreto/Alto Amazonas/Balsapuerto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150205",
    TUbiDes: "Loreto/Alto Amazonas/Jeberos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150206",
    TUbiDes: "Loreto/Alto Amazonas/Lagunas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150210",
    TUbiDes: "Loreto/Alto Amazonas/Santa Cruz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150211",
    TUbiDes: "Loreto/AltoAmazonas/Teniente Cesar Lopez Rojas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150301",
    TUbiDes: "Loreto/Loreto/Nauta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150302",
    TUbiDes: "Loreto/Loreto/Parinari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150303",
    TUbiDes: "Loreto/Loreto/Tigre",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150304",
    TUbiDes: "Loreto/Loreto/Urarinas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150305",
    TUbiDes: "Loreto/Loreto/Trompeteros",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150401",
    TUbiDes: "Loreto/Requena/Requena",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150402",
    TUbiDes: "Loreto/Requena/Alto Tapiche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150403",
    TUbiDes: "Loreto/Requena/Capelo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150404",
    TUbiDes: "Loreto/Requena/Emilio San Martin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150405",
    TUbiDes: "Loreto/Requena/Maquia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150406",
    TUbiDes: "Loreto/Requena/Puinahua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150407",
    TUbiDes: "Loreto/Requena/Saquena",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150408",
    TUbiDes: "Loreto/Requena/Soplin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150409",
    TUbiDes: "Loreto/Requena/Tapiche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150410",
    TUbiDes: "Loreto/Requena/Jenaro Herrera",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150411",
    TUbiDes: "Loreto/Requena/Yaquerana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150501",
    TUbiDes: "Loreto/Ucayali/Contamana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150502",
    TUbiDes: "Loreto/Ucayali/Vargas Guerra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150503",
    TUbiDes: "Loreto/Ucayali/Padre Marquez",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150504",
    TUbiDes: "Loreto/Ucayali/Pampa Hermosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150505",
    TUbiDes: "Loreto/Ucayali/Sarayacu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150506",
    TUbiDes: "Loreto/Ucayali/Inahuaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150601",
    TUbiDes: "Loreto/Mariscal Ramon Castilla/Ramon Castilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150602",
    TUbiDes: "Loreto/Mariscal Ramon Castilla/Pebas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150603",
    TUbiDes: "Loreto/Mariscal Ramon Castilla/Yavari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150604",
    TUbiDes: "Loreto/Mariscal Ramon Castilla/San Pablo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150701",
    TUbiDes: "Loreto/Datem del Marañon/Barranca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150702",
    TUbiDes: "Loreto/Datem del Marañon/Andoas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150703",
    TUbiDes: "Loreto/Datem del Marañon/Cahuapanas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150704",
    TUbiDes: "Loreto/Datem del Marañon/Manseriche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150705",
    TUbiDes: "Loreto/Datem del Marañon/Morona",
    TUbiEst: "S",
  },
  {
    TUbiCod: "150706",
    TUbiDes: "Loreto/Datem del Marañon/Pastaza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160101",
    TUbiDes: "Madre de Dios/Tambopata/Tambopata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160102",
    TUbiDes: "Madre de Dios/Tambopata/Inambari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160103",
    TUbiDes: "Madre de Dios/Tambopata/Las Piedras",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160104",
    TUbiDes: "Madre de Dios/Tambopata/Laberinto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160201",
    TUbiDes: "Madre de Dios/Manu/Manu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160202",
    TUbiDes: "Madre de Dios/Manu/Fitzcarrald",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160203",
    TUbiDes: "Madre de Dios/Manu/Madre de Dios",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160204",
    TUbiDes: "Madre de Dios/Manu/Huepetuhe",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160301",
    TUbiDes: "Madre de Dios/Tahuamanu/Iñapari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160302",
    TUbiDes: "Madre de Dios/Tahuamanu/Iberia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "160303",
    TUbiDes: "Madre de Dios/Tahuamanu/Tahuamanu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170101",
    TUbiDes: "Moquegua/Mariscal Nieto/Moquegua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170102",
    TUbiDes: "Moquegua/Mariscal Nieto/Carumas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170103",
    TUbiDes: "Moquegua/Mariscal Nieto/Cuchumbaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170104",
    TUbiDes: "Moquegua/Mariscal Nieto/San Cristobal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170105",
    TUbiDes: "Moquegua/Mariscal Nieto/Torata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170106",
    TUbiDes: "Moquegua/Mariscal Nieto/Samegua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170201",
    TUbiDes: "Moquegua/General Sanchez Cerro/Omate",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170202",
    TUbiDes: "Moquegua/General Sanchez Cerro/Coalaque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170203",
    TUbiDes: "Moquegua/General Sanchez Cerro/Chojata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170204",
    TUbiDes: "Moquegua/General Sanchez Cerro/Ichuña",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170205",
    TUbiDes: "Moquegua/General Sanchez Cerro/La Capilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170206",
    TUbiDes: "Moquegua/General Sanchez Cerro/Lloque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170207",
    TUbiDes: "Moquegua/General Sanchez Cerro/Matalaque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170208",
    TUbiDes: "Moquegua/General Sanchez Cerro/Puquina",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170209",
    TUbiDes: "Moquegua/General Sanchez Cerro/Quinistaquillas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170210",
    TUbiDes: "Moquegua/General Sanchez Cerro/Ubinas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170211",
    TUbiDes: "Moquegua/General Sanchez Cerro/Yunga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170301",
    TUbiDes: "Moquegua/Ilo/Ilo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170302",
    TUbiDes: "Moquegua/Ilo/El Algarrobal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "170303",
    TUbiDes: "Moquegua/Ilo/Pacocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180101",
    TUbiDes: "Pasco/Pasco/Chaupimarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180103",
    TUbiDes: "Pasco/Pasco/Huachon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180104",
    TUbiDes: "Pasco/Pasco/Huariaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180105",
    TUbiDes: "Pasco/Pasco/Huayllay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180106",
    TUbiDes: "Pasco/Pasco/Ninacaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180107",
    TUbiDes: "Pasco/Pasco/Pallanchacra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180108",
    TUbiDes: "Pasco/Pasco/Paucartambo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180109",
    TUbiDes: "Pasco/Pasco/San Francisco de Asis de Yarusyacan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180110",
    TUbiDes: "Pasco/Pasco/Simon Bolivar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180111",
    TUbiDes: "Pasco/Pasco/Ticlacayan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180112",
    TUbiDes: "Pasco/Pasco/Tinyahuarco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180113",
    TUbiDes: "Pasco/Pasco/Vicco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180114",
    TUbiDes: "Pasco/Pasco/Yanacancha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180201",
    TUbiDes: "Pasco/Daniel Alcides Carrion/Yanahuanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180202",
    TUbiDes: "Pasco/Daniel Alcides Carrion/Chacayan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180203",
    TUbiDes: "Pasco/Daniel Alcides Carrion/Goyllarisquizga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180204",
    TUbiDes: "Pasco/Daniel Alcides Carrion/Paucar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180205",
    TUbiDes: "Pasco/Daniel Alcides Carrion/San Pedro de Pillao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180206",
    TUbiDes: "Pasco/Daniel Alcides Carrion/Santa Ana de Tusi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180207",
    TUbiDes: "Pasco/Daniel Alcides Carrion/Tapuc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180208",
    TUbiDes: "Pasco/Daniel Alcides Carrion/Vilcabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180301",
    TUbiDes: "Pasco/Oxapampa/Oxapampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180302",
    TUbiDes: "Pasco/Oxapampa/Chontabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180303",
    TUbiDes: "Pasco/Oxapampa/Huancabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180304",
    TUbiDes: "Pasco/Oxapampa/Puerto Bermudez",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180305",
    TUbiDes: "Pasco/Oxapampa/VillaRica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180306",
    TUbiDes: "Pasco/Oxapampa/Pozuzo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180307",
    TUbiDes: "Pasco/Oxapampa/Palcazu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "180308",
    TUbiDes: "Pasco/Oxapampa/Constitución",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190101",
    TUbiDes: "Piura/Piura/Piura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190103",
    TUbiDes: "Piura/Piura/Castilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190104",
    TUbiDes: "Piura/Piura/Catacaos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190105",
    TUbiDes: "Piura/Piura/La Arena",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190106",
    TUbiDes: "Piura/Piura/La Union",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190107",
    TUbiDes: "Piura/Piura/Las Lomas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190109",
    TUbiDes: "Piura/Piura/Tambo Grande",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190113",
    TUbiDes: "Piura/Piura/Cura Mori",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190114",
    TUbiDes: "Piura/Piura/El Tallan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190201",
    TUbiDes: "Piura/Ayabaca/Ayabaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190202",
    TUbiDes: "Piura/Ayabaca/Frias",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190203",
    TUbiDes: "Piura/Ayabaca/Lagunas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190204",
    TUbiDes: "Piura/Ayabaca/Montero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190205",
    TUbiDes: "Piura/Ayabaca/Pacaipampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190206",
    TUbiDes: "Piura/Ayabaca/Sapillica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190207",
    TUbiDes: "Piura/Ayabaca/Sicchez",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190208",
    TUbiDes: "Piura/Ayabaca/Suyo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190209",
    TUbiDes: "Piura/Ayabaca/Jilili",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190210",
    TUbiDes: "Piura/Ayabaca/Paimas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190301",
    TUbiDes: "Piura/Huancabamba/Huancabamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190302",
    TUbiDes: "Piura/Huancabamba/Canchaque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190303",
    TUbiDes: "Piura/Huancabamba/Huarmaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190304",
    TUbiDes: "Piura/Huancabamba/Sondor",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190305",
    TUbiDes: "Piura/Huancabamba/Sondorillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190306",
    TUbiDes: "Piura/Huancabamba/El Carmen de La Frontera",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190307",
    TUbiDes: "Piura/Huancabamba/San Miguel de El Faique",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190308",
    TUbiDes: "Piura/Huancabamba/Lalaquiz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190401",
    TUbiDes: "Piura/Morropon/Chulucanas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190402",
    TUbiDes: "Piura/Morropon/Buenos Aires",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190403",
    TUbiDes: "Piura/Morropon/Chalaco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190404",
    TUbiDes: "Piura/Morropon/Morropon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190405",
    TUbiDes: "Piura/Morropon/Salitral",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190406",
    TUbiDes: "Piura/Morropon/Santa Catalina de Mossa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190407",
    TUbiDes: "Piura/Morropon/Santo Domingo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190408",
    TUbiDes: "Piura/Morropon/La Matanza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190409",
    TUbiDes: "Piura/Morropon/Yamango",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190410",
    TUbiDes: "Piura/Morropon/San Juan de Bigote",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190501",
    TUbiDes: "Piura/Paita/Paita",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190502",
    TUbiDes: "Piura/Paita/Amotape",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190503",
    TUbiDes: "Piura/Paita/Arenal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190504",
    TUbiDes: "Piura/Paita/La Huaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190505",
    TUbiDes: "Piura/Paita/Colan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190506",
    TUbiDes: "Piura/Paita/Tamarindo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190507",
    TUbiDes: "Piura/Paita/Vichayal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190601",
    TUbiDes: "Piura/Sullana/Sullana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190602",
    TUbiDes: "Piura/Sullana/Bellavista",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190603",
    TUbiDes: "Piura/Sullana/Lancones",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190604",
    TUbiDes: "Piura/Sullana/Marcavelica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190605",
    TUbiDes: "Piura/Sullana/Miguel Checa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190606",
    TUbiDes: "Piura/Sullana/Querecotillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190607",
    TUbiDes: "Piura/Sullana/Salitral",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190608",
    TUbiDes: "Piura/Sullana/Ignacio Escudero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190701",
    TUbiDes: "Piura/Talara/Pariñas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190702",
    TUbiDes: "Piura/Talara/El Alto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190703",
    TUbiDes: "Piura/Talara/La Brea",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190704",
    TUbiDes: "Piura/Talara/Lobitos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190705",
    TUbiDes: "Piura/Talara/Mancora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190706",
    TUbiDes: "Piura/Talara/Los Organos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190801",
    TUbiDes: "Piura/Sechura/Sechura",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190802",
    TUbiDes: "Piura/Sechura/Vice",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190803",
    TUbiDes: "Piura/Sechura/Bernal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190804",
    TUbiDes: "Piura/Sechura/Bellavista de La Union",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190805",
    TUbiDes: "Piura/Sechura/Cristo Nos Valga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "190806",
    TUbiDes: "Piura/Sechura/Rinconada Llicuar",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200101",
    TUbiDes: "Puno/Puno/Puno",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200102",
    TUbiDes: "Puno/Puno/Acora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200103",
    TUbiDes: "Puno/Puno/Atuncolla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200104",
    TUbiDes: "Puno/Puno/Capachica",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200105",
    TUbiDes: "Puno/Puno/Coata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200106",
    TUbiDes: "Puno/Puno/Chucuito",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200107",
    TUbiDes: "Puno/Puno/Huata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200108",
    TUbiDes: "Puno/Puno/Mañazo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200109",
    TUbiDes: "Puno/Puno/Paucarcolla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200110",
    TUbiDes: "Puno/Puno/Pichacani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200111",
    TUbiDes: "Puno/Puno/San Antonio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200112",
    TUbiDes: "Puno/Puno/Tiquillaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200113",
    TUbiDes: "Puno/Puno/Vilque",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200114",
    TUbiDes: "Puno/Puno/Plateria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200115",
    TUbiDes: "Puno/Puno/Amantani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200201",
    TUbiDes: "Puno/Azangaro/Azangaro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200202",
    TUbiDes: "Puno/Azangaro/Achaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200203",
    TUbiDes: "Puno/Azangaro/Arapa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200204",
    TUbiDes: "Puno/Azangaro/Asillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200205",
    TUbiDes: "Puno/Azangaro/Caminaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200206",
    TUbiDes: "Puno/Azangaro/Chupa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200207",
    TUbiDes: "Puno/Azangaro/Jose Domingo Choquehuanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200208",
    TUbiDes: "Puno/Azangaro/Muñani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200210",
    TUbiDes: "Puno/Azangaro/Potoni",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200212",
    TUbiDes: "Puno/Azangaro/Saman",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200213",
    TUbiDes: "Puno/Azangaro/San Anton",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200214",
    TUbiDes: "Puno/Azangaro/San Jose",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200215",
    TUbiDes: "Puno/Azangaro/San Juande Salinas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200216",
    TUbiDes: "Puno/Azangaro/Santiago de Pupuja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200217",
    TUbiDes: "Puno/Azangaro/Tirapata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200301",
    TUbiDes: "Puno/Carabaya/Macusani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200302",
    TUbiDes: "Puno/Carabaya/Ajoyani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200303",
    TUbiDes: "Puno/Carabaya/Ayapata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200304",
    TUbiDes: "Puno/Carabaya/Coasa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200305",
    TUbiDes: "Puno/Carabaya/Corani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200306",
    TUbiDes: "Puno/Carabaya/Crucero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200307",
    TUbiDes: "Puno/Carabaya/Ituata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200308",
    TUbiDes: "Puno/Carabaya/Ollachea",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200309",
    TUbiDes: "Puno/Carabaya/San Gaban",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200310",
    TUbiDes: "Puno/Carabaya/Usicayos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200401",
    TUbiDes: "Puno/Chucuito/Juli",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200402",
    TUbiDes: "Puno/Chucuito/Desaguadero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200403",
    TUbiDes: "Puno/Chucuito/Huacullani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200406",
    TUbiDes: "Puno/Chucuito/Pisacoma",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200407",
    TUbiDes: "Puno/Chucuito/Pomata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200410",
    TUbiDes: "Puno/Chucuito/Zepita",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200412",
    TUbiDes: "Puno/Chucuito/Kelluyo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200501",
    TUbiDes: "Puno/Huancane/Huancane",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200502",
    TUbiDes: "Puno/Huancane/Cojata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200504",
    TUbiDes: "Puno/Huancane/Inchupalla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200506",
    TUbiDes: "Puno/Huancane/Pusi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200507",
    TUbiDes: "Puno/Huancane/Rosaspata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200508",
    TUbiDes: "Puno/Huancane/Taraco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200509",
    TUbiDes: "Puno/Huancane/Vilque Chico",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200511",
    TUbiDes: "Puno/Huancane/Huatasani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200601",
    TUbiDes: "Puno/Lampa/Lampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200602",
    TUbiDes: "Puno/Lampa/Cabanilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200603",
    TUbiDes: "Puno/Lampa/Calapuja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200604",
    TUbiDes: "Puno/Lampa/Nicasio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200605",
    TUbiDes: "Puno/Lampa/Ocuviri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200606",
    TUbiDes: "Puno/Lampa/Palca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200607",
    TUbiDes: "Puno/Lampa/Paratia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200608",
    TUbiDes: "Puno/Lampa/Pucara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200609",
    TUbiDes: "Puno/Lampa/Santa Lucia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200610",
    TUbiDes: "Puno/Lampa/Vilavila",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200701",
    TUbiDes: "Puno/Melgar/Ayaviri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200702",
    TUbiDes: "Puno/Melgar/Antauta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200703",
    TUbiDes: "Puno/Melgar/Cupi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200704",
    TUbiDes: "Puno/Melgar/Llalli",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200705",
    TUbiDes: "Puno/Melgar/Macari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200706",
    TUbiDes: "Puno/Melgar/Nuñoa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200707",
    TUbiDes: "Puno/Melgar/Orurillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200708",
    TUbiDes: "Puno/Melgar/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200709",
    TUbiDes: "Puno/Melgar/Umachiri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200801",
    TUbiDes: "Puno/Sandia/Sandia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200803",
    TUbiDes: "Puno/Sandia/Cuyocuyo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200804",
    TUbiDes: "Puno/Sandia/Limbani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200805",
    TUbiDes: "Puno/Sandia/Phara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200806",
    TUbiDes: "Puno/Sandia/Patambuco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200807",
    TUbiDes: "Puno/Sandia/Quiaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200808",
    TUbiDes: "Puno/Sandia/San Juan del Oro",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200810",
    TUbiDes: "Puno/Sandia/Yanahuaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200811",
    TUbiDes: "Puno/Sandia/Alto Inambari",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200812",
    TUbiDes: "Puno/Sandia/San Pedro de Putina Punco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200901",
    TUbiDes: "Puno/San Roman/Juliaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200902",
    TUbiDes: "Puno/San Roman/Cabana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200903",
    TUbiDes: "Puno/San Roman/Cabanillas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "200904",
    TUbiDes: "Puno/San Roman/Caracoto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201001",
    TUbiDes: "Puno/Yunguyo/Yunguyo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201002",
    TUbiDes: "Puno/Yunguyo/Unicachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201003",
    TUbiDes: "Puno/Yunguyo/Anapia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201004",
    TUbiDes: "Puno/Yunguyo/Copani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201005",
    TUbiDes: "Puno/Yunguyo/Cuturapi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201006",
    TUbiDes: "Puno/Yunguyo/Ollaraya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201007",
    TUbiDes: "Puno/Yunguyo/Tinicachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201101",
    TUbiDes: "Puno/San Antonio de Putina/Putina",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201102",
    TUbiDes: "Puno/San Antonio de Putina/Pedro Vilca Apaza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201103",
    TUbiDes: "Puno/San Antonio de Putina/Quilcapuncu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201104",
    TUbiDes: "Puno/San Antonio de Putina/Ananea",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201105",
    TUbiDes: "Puno/San Antonio de Putina/Sina",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201201",
    TUbiDes: "Puno/El Collao/Ilave",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201202",
    TUbiDes: "Puno/El Collao/Pilcuyo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201203",
    TUbiDes: "Puno/El Collao/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201204",
    TUbiDes: "Puno/El Collao/Capazo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201205",
    TUbiDes: "Puno/El Collao/Conduriri",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201301",
    TUbiDes: "Puno/Moho/Moho",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201302",
    TUbiDes: "Puno/Moho/Conima",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201303",
    TUbiDes: "Puno/Moho/Tilali",
    TUbiEst: "S",
  },
  {
    TUbiCod: "201304",
    TUbiDes: "Puno/Moho/Huayrapata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210101",
    TUbiDes: "San Martin/Moyobamba/Moyobamba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210102",
    TUbiDes: "San Martin/Moyobamba/Calzada",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210103",
    TUbiDes: "San Martin/Moyobamba/Habana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210104",
    TUbiDes: "San Martin/Moyobamba/Jepelacio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210105",
    TUbiDes: "San Martin/Moyobamba/Soritor",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210106",
    TUbiDes: "San Martin/Moyobamba/Yantalo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210201",
    TUbiDes: "San Martin/Huallaga/Saposoa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210202",
    TUbiDes: "San Martin/Huallaga/Piscoyacu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210203",
    TUbiDes: "San Martin/Huallaga/Sacanche",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210204",
    TUbiDes: "San Martin/Huallaga/Tingo de Saposoa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210205",
    TUbiDes: "San Martin/Huallaga/Alto Saposoa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210206",
    TUbiDes: "San Martin/Huallaga/El Eslabon",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210301",
    TUbiDes: "San Martin/Lamas/Lamas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210303",
    TUbiDes: "San Martin/Lamas/Barranquita",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210304",
    TUbiDes: "San Martin/Lamas/Caynarachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210305",
    TUbiDes: "San Martin/Lamas/Cuñumbuqui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210306",
    TUbiDes: "San Martin/Lamas/Pinto Recodo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210307",
    TUbiDes: "San Martin/Lamas/Rumisapa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210311",
    TUbiDes: "San Martin/Lamas/Shanao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210313",
    TUbiDes: "San Martin/Lamas/Tabalosos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210314",
    TUbiDes: "San Martin/Lamas/Zapatero",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210315",
    TUbiDes: "San Martin/Lamas/Alonso de Alvarado",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210316",
    TUbiDes: "San Martin/Lamas/San Roque de Cumbaza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210401",
    TUbiDes: "San Martin/Mariscal Caceres/Juanjui",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210402",
    TUbiDes: "San Martin/Mariscal Caceres/Campanilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210403",
    TUbiDes: "San Martin/Mariscal Caceres/Huicungo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210404",
    TUbiDes: "San Martin/Mariscal Caceres/Pachiza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210405",
    TUbiDes: "San Martin/Mariscal Caceres/Pajarillo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210501",
    TUbiDes: "San Martin/Rioja/Rioja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210502",
    TUbiDes: "San Martin/Rioja/Posic",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210503",
    TUbiDes: "San Martin/Rioja/Yorongos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210504",
    TUbiDes: "San Martin/Rioja/Yuracyacu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210505",
    TUbiDes: "San Martin/Rioja/Nueva Cajamarca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210506",
    TUbiDes: "San Martin/Rioja/Elias Soplin Vargas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210507",
    TUbiDes: "San Martin/Rioja/San Fernando",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210508",
    TUbiDes: "San Martin/Rioja/Pardo Miguel",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210509",
    TUbiDes: "San Martin/Rioja/Awajun",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210601",
    TUbiDes: "San Martin/San Martin/Tarapoto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210602",
    TUbiDes: "San Martin/San Martin/Alberto Leveau",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210604",
    TUbiDes: "San Martin/San Martin/Cacatachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210606",
    TUbiDes: "San Martin/San Martin/Chazuta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210607",
    TUbiDes: "San Martin/San Martin/Chipurana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210608",
    TUbiDes: "San Martin/San Martin/El Porvenir",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210609",
    TUbiDes: "San Martin/San Martin/Huimbayoc",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210610",
    TUbiDes: "San Martin/San Martin/Juan Guerra",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210611",
    TUbiDes: "San Martin/San Martin/Morales",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210612",
    TUbiDes: "San Martin/San Martin/Papaplaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210616",
    TUbiDes: "San Martin/San Martin/San Antonio",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210619",
    TUbiDes: "San Martin/San Martin/Sauce",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210620",
    TUbiDes: "San Martin/San Martin/Shapaja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210621",
    TUbiDes: "San Martin/San Martin/La Banda de Shilcayo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210701",
    TUbiDes: "San Martin/Bellavista/Bellavista",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210702",
    TUbiDes: "San Martin/Bellavista/San Rafael",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210703",
    TUbiDes: "San Martin/Bellavista/San Pablo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210704",
    TUbiDes: "San Martin/Bellavista/Alto Biavo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210705",
    TUbiDes: "San Martin/Bellavista/Huallaga",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210706",
    TUbiDes: "San Martin/Bellavista/Bajo Biavo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210801",
    TUbiDes: "San Martin/Tocache/Tocache",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210802",
    TUbiDes: "San Martin/Tocache/Nuevo Progreso",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210803",
    TUbiDes: "San Martin/Tocache/Polvora",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210804",
    TUbiDes: "San Martin/Tocache/Shunte",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210805",
    TUbiDes: "San Martin/Tocache/Uchiza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210901",
    TUbiDes: "San Martin/Picota/Picota",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210902",
    TUbiDes: "San Martin/Picota/Buenos Aires",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210903",
    TUbiDes: "San Martin/Picota/Caspisapa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210904",
    TUbiDes: "San Martin/Picota/Pilluana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210905",
    TUbiDes: "San Martin/Picota/Pucacaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210906",
    TUbiDes: "San Martin/Picota/San Cristobal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210907",
    TUbiDes: "San Martin/Picota/San Hilarion",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210908",
    TUbiDes: "San Martin/Picota/Tingo de Ponasa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210909",
    TUbiDes: "San Martin/Picota/Tres Unidos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "210910",
    TUbiDes: "San Martin/Picota/Shamboyacu",
    TUbiEst: "S",
  },
  {
    TUbiCod: "211001",
    TUbiDes: "San Martin/El Dorado/San Jose de Sisa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "211002",
    TUbiDes: "San Martin/El Dorado/Agua Blanca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "211003",
    TUbiDes: "San Martin/El Dorado/Shatoja",
    TUbiEst: "S",
  },
  {
    TUbiCod: "211004",
    TUbiDes: "San Martin/El Dorado/San Martin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "211005",
    TUbiDes: "San Martin/El Dorado/Santa Rosa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220101",
    TUbiDes: "Tacna/Tacna/Tacna",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220102",
    TUbiDes: "Tacna/Tacna/Calana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220104",
    TUbiDes: "Tacna/Tacna/Inclan",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220107",
    TUbiDes: "Tacna/Tacna/Pachia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220108",
    TUbiDes: "Tacna/Tacna/Palca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220109",
    TUbiDes: "Tacna/Tacna/Pocollay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220110",
    TUbiDes: "Tacna/Tacna/Sama",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220111",
    TUbiDes: "Tacna/Tacna/Alto de La Alianza",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220112",
    TUbiDes: "Tacna/Tacna/Ciudad Nueva",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220113",
    TUbiDes: "Tacna/Tacna/Coronel Gregori oAlbarracin Lanchipa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220201",
    TUbiDes: "Tacna/Tarata/Tarata",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220205",
    TUbiDes: "Tacna/Tarata/Heroes Albarracin",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220206",
    TUbiDes: "Tacna/Tarata/Estique",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220207",
    TUbiDes: "Tacna/Tarata/Estique Pampa",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220210",
    TUbiDes: "Tacna/Tarata/Sitajara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220211",
    TUbiDes: "Tacna/Tarata/Susapaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220212",
    TUbiDes: "Tacna/Tarata/Tarucachi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220213",
    TUbiDes: "Tacna/Tarata/Ticaco",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220301",
    TUbiDes: "Tacna/Jorge Basadre/Locumba",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220302",
    TUbiDes: "Tacna/Jorge Basadre/Ite",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220303",
    TUbiDes: "Tacna/Jorge Basadre/Ilabaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220401",
    TUbiDes: "Tacna/Candarave/Candarave",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220402",
    TUbiDes: "Tacna/Candarave/Cairani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220403",
    TUbiDes: "Tacna/Candarave/Curibaya",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220404",
    TUbiDes: "Tacna/Candarave/Huanuara",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220405",
    TUbiDes: "Tacna/Candarave/Quilahuani",
    TUbiEst: "S",
  },
  {
    TUbiCod: "220406",
    TUbiDes: "Tacna/Candarave/Camilaca",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230101",
    TUbiDes: "Tumbes/Tumbes/Tumbes",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230102",
    TUbiDes: "Tumbes/Tumbes/Corrales",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230103",
    TUbiDes: "Tumbes/Tumbes/La Cruz",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230104",
    TUbiDes: "Tumbes/Tumbes/PampasdeHospital",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230105",
    TUbiDes: "Tumbes/Tumbes/San Jacinto",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230106",
    TUbiDes: "Tumbes/Tumbes/San Juan de La Virgen",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230201",
    TUbiDes: "Tumbes/Contralmirante Villar/Zorritos",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230202",
    TUbiDes: "Tumbes/Contralmirante Villar/Casitas",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230203",
    TUbiDes: "Tumbes/Contralmirante Villar/Canoas de Punta Sal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230301",
    TUbiDes: "Tumbes/Zarumilla/Zarumilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230302",
    TUbiDes: "Tumbes/Zarumilla/Matapalo",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230303",
    TUbiDes: "Tumbes/Zarumilla/Papayal",
    TUbiEst: "S",
  },
  {
    TUbiCod: "230304",
    TUbiDes: "Tumbes/Zarumilla/Aguas Verdes",
    TUbiEst: "S",
  },
  {
    TUbiCod: "240101",
    TUbiDes: "Callao/Callao/Callao",
    TUbiEst: "S",
  },
  {
    TUbiCod: "240102",
    TUbiDes: "Callao/Callao/Bellavista",
    TUbiEst: "S",
  },
  {
    TUbiCod: "240103",
    TUbiDes: "Callao/Callao/La Punta",
    TUbiEst: "S",
  },
  {
    TUbiCod: "240104",
    TUbiDes: "Callao/Callao/Carmen de La Legua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "240105",
    TUbiDes: "Callao/Callao/La Perla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "240106",
    TUbiDes: "Callao/Callao/Ventanilla",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250101",
    TUbiDes: "Ucayali/Coronel Portillo/Calleria",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250102",
    TUbiDes: "Ucayali/Coronel Portillo/Yarinacocha",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250103",
    TUbiDes: "Ucayali/Coronel Portillo/Masisea",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250104",
    TUbiDes: "Ucayali/Coronel Portillo/Campoverde",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250105",
    TUbiDes: "Ucayali/Coronel Portillo/Iparia",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250106",
    TUbiDes: "Ucayali/Coronel Portillo/Nueva Requena",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250107",
    TUbiDes: "Ucayali/Coronel Portillo/Manantay",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250201",
    TUbiDes: "Ucayali/Padre Abad/Padre Abad",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250202",
    TUbiDes: "Ucayali/Padre Abad/Irazola",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250203",
    TUbiDes: "Ucayali/Padre Abad/Curimana",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250301",
    TUbiDes: "Ucayali/Atalaya/Raymondi",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250302",
    TUbiDes: "Ucayali/Atalaya/Tahuania",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250303",
    TUbiDes: "Ucayali/Atalaya/Yurua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250304",
    TUbiDes: "Ucayali/Atalaya/Sepahua",
    TUbiEst: "S",
  },
  {
    TUbiCod: "250401",
    TUbiDes: "Ucayali/Purus/Purus",
    TUbiEst: "S",
  },
];
