import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";

//Api
import { getDataReportApi, obtenerSucursales } from '../../api/tramiteApi'

//Utils
import { obtenerLocalStorage } from '../../resources/funciones'

// const fecha = new Date()
// fecha.setUTCHours(fecha.getUTCHours() - 5)
// const hoy = fecha.toISOString().slice(0, 10)
// fecha.setUTCDate(fecha.getUTCDate() - 15)
// const fechaAnterior = fecha.toISOString().slice(0, 10)

const fecha = new Date();
fecha.setUTCHours(fecha.getUTCHours() - 5);

const hoy = fecha.toISOString().slice(0, 10);

fecha.setUTCMonth(fecha.getUTCMonth() - 1); // Resta un mes al mes actual
const fechaAnterior = fecha.toISOString().slice(0, 10);



export function General() {
 


  const token = obtenerLocalStorage("usuario")?.token;
    const estilosCompania = obtenerLocalStorage("usuario")?.estilo;
    const { register, watch, setValue, handleSubmit } = useForm({})

    const [listaSucursales, setListaSucursales] = useState([])
    const [listaSedes, setListaSedes] = useState([]);
    const [inmovilizar, setInmovilizar] = useState(true);
    const [listaReportes, setListaReportes] = useState([]); 

    const alturaMaxima = "70vh"


    const [filteredData, setFilteredData] = useState([]);
    const colores = {
        datos:"#35a1b3",
        titulo:"#1d7faf",
        placa:"#7480a3",
    }

    // const onSubmitBuscar = (data) => {
    //     console.log("DATA", data)
    //     // const filteredData = listaSucursales.filter(item => {
    //     //     return (
    //     //         item.id === data.Sucursal &&
    //     //         item.sedes.filter(sede => sede.id === parseInt(data.Sede))
    //     //     );
    //     // });
    //     const filteredData = listaSucursales.map(item => {
    //         const sedesFiltradas = item.sedes.filter(sede => sede.id === parseInt(data.Sede));
    //         if (sedesFiltradas.length > 0) {
    //             return {
    //                 ...item,
    //                 sedes: sedesFiltradas
    //             };
    //         }
    //         return null;
    //     }).filter(item => item !== null && item.id === data.Sucursal);

    //     setFilteredData(filteredData);
    //     console.log("filtered data", filteredData)
    // }



 

    // useEffect(() =>{
    //     setValue('sucursal', '')
    //     setValue('sede', '')
    //     setValue('fchDesde', '')
    //     setValue('fchHasta', '')
    // })

    useEffect(() => {
        obtenerListaSedes(watch('sucursal'))
    }, [watch('sucursal')]);

    /* useEffect(() => {
        console.log(watch())
    }, [watch()]); */

    const obtenerListaSucursales = async () => {
        const dataPOST = {
            token: token,
        }
        const { sucursales } = await obtenerSucursales(dataPOST);
        setListaSucursales(sucursales)
        setValue("sucursal", sucursales[0].id)


        console.log("Lista de surcursales", listaSucursales)

    }


    const getDataReport = async (data) => {
        const dataPOST = {
            token: token,
        }

        const { reportes } = await getDataReportApi(data);
        setListaReportes(reportes);
        console.log("Reportes: ", listaReportes)
    }


    const obtenerListaSedes = async (idSucursal) => {
        let sedes = []
        const dataPOST = {
            token: token,
        }
        const { sucursales } = await obtenerSucursales(dataPOST)
        if (idSucursal === "") {
            sucursales.forEach(item => {
                sedes = sedes.concat(item.sedes)
            });
        } else {
            const sucursal = sucursales.find((item) => item.id === idSucursal);
            sedes = sucursal.sedes;
        }
        setListaSedes(sedes);
        setValue("sede", sedes.length > 1 ? "" : sedes[0]?.id)
    }

    return (
        <div className="">
            <div className="container-fluid p-3">
                <h4 className="text-center mt-3 ">Reporte General de  Inmatriculaciones</h4>
                <div className="row border p-2 pb-4">
                    <div className='col-md-3'>
                        <label className="form-label" htmlFor="form-sucursal">Sucursal</label>
                        <select className='form-select form-select-sm'
                            {...register('sucursal')}
                            value={watch('sucursal')}
                        >
                            {listaSucursales.length > 1 && <option value={""}>TODOS</option>}
                            {
                                listaSucursales?.length > 0 &&
                                listaSucursales.map((e) => (
                                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="col-md-3">
                        <label className="form-label">Sede</label>
                        <select
                            className="form-select form-select-sm"
                            {...register("sede")}
                            value={watch("sede")}
                        >
                            {listaSedes.length > 1 && <option value={""}>TODOS</option>}
                            {
                                listaSedes?.length > 0 &&
                                listaSedes.map((e) => (
                                    <option key={e?.id} value={e?.id}>
                                        {e?.tipo}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="col-md-2">
                        <label className="form-label ">Desde</label>
                        <input
                            className="form-control form-control-sm"
                            type="date"
                            defaultValue={fechaAnterior}
                            {...register("fchDesde")}
                        />
                    </div>
                    <div className="col-md-2">
                        <label className="form-label">Hasta</label>
                        <input
                            className="form-control form-control-sm"
                            type="date"
                            defaultValue={hoy}
                            {...register("fchHasta")}
                        />
                    </div>
                    {/* <div className="col-md-3">
                        <label className='form-label'>Estado</label>
                        <select
                            className="form-select form-select-sm"
                            {...register("Estado")}
                        >
                            <option value='Todos'>Todos</option>
                            <option value='Inscrito'>Inscrito</option>
                            <option value='Observado'>Observado</option>
                            <option value='Tachado'>Tachado</option>
                            <option value='Pagado'>Pagado</option>
                            <option value='Liquidado'>Liquidado</option>
                            <option value='Ingresado'>Ingresado</option>
                            <option value='Expedido'>Expedido</option>
                        </select>
                    </div> */}


                    <div className="col-md-2 d-flex justify-content-center align-items-end" style={{ marginTop: "3px" }}>
                        <button
                            className="col-md-6 btn btn-sm btn-warning"
                            // style={{ background: "#8ec321" }}
                          
                            onClick={handleSubmit(getDataReport)}
                        >
                            
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg>

                        </button>
                    </div>

                </div>
                {/* bg-light p-2 text-white bg-opacity-75 */}
                {/* <div className='form-check form-switch d-flex align-items-center mt-3'>
                    <input type='checkbox' id='tableSticky' className='form-check-input' onChange={e => setInmovilizar(e.target.checked)} />
                    <div style={{ width: "12px", display: "inline-block" }}></div>
                    <label className="form-check-label align-top" htmlFor="tableSticky">Inmovilizar Columnas</label>
                </div> */}
            </div>
            {/* <div style={{background:"#7FFF00"}}>adsa</div>
            <div style={{background:"#C0FF3E"}}>assa</div>
            <div style={{background:"#00FA9A"}}>asdsad</div>
            <div style={{background:"#AFEEEE"}}>asdsad</div>
            <div style={{background:"#32CD32"}}>asdsad</div>
            <div style={{background:"#DFFF00"}}>asdsad</div>
            <div style={{background:"#00CED1"}}>asdas</div>
            <div style={{background:"#00FF7F"}}>asdsa</div>




            <div style={{background:"#FFFFE0"}}>adsa</div>
            <div style={{background:"#FFFF00"}}>assa</div>
            <div style={{background:"#FFFACD"}}>asdsad</div>
            <div style={{background:"#FFEF00"}}>asdsad</div>
            <div style={{background:"#FAFAD2"}}>asdsad</div>
            <div style={{background:"#FFFF66"}}>asdsad</div>
            <div style={{background:"#F3E5AB"}}>asdas</div>
            <div style={{background:"#FDFD96"}}>asdsa</div>
            <div style={{background:"#FFF8C6"}}>asdsa</div>
            <div style={{background:"#FCE38A"}}>asdsa</div> */}

            <div className="px-3 mt-3">
                
                <div className="  p-0 m-0" style={{ maxHeight: alturaMaxima, overflow: 'auto'}}>
                    <table className="table table-bordered    table-reporte" style={{ width: "max-content" }}>
                        <thead className='' style={{ position: 'sticky', top: 0, zIndex: 1, background: '#ffffff' }}>
                            <tr>
                       
                                {/* <th colSpan={4} className='text-center main-colored'>Título</th>
                                <th colSpan={4} className='text-center secondary-colored'>Placa</th> */}
                                <th colSpan={8} className='text-center' style={{ background: colores.datos }}>Datos generales</th>
                                <th colSpan={3} className='text-center' style={{ background: colores.titulo }}>Título</th>
                                {/* style={{
                                color: estilosCompania?.boton_primario,
                              }} */}
                                <th colSpan={4} className='text-center' style={{ background: colores.placa}}>Placa</th>
                                <th colSpan={2} className='text-center' style={{ background: colores.placa}}>Entrega</th>
 
                            </tr>
                            <tr style={{ lineHeight: "12px" }}>
                                <th className='text-center'  style={{ width: "5rem" , position: "sticky", left: "0", background: colores.datos }}>Inmatriculación</th>
                                <th className='text-center' style={{ width: "5rem", background:colores.datos}} >Chasis</th>
                                <th className='text-center' style={{ width: "5rem", background:colores.datos}} >Kardex</th>
                                <th className='text-center' style={{ width: "10rem", background:colores.datos}} >Sucursal</th>
                                <th className='text-center' style={{ width: "10rem", background:colores.datos}} >Registrado</th>
                                <th className='text-center' style={{ width: "10rem", background:colores.datos}} >Asesor</th>
                                <th className='text-center' style={{ width: "10rem", background:colores.datos}} >Representante</th>
                                <th className='text-center' style={{ width: "20rem", background:colores.datos}} >Cliente</th>
                                <th style={{ width: "10rem", background:colores.titulo}} className='text-center'>Titulo</th>
                                <th style={{ width: "10rem", background:colores.titulo}} className='text-center'>Presentado</th>
                                <th style={{ width: "10rem", background:colores.titulo}} className='text-center'>Inscrito</th>
                                {/* <th style={{ width: "10rem" }} className='bg-danger p-2 text-dark bg-opacity-50'>Fecha Estado</th> */}
                                <th style={{ width: "10rem", background:colores.placa}} className='text-center'>Placa</th>
                                <th style={{ width: "10rem", background:colores.placa}} className='text-center'>Tramitado</th>
                                <th style={{ width: "10rem", background:colores.placa}} className='text-center'>Por recoger</th>
                                <th style={{ width: "10rem", background:colores.placa}} className='text-center'>Entregado</th>
                                {/* <th style={{ width: "10rem" }} className='bg-light p-2 text-dark bg-opacity-50'>Inicio Tramite</th>
                                <th style={{ width: "10rem" }} className='secondary-colored'>Entrega</th> */}
                                {/* <th style={{ width: "10rem" }} className='text-center'>Compañia</th> */}
                                <th style={{ width: "10rem", background:colores.placa}} className='text-center'>Recibido por</th>
                                <th style={{ width: "15rem", background:colores.placa}} className='text-center'>Observaciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listaReportes.map((item, index) => (
                                    <tr key={index}>
                                        <td className='text-center' style={{ width: "5rem" , position: "sticky", left: "0", background: "white" }}>{item.inmatriculacion}</td>
                                        <td className='text-center'>{item.chasis}</td>
                                        <td className='text-center'>{item.kardex}</td>
                                        <td className='text-center' >{item.sucursal}</td>
                                        <td className='text-center' >{item.fecha}</td>
                                        <td className='text-center' >{item.asesor}</td>
                                        <td className='text-center' >{item.representante}</td>
                                        <td className='text-center' >{item.comprador}</td>
                                        <td className='text-center'>{item.titulo}</td>
                                        <td className='text-center'>{item.titulofechapresentado}</td>
                                        <td className='text-center'>{item.titulofechainscrito}</td>
                                        <td className='text-center'>{item.placa}</td>
                                        <td className='text-center'>{item.placatramite}</td>
                                        <td className='text-center'>{item.placaentregado}</td>
                                        {/* <td className='text-center'>Entregado</td> */}
                                        {/* <td>{item.fechaInicioTramite}</td> */}
                                        {/* <td className='text-center'>{item.compania}</td> */}
                                        {/* <td className='text-center'>Recepcion</td> */}
                                        <td className='text-center'>{item.placarecepcionado}</td>
                                        <td className='text-center'>{item.observaciones}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )

}
