import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const ConexionExterna = () => {
  const { token } = useParams()
  const navigate = useNavigate();

  const test = () => {
    navigate("/conectando", { state: { token } });
  }

  useEffect(() => {
    test()
  }, [])


  return (
    <div></div>
  )
}
